
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { keycloak } from '../../../lib/keycloak/index.ts'
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hook.ts'
import { LogoExtended } from '../../../ui/icons/logoExtended.tsx'
import { ButtonComponent } from '../../../ui/molecules/button/index.tsx'
import { InputComponent } from '../../../ui/molecules/input/index.tsx'
import { FinancialAdvisorRegisterForm } from './form.tsx'
import { setSpontaneousFinancialAdvisorsEmail } from './slice.ts'
import { SubscriptionChoice } from './subscriptionChoice.tsx'
import { SuccesfulSignup } from './succesfulSignup.tsx'
import { ProgressStep } from '../../../ui/molecules/progressStep/index.tsx'
import { NewBackground } from '../../../ui/icons/newBackground.tsx'
import clsx from 'clsx'
import './style.css'
import { SubscriptionTierLimit } from '../dto.ts'

export function Register() {
  const [canGoOn, setCanGoOn] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const spontaneousState = useAppSelector(state => state.spontaneous)
  const stepMap = new Map<number, ReactElement>([
    [1, <FinancialAdvisorRegisterForm />],
    [2, <SubscriptionChoice />],
    [3, <SuccesfulSignup />],
  ]
  )

  useEffect(() => {
    function handleEnter(event) {
      const button = document.querySelector('button')
      if (event.key === "Enter") {
        button!.click();
      }
    }
    document.addEventListener("keydown", handleEnter)
    return () => document.removeEventListener('keydown', handleEnter)
  }, [spontaneousState.signupStep])

  return (
    <div className={clsx('flex min-h-screen min-w-fit relative items-center  justify-center', { 
      "max-h-screen" : spontaneousState.signupStep === 1 , 
      }
      )}>
      <div style={{ backgroundColor: '#161FCA', minHeight: spontaneousState.signupStep === 2 ?'-webkit-fill-available': '100vh' }} className={clsx('background rounded-r-[40px] basis-[60%] transition-all ease-linear duration-200  sm:flex justify-center items-center ',
        {
          ' absolute left-[-20%]  bg-none w-[30%] ': spontaneousState.signupStep === 2,
        })}>
      </div>
      <div className={clsx('flex flex-col items-center w-[100%] lg:w-[40%] justify-center  px-10 py-4 ', {
        ' ': spontaneousState.signupStep !== 2,
        ' flex-grow ml-[10.5%]': spontaneousState.signupStep === 2,
      })}>
        <div className='flex flex-col gap-8 w-full  items-center'>
          <div className=' flex items-center justify-center w-full '>    
              <LogoExtended/>
          </div>
          {
            !canGoOn &&
            <>
              <h1 className='text-center font-bold text-neutral-850 text-heading-sm'>Crea il tuo account</h1>
              <div className='bg-white  flex flex-col items-center 
                 rounded-xl py-5 px-5 w-full  md:py-2.5 md:px-8 lg:px-5 lg:py-8 xl:py-2.5 xl:px-5 xl:min-w-[68%] gap-6 '>
                <div className='flex flex-col gap-6 w-[352px]'>
                  <InputComponent
                    onChangeText={(t) => dispatch(setSpontaneousFinancialAdvisorsEmail(t))}
                    required
                    label='Indirizzo email'
                    placeholder='nome@dominio.com'
                    type={'email'}
                  />
                  <ButtonComponent
                    disabled={!/^[A-z0-9\.\+_-]+@[A-z0-9\._-]+\.[A-z]{2,6}$/.test(spontaneousState.spontaneousFinancialAdvisorRequest.email)}
                    onClick={() => { setCanGoOn(true) }}
                    label='Avanti'
                    fullWidth
                    size={'md'}
                    iconPosition={'off'}
                    variant={'solid'}
                    color={'blue'}
                  />
                </div>
                <div className='flex flex-col w-full border-brandPrimary-200 gap-8 items-center pt-2'>
                  <div className='flex flex-row items-center gap-1'>
                    <span className=' text-label-sm lg:text-label-md font-semibold text-[#323252]'>Hai già un account?</span>
                    <span onClick={() => {
                      navigate('/')
                      keycloak.login()
                    }} className=' text-text-md font-semibold text-brandPrimary-500 cursor-pointer'>Accedi qui</span>
                  </div>
                </div>
              </div>
            </>
          }
          {
            canGoOn &&
            <div className='flex flex-col items-center gap-8 w-full'>
              <ProgressStep isVisible={spontaneousState.signupStep < 3} color='primary' size='md' stepMap={stepMap}
                stepNumber={spontaneousState.signupStep} symbol='number' />
            </div>
          }
        </div>
      </div>
    </div>
  )
}
