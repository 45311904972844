import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReactElement } from "react";
import { PromiseStatuses } from "../../../lib/utilis/index.ts";
import { ChatBotRequestDTO, ChatBotResponseDTO, ChatDTO, FindChatBotFilterDTO, MessageFilterDTO, MessageResponse, TopicUnseenInfoDTO } from "./dto.ts";
import { NewChatService } from "./service.ts";

interface ChatsState {
    findChatsStatus: PromiseStatuses
    findChatResponse: ChatDTO[]
    findChatsByLoggedUserStatus: PromiseStatuses
    findChatByLoggedUserResponse: ChatDTO[]
    selectedChat: number
    findMessagesStatus: PromiseStatuses
    findMessagesResponse?: MessageResponse
    setChatSeenStatus: PromiseStatuses
    chatList?: { chat: ChatDTO, name: string, icon: ReactElement, members?: number, bot: boolean }
    usersListAfterSend: string[]
    messageFilter: MessageFilterDTO
    unSeenMessagesStatus: PromiseStatuses
    unSeenMessagesResponse?: TopicUnseenInfoDTO[]
    findLastMessageStatus: PromiseStatuses
    findLastMessageResponse?: { messages: MessageResponse; topic: number; }[]
    findChatBotStatus: PromiseStatuses
    findChatBotResponse?: ChatBotResponseDTO
    questionBotRequest: ChatBotRequestDTO
    questionBotStatus: PromiseStatuses
    filterChatBot: FindChatBotFilterDTO
}

const initialState: ChatsState = {
    findChatsStatus: 'idle',
    findChatResponse: [],
    findChatsByLoggedUserStatus: 'idle',
    findChatByLoggedUserResponse: [],
    selectedChat: 0,
    findMessagesStatus: 'idle',
    setChatSeenStatus: 'idle',
    usersListAfterSend: [],
    messageFilter: {
        page: 0,
        itemsPerPage: 0,
        order: false,
        sort: "timestamp"
    },
    unSeenMessagesStatus: 'idle',
    findLastMessageStatus: 'idle',
    findChatBotStatus: "idle",
    questionBotRequest: {
        question: "",
        userName: ""
    },
    questionBotStatus: "idle",
    filterChatBot: {
        page: 0,
        page_size: 0
    }
}

export const findChats = createAsyncThunk(
    'chat/findChats',
    async (topic: string): Promise<ChatDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.findChats(topic)
    },
)

export const findChatsByLoggedUser = createAsyncThunk(
    'chat/findChatsByLoggedUser',
    async (): Promise<ChatDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.findChatsByLoggedUser()
    },
)

export const findLastMessage = createAsyncThunk(
    'chat/findLastMessage',
    async (request: { chatId: number, filter: MessageFilterDTO }[]): Promise<{ messages: MessageResponse, topic: number }[]> => {
        let promises: Promise<{ messages: MessageResponse, topic: number }>[] = []
        promises = request.map(async ({ chatId: id, filter: filter }) => {
            const chatsService = NewChatService()

            return chatsService.findMessages(id, filter).then(data => ({ messages: data, topic: id }))
        })
        return Promise.all(promises)
    }
)

export const findMessages = createAsyncThunk(
    'chat/findMessages',
    async (request: { chatId: number, filter: MessageFilterDTO }): Promise<MessageResponse> => {
        const chatsService = NewChatService()

        return chatsService.findMessages(request.chatId, request.filter)
    },
)

export const setChatSeen = createAsyncThunk(
    'chat/chatSeen',
    async (id: number): Promise<void> => {
        const chatsService = NewChatService()

        return chatsService.setChatSeen(id)
    },
)

export const unSeenMessages = createAsyncThunk(
    'chat/unSeen',
    async (topic: string): Promise<TopicUnseenInfoDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.unSeenMessages(topic)
    },
)

export const findChatBot = createAsyncThunk(
    'chat/findChatBot',
    async (filter: FindChatBotFilterDTO): Promise<ChatBotResponseDTO> => {
        const chatsService = NewChatService()

        return chatsService.findChatBot(filter)
    },
)

export const questionBot = createAsyncThunk(
    'chat/questionBot',
    async (request: ChatBotRequestDTO): Promise<void> => {
        const chatsService = NewChatService()

        return chatsService.questionBot(request)
    },
)

const chatSlice = createSlice({
    name: 'chat/slice',
    initialState,
    reducers: {
        setSelectedChat: (state, action) => {
            state.selectedChat = action.payload
        },
        setChatList: (state, action) => {
            state.chatList = action.payload
        },
        setUsersListAfterSend: (state, action) => {
            state.usersListAfterSend = action.payload
        },
        setFindChatsStatus: (state, action) => {
            state.findChatsStatus = action.payload
        },
        setFindChatsByLoggedUserStatus: (state, action) => {
            state.findChatsStatus = action.payload
        },
        setFindChatBotStatus: (state, action) => {
            state.findChatBotStatus = action.payload
        },
        setQuestionBotStatus: (state, action) => {
            state.questionBotStatus = action.payload
        },
        setFindMessagesStatus: (state, action) => {
            state.findMessagesStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findChats.pending, (state) => {
                state.findChatsStatus = 'loading'
            })
            .addCase(findChats.fulfilled, (state, action) => {
                state.findChatsStatus = 'successfully'
                state.findChatResponse = action.payload
            })
            .addCase(findChats.rejected, (state) => {
                state.findChatsStatus = 'failed'
            })
            .addCase(findMessages.pending, (state) => {
                state.findMessagesStatus = 'loading'
            })
            .addCase(findMessages.fulfilled, (state, action) => {
                state.findMessagesStatus = 'successfully'
                state.findMessagesResponse = action.payload
            })
            .addCase(findMessages.rejected, (state) => {
                state.findMessagesStatus = 'failed'
            })
            .addCase(setChatSeen.pending, (state) => {
                state.setChatSeenStatus = 'loading'
            })
            .addCase(setChatSeen.fulfilled, (state, action) => {
                state.setChatSeenStatus = 'successfully'
            })
            .addCase(setChatSeen.rejected, (state) => {
                state.setChatSeenStatus = 'failed'
            })
            .addCase(unSeenMessages.pending, (state) => {
                state.unSeenMessagesStatus = 'loading'
            })
            .addCase(unSeenMessages.fulfilled, (state, action) => {
                state.unSeenMessagesStatus = 'successfully'
                state.unSeenMessagesResponse = action.payload
            })
            .addCase(unSeenMessages.rejected, (state) => {
                state.unSeenMessagesStatus = 'failed'
            })
            .addCase(findLastMessage.pending, (state) => {
                state.findLastMessageStatus = 'loading'
            })
            .addCase(findLastMessage.fulfilled, (state, action) => {
                state.findLastMessageStatus = 'successfully'
                state.findLastMessageResponse = action.payload
            })
            .addCase(findLastMessage.rejected, (state) => {
                state.findLastMessageStatus = 'failed'
            })
            .addCase(findChatsByLoggedUser.fulfilled, (state, action) => {
                state.findChatsByLoggedUserStatus = 'successfully'
                state.findChatByLoggedUserResponse = action.payload
            })
            .addCase(findChatsByLoggedUser.pending, (state) => {
                state.findChatsByLoggedUserStatus = 'loading'
            })
            .addCase(findChatsByLoggedUser.rejected, (state, action) => {
                state.findChatsByLoggedUserStatus = 'failed'
            })
            .addCase(findChatBot.pending, (state) => {
                state.findChatBotStatus = 'loading'
            })
            .addCase(findChatBot.fulfilled, (state, action) => {
                state.findChatBotStatus = 'successfully'
                state.findChatBotResponse = action.payload
            })
            .addCase(findChatBot.rejected, (state) => {
                state.findChatBotStatus = 'failed'
            })
            .addCase(questionBot.pending, (state) => {
                state.questionBotStatus = 'loading'
            })
            .addCase(questionBot.fulfilled, (state, action) => {
                state.questionBotStatus = 'successfully'
            })
            .addCase(questionBot.rejected, (state) => {
                state.questionBotStatus = 'failed'
            })
    },
})

export const {
    setSelectedChat,
    setChatList,
    setUsersListAfterSend,
    setFindChatsStatus,
    setFindChatsByLoggedUserStatus,
    setFindChatBotStatus,
    setQuestionBotStatus,
    setFindMessagesStatus
} = chatSlice.actions

export default chatSlice.reducer
