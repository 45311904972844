import { FilterFindProductDTO, FinancialAdvisorActiveOpenBankingCompanyDTO, FinancialAdvisorDTO, FinancialAdvisorObActiveSubscriptionDTO, FinancialAdvisorObSubscriptionDTO, FinancialAdvisorPromoResponseDTO, FinancialAdvisorStripeSubscriptionDTO, FinancialAdvisorSubscriptionDTO, FinancialAdvisorUrlResponseDTO, FindAllFinancialAdvisorFiltersDTO, findAllFinancialAdvisorResponseDTO, FindStripeProductResponseDTO, PurchaseDTO } from "./dto.ts";
import { FinancialAdvisorServiceImpl } from "./serviceImpl.ts";

export interface FinancialAdvisorService {
    findAllFinancialAdvisor(filters: FindAllFinancialAdvisorFiltersDTO): Promise<findAllFinancialAdvisorResponseDTO>
    createFinancialAdvisor(request: FormData): Promise<void>
    deleteFinancialAdvisor(id: string): Promise<void>
    findByIdFinancialAdvisor(id: string): Promise<FinancialAdvisorDTO>
    editFinancialAdvisor(id: string, request: FormData): Promise<void>
    findStripeProduct(filter: FilterFindProductDTO): Promise<FindStripeProductResponseDTO[]>
    findObProduct(filter: { sort: boolean }): Promise<FindStripeProductResponseDTO[]>
    valideStripePromoCode(promoCode: string): Promise<FinancialAdvisorPromoResponseDTO>
    selectPlanStripe(id: string, request: FinancialAdvisorStripeSubscriptionDTO): Promise<FinancialAdvisorUrlResponseDTO>
    selectPlanOb(id: string, request: FinancialAdvisorObSubscriptionDTO): Promise<FinancialAdvisorUrlResponseDTO>
    deletePlanStripe(id: string): Promise<void>
    deletePlanOb(id: string): Promise<void>
    financialAdvisorActiveSubscription(id: string): Promise<FinancialAdvisorSubscriptionDTO>
    financialAdvisorObActiveSubscription(id: string): Promise<FinancialAdvisorObActiveSubscriptionDTO>
    findPurchaseHistory(id: string): Promise<PurchaseDTO[]>
    openBankingActiveCompanies(id: string): Promise<FinancialAdvisorActiveOpenBankingCompanyDTO[]>
}

export function NewFinancialAdvisorService(): FinancialAdvisorService {
    return new FinancialAdvisorServiceImpl();
}
