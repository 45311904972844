import axios from "axios";
import { keycloak } from "../../lib/keycloak/index.ts";
import { FilterFindProductDTO, FinancialAdvisorActiveOpenBankingCompanyDTO, FinancialAdvisorDTO, FinancialAdvisorObActiveSubscriptionDTO, FinancialAdvisorObSubscriptionDTO, FinancialAdvisorPromoResponseDTO, FinancialAdvisorStripeSubscriptionDTO, FinancialAdvisorSubscriptionDTO, FinancialAdvisorUrlResponseDTO, FindAllFinancialAdvisorFiltersDTO, findAllFinancialAdvisorResponseDTO, FindStripeProductResponseDTO, PurchaseDTO } from "./dto.ts";

import { FinancialAdvisorService } from "./service.ts";

const cache: { [key: string]: any } = {};

export class FinancialAdvisorServiceImpl implements FinancialAdvisorService {

    public findAllFinancialAdvisor(filters: FindAllFinancialAdvisorFiltersDTO): Promise<findAllFinancialAdvisorResponseDTO> {
        let url: string = "/api/financialAdvisors?" +
            "itemsPerPage=" + filters.itemsPerPage +
            "&order=" + filters.order +
            "&page=" + filters.page +
            "&sort=" + filters.sort +
            (filters.name !== '' ? ('&commercialName=' + filters.name) : '')
        return axios({
            url: url,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createFinancialAdvisor(request: FormData): Promise<void> {
        let url: string = "/api/financialAdvisors"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteFinancialAdvisor(id: string): Promise<void> {
        let url: string = "/api/financialAdvisors/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public findByIdFinancialAdvisor(id: string): Promise<FinancialAdvisorDTO> {
        let idByPath = window.location.pathname.split("/")[2]
        let _id = id;

        if (idByPath !== undefined && window.location.pathname === ("/operation/" + idByPath)) {
            _id = idByPath
        }

        let url: string = "/api/financialAdvisors/" + _id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editFinancialAdvisor(id: string, request: FormData): Promise<void> {
        let url: string = "/api/financialAdvisors/" + id
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public async findStripeProduct(filter: FilterFindProductDTO): Promise<FindStripeProductResponseDTO[]> {
        const cacheKey = `${filter.period}-${filter.sort}`;

        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        const url = `/api/financialAdvisors/subscription/stripe-products/${filter.period}?sort=${filter.sort}`;

        try {
            const response = await axios({
                url: url,
                method: 'GET',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'accept, authorization, content-type, x-requested-with',
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Access-Control-Allow-Methods': 'GET',
                },
            });

            cache[cacheKey] = response.data;
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async findObProduct(filter: { sort: boolean }): Promise<FindStripeProductResponseDTO[]> {

        const url = `/api/financialAdvisors/subscription/open-banking/products?sort=` + filter.sort;

        try {
            const response = await axios({
                url: url,
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'accept, authorization, content-type, x-requested-with',
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Access-Control-Allow-Methods': 'GET',
                },
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async editObSubscription(id: string): Promise<void> {
        const url = 'subscription/open-banking/edit-subscription/' + id

        return axios(
            {
                url: url,
                method: "PUT",
                headers: {
                    Accept: '*/*',
                    ContentType: 'application/json',
                    "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "Access-Control-Allow-Methods": "GET",
                }
            }
        )
    }

    public valideStripePromoCode(promoCode: string): Promise<FinancialAdvisorPromoResponseDTO> {
        let url: string = "/api/financialAdvisors/subscription/promo-code-verification/" + promoCode
        return axios({
            url: url,
            method: "GET",
            headers: {
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public selectPlanStripe(id: string, request: FinancialAdvisorStripeSubscriptionDTO): Promise<FinancialAdvisorUrlResponseDTO> {
        let url: string = "/api/financialAdvisors/subscription/select-plan/" + id
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public selectPlanOb(id: string, request: FinancialAdvisorObSubscriptionDTO): Promise<FinancialAdvisorUrlResponseDTO> {
        let url: string = "/api/financialAdvisors/subscription/open-banking/select-plan/" + id
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deletePlanStripe(id: string): Promise<void> {
        let url: string = "/api/financialAdvisors/subscription/cancel-subscription/" + id
        return axios({
            url: url,
            method: "PUT",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public deletePlanOb(id: string): Promise<void> {
        let url: string = "/api/financialAdvisors/subscription/open-banking/cancel-subscription/" + id
        return axios({
            url: url,
            method: "PUT",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public financialAdvisorActiveSubscription(id: string): Promise<FinancialAdvisorSubscriptionDTO> {
        let url: string = "/api/financialAdvisors/subscription/is-active/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public financialAdvisorObActiveSubscription(id: string): Promise<FinancialAdvisorObActiveSubscriptionDTO> {
        let url: string = "/api/financialAdvisors/subscription/open-banking/is-active/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    findPurchaseHistory(id: string): Promise<PurchaseDTO[]> {
        let url = '/api/financialAdvisors/subscription/transaction/' + id

        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    openBankingActiveCompanies(id: string): Promise<FinancialAdvisorActiveOpenBankingCompanyDTO[]> {
        let url = '/api/financialAdvisors/subscription/open-banking/active-companies/' + id

        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
