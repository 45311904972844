import { CSSProperties } from "react"
import { useAppSelector } from "../../../lib/redux/hook.ts"
import { colors } from "../../colors.ts"

export type ButtonSize = 'sm' | 'md' | 'lg'
export type ButtonIconPosition = 'off' | 'only' | 'right' | 'left'
export type ButtonVariant = 'solid' | 'outline' | 'ghost' | 'link'
export type ButtonColor = 'blue' | 'gray' | 'green' | 'red'

export const ButtonColorMap = (color: ButtonColor) => {
    const layoutState = useAppSelector(state => state.layout)

    return new Map<ButtonColor, { color: string, darker: string }>([
        ['blue', { color: layoutState.theme[500], darker: layoutState.theme[600] }],
        ['gray', { color: colors.neutral[400], darker: colors.neutral[500] }],
        ['green', { color: colors.green[500], darker: colors.green[600] }],
        ['red', { color: colors.red[400], darker: colors.red[500] }],
    ]).get(color)
}

export const buttonSizeMap = new Map<ButtonSize, CSSProperties>([
    ['sm', { height: '32px', minWidth: '32px', }],
    ['md', { height: '40px', minWidth: '40px' }],
    ['lg', { height: '48px', minWidth: '48px' }],
])

export const buttonLabelSizeMap = new Map<ButtonSize, CSSProperties>([
    ['sm', {
        fontFamily: 'Manrope',
        fontSize: '14px',
        fontWeight: 600,
        height: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }],
    ['md', {
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '18px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }],
    ['lg', {
        fontFamily: 'Manrope',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }],
])

export const buttonIconPositionMap = new Map<ButtonIconPosition, CSSProperties>([
    ['off', { padding: '0px 16px 0px 16px', }],
    ['only', { padding: '0px 16px 0px 16px', }],
    ['left', { padding: '0px 16px 0px 12px', flexDirection: 'row' }],
    ['right', { padding: '0px 12px 0px 16px', flexDirection: 'row-reverse' }],
])


export const buttonVariantKey = (variant: string, darker: boolean): string => {
    return `${variant}-${darker}`;
};

export const colorKey = (color: ButtonColor, variantKey: string): { buttonIconVariant: string, buttonVariant: CSSProperties } => {
    const COLOR = ButtonColorMap(color)?.color || ''
    const DARKERCOLOR = ButtonColorMap(color)?.darker || ''

    const buttonIconVariantMap = new Map<string, string>([
        [buttonVariantKey('solid', true), 'white'],
        [buttonVariantKey('solid', false), 'white'],
        [buttonVariantKey('outline', true), DARKERCOLOR],
        [buttonVariantKey('outline', false), COLOR],
        [buttonVariantKey('ghost', true), DARKERCOLOR],
        [buttonVariantKey('ghost', false), COLOR],
        [buttonVariantKey('link', true), DARKERCOLOR],
        [buttonVariantKey('link', false), COLOR],
    ])

    const buttonVariantMap = new Map<string, CSSProperties>([
        [buttonVariantKey('solid', true), {
            backgroundColor: DARKERCOLOR,
            borderColor: DARKERCOLOR,
        }],
        [buttonVariantKey('solid', false), {
            backgroundColor: COLOR,
            borderColor: COLOR,
        }],
        [buttonVariantKey('outline', true), {
            backgroundColor: 'white',
            borderColor: DARKERCOLOR,
        }],
        [buttonVariantKey('outline', false), {
            backgroundColor: 'white',
            borderColor: COLOR,
        }],
        [buttonVariantKey('ghost', true), {
            backgroundColor: 'white',
            borderColor: 'transparent',
        }],
        [buttonVariantKey('ghost', false), {
            backgroundColor: 'white',
            borderColor: 'transparent',
        }],
        [buttonVariantKey('link', true), {
            backgroundColor: 'transparent',
            borderColor: 'transparent'
        }],
        [buttonVariantKey('link', false), {
            backgroundColor: 'transparent',
            borderColor: 'transparent'
        }],
    ])

    return { buttonIconVariant: buttonIconVariantMap.get(variantKey) || '', buttonVariant: buttonVariantMap.get(variantKey) || {} }
};
