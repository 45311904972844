import clsx from 'clsx'
import { format } from 'date-fns'
import React, { ReactElement, useEffect, useState } from 'react'
import { NewObjectService } from '../../../lib/object/service.ts'
import { findFileById } from '../../../lib/object/slice.ts'
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hook.ts'
import { colors } from '../../../ui/colors.ts'
import { PdfIcon } from '../../../ui/icons/pdfIcon.tsx'
import { XlsxIcon } from '../../../ui/icons/xlsxIcon.tsx'
import { ZipIcon } from '../../../ui/icons/zipIcon.tsx'
import { MessageType, UserInfoDTO } from './dto.ts'
import { ErrorPopup } from '../../../ui/organisms/errorPopup/index.tsx'
import { setFindByIdDocumentStatus } from '../documents/slice.ts'

interface Props {
    message: string
    date: Date
    sent: boolean
    sender?: UserInfoDTO
    sameSanderAsPrevious: boolean
    type: MessageType
}
export function MessageComponent(props: Props) {
    const { message } = props
    const { date } = props
    const { sent } = props
    const { sender } = props
    const { sameSanderAsPrevious } = props
    const { type } = props

    const dispatch = useAppDispatch()
    const layoutState = useAppSelector(state => state.layout)

    const objectService = NewObjectService()

    const [error, setError] = useState(false)

    useEffect(() => {
        if (error) {
            let id = setTimeout(() => {
                setError(false)
            }, 5000);
            return () => clearTimeout(id)
        }
    }, [error])

    const loadSenderData = (sender?: UserInfoDTO): string => {
        if (sender === undefined) {
            return 'Utente eliminato'
        }

        const name = (sender.role === 'companies' || sender.role === 'financial_advisors') && sender.businessName ? sender.businessName : (sender.name + ' ' + sender.surname)
        return name
    }

    const getFileExtension = (message: string): string => {
        const match = message.match(/(\.[a-zA-Z0-9]+)$/);
        return match ? match[0] : "";
    };

    const iconMap = new Map<string, ReactElement>([
        [".png", <ZipIcon size={"28"} color={sent ? colors.white : colors.brandPrimary[800]} />],
        [".jpg", <ZipIcon size={"28"} color={sent ? colors.white : colors.brandPrimary[800]} />],
        [".jpeg", <ZipIcon size={"28"} color={sent ? colors.white : colors.brandPrimary[800]} />],
        [".pdf", <PdfIcon size={"28"} color={colors.red[500]} />],
        [".xlsx", <XlsxIcon size={"28"} color={colors.green[500]} />],
        [".xls", <XlsxIcon size={"28"} color={colors.green[500]} />],
        [".zip", <ZipIcon size={"28"} color={sent ? colors.white : colors.brandPrimary[800]} />],
        [".txt", <ZipIcon size={"28"} color={sent ? colors.white : colors.brandPrimary[800]} />],
    ]);

    return (
        <div
            style={{
                padding: type === MessageType.message ? '8px 12px 8px 12px' : '4px 4px 12px 4px',
                backgroundColor: (sent ? layoutState.theme[500] : colors.white),
                borderRadius: '12px',
                borderTopRightRadius: (sent ? '0' : '12px'),
                borderTopLeftRadius: (sent ? '12px' : '0'),
                gap: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                maxWidth: '100%',
                boxShadow: '0px 1px 2px 0px rgba(22, 42, 100, 0.12)'
            }}
        >
            {
                !sent && !sameSanderAsPrevious &&
                <div className={clsx('text-text-xs font-semibold text-brandSecondary-500', {
                    "ml-[2px]": type === MessageType.media
                })}>
                    {loadSenderData(sender)}
                </div>
            }
            <ErrorPopup message="Documento non più disponibile" active={error} close={() => setError(false)} />
            <div
                style={{
                    gap: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    width: '100%'
                }}
            >{
                    type === MessageType.message ?
                        <span
                            className={
                                clsx("text-text-md font-medium text-brandPrimary-800 break-words w-full",
                                    {
                                        "text-white": sent
                                    })}
                        >
                            {message}
                        </span> :
                        <div
                            className={clsx('rounded-lg p-4 flex flex-row gap-2 items-center cursor-pointer', {
                                "bg-neutral-200": sender && !sent,
                                "bg-brandPrimary-800": sent
                            })}
                            onClick={() => {
                                dispatch(findFileById(message.charAt(0))).then((e) => {
                                    //@ts-ignore
                                    if (e.meta.requestStatus !== 'rejected') objectService.downloadBase64WithExtension(message.replace(/^.*?;/, ''), e.payload !== null ? e.payload : '')
                                    if (e.meta.requestStatus === 'rejected') setError(true)
                                    // dispatch(findByIdDocument(item.id.toString())).then(() => {
                                    //     dispatch(setFindDocumentStatus('idle'))
                                    //     dispatch(findAllDocuments(documentState.documentFilters))
                                    // })
                                })
                            }}
                        >
                            {iconMap.get(getFileExtension(message))}
                            <span
                                className={
                                    clsx("text-text-md font-medium text-brandPrimary-800 break-words w-full",
                                        {
                                            "text-white": sent
                                        })}
                            >
                                {message.replace(/^.*?;/, '')}
                            </span>
                        </div>
                }
            </div>
            <div className={'flex justify-end w-full'}>
                <span className={
                    clsx(
                        'text-text-xs opacity-80 font-normal', {
                        "text-neutral-500": sender,
                        "text-white": sent,
                        "mr-1 mt-1": type === MessageType.media
                    }
                    )}
                >
                    {format(new Date(date), 'HH:mm')}
                </span>
            </div>
        </div>
    )
}