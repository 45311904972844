import React from 'react'
import { IconsProps } from './dto'

export function ChevronRightIcon(props: IconsProps) {
    return (
        <svg onClick={()=>props.onClick && props.onClick()} width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 10L5.25 5.5L0.75 1" stroke="#7171B3" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
