import { ChatBotRequestDTO, ChatBotResponseDTO, ChatDTO, FindChatBotFilterDTO, MessageFilterDTO, MessageResponse, TopicUnseenInfoDTO } from "./dto.ts";
import { ChatServiceImpl } from "./serviceImpl.ts";


export interface ChatService {
    findChats(topic: string): Promise<ChatDTO[]>
    findChatsByLoggedUser(): Promise<ChatDTO[]>
    findMessages(chatId: number, filter: MessageFilterDTO): Promise<MessageResponse>
    setChatSeen(chatId: number): Promise<void>
    unSeenMessages(topic: string): Promise<TopicUnseenInfoDTO[]>
    findChatBot(filter: FindChatBotFilterDTO): Promise<ChatBotResponseDTO>
    questionBot(request: ChatBotRequestDTO): Promise<void>
}

export function NewChatService(): ChatService {
    return new ChatServiceImpl();
}