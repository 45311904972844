import { format } from "date-fns"
import { it } from 'date-fns/locale/it'
import React, { useEffect, useRef, useState } from "react"
import InfiniteScroll from 'react-infinite-scroll-component'
import { keycloak } from "../../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import { colors } from "../../../ui/colors.ts"
import { ArrowRightIcon } from "../../../ui/icons/arrowRightIcon.tsx"
import { BotSecondaryIcon } from "../../../ui/icons/botSecondaryIcon.tsx"
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx"
import { InputComponent } from "../../../ui/molecules/input/index.tsx"
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx"
import { MessageBotDTO } from "./dto.ts"
import { MessageBotComponent } from "./messageBot.tsx"
import { findChatBot, questionBot, setFindChatBotStatus, setQuestionBotStatus } from "./slice.ts"
import "./style.css"


export function ChatBotComponent() {
    const layoutState = useAppSelector(state => state.layout)
    const chatState = useAppSelector(state => state.chat)
    const authState = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const [pageBot, setPageBot] = useState<number>(1)
    const [messagesBot, setMessagesBot] = useState<MessageBotDTO[]>([])
    const [textMessage, setTextMessage] = useState<string>('')
    const [errorStatus, setErrorStatus] = useState(false)

    const messageBotRef = useRef(null)

    useEffect(() => {
        setPageBot(1)
        setMessagesBot([])
        setTextMessage("")
    }, [chatState.selectedChat])

    useEffect(() => {
        if (
            chatState.findChatBotStatus === 'successfully' &&
            chatState.findChatBotResponse !== undefined &&
            chatState.findChatBotResponse?.messages !== undefined &&
            chatState.findChatBotResponse.messages.length > 0
        ) {
            setErrorStatus(false)
            dispatch(setFindChatBotStatus("idle"))
            let messagesList = [...messagesBot, ...chatState.findChatBotResponse.messages]
            chatState.findChatBotResponse.messages.forEach((message, index) => {
                messagesList.concat(
                    message
                )
            })
            setMessagesBot(messagesList)
            if (chatState.findChatBotResponse.page > 1) {
                //@ts-ignore
                messageBotRef.current?.lastElementChild?.lastElementChild?.scrollIntoView({ block: 'start' })
            }
        }
    }, [chatState.findChatBotStatus])

    useEffect(() => {
        if (chatState.questionBotStatus === "successfully") {
            setTextMessage("")
            setMessagesBot([])
            dispatch(findChatBot({ page: 1, page_size: 25 }))
            dispatch(setQuestionBotStatus("idle"))
        } else if (chatState.questionBotStatus === "failed") {
            setErrorStatus(true)
            setMessagesBot(prevMessages => {
                const updatedMessages = prevMessages.slice(1);
                return [{
                    user_id: keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.userId! : authState.findMeReferrersResponse?.userId!,
                    date: new Date(),
                    content: textMessage,
                    response: "Ops, c'è stato un problema! Riprova tra un attimo."
                }, ...updatedMessages];
            });
            setTimeout(() => {
                setMessagesBot([])
                dispatch(findChatBot({ page: 1, page_size: 25 }))
                dispatch(setQuestionBotStatus("idle"))
            }, 4000)
        }
    }, [chatState.questionBotStatus])

    return (
        <div style={{
            height: '100%',
            backgroundColor: colors.white,
            width: '100%',
            borderRadius: 12,
            border: '1px solid ' + colors.neutral[200],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            {
                chatState.selectedChat === 0 ? (
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='text-text-md font-medium text-neutral-700'>
                        Seleziona una chat.
                    </div>
                ) : chatState.selectedChat === 99 && (
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="flex flex-row items-center gap-3 flex-grow">
                                <div className="w-[48px] h-[48px] rounded-[240px] flex justify-center items-center" style={{ backgroundColor: layoutState.theme[500] }}>
                                    <BotSecondaryIcon size={""} color={''} />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <span className="text-text-md font-bold text-neutral-800">
                                        Brighty
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="chat"
                            style={{
                                backgroundColor: colors.neutral[100],
                                position: 'relative',
                                height: 'calc(100% - 147.98px)',
                            }}
                        >
                            {
                                chatState.findChatBotStatus === 'loading' ?
                                    <div className="flex justify-center items-center h-full">
                                        <SpinnerComponent />
                                    </div>
                                    :
                                    <div id='scrollableDivMessages' ref={messageBotRef} style={{ display: 'flex', flexDirection: "column-reverse", overflow: "scroll", height: '100%' }}>
                                        <InfiniteScroll
                                            key={chatState.selectedChat + '-chat-bot-list-message'}
                                            inverse
                                            style={{ display: 'flex', flexDirection: 'column-reverse', marginBottom: 4 }}
                                            scrollableTarget="scrollableDivMessages"
                                            dataLength={messagesBot.length}
                                            next={() => {
                                                if (chatState.findChatBotStatus !== 'loading') {
                                                    setPageBot(pageBot + 1)
                                                    dispatch(findChatBot({ page: pageBot + 1, page_size: 25 }))
                                                }
                                            }}
                                            hasMore={chatState.findChatBotResponse?.page! < chatState.findChatBotResponse?.total_pages!}
                                            endMessage={
                                                <span className='text-text-md font-semibold text-neutral-800 text-center p-4'>
                                                    {chatState.findChatBotResponse?.total_messages === 0 ? 'Non sono presenti altri messaggi' : ''}
                                                </span>
                                            }
                                            loader={
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        padding: 10,
                                                        margin: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SpinnerComponent />
                                                </div>
                                            }
                                        >
                                            {
                                                messagesBot.map((message, index) => (
                                                    <div
                                                        key={message.user_id}
                                                        style={{
                                                            display: 'flex',
                                                            gap: '8px',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            alignSelf: 'stretch',
                                                            borderRadius: '6px',
                                                            width: '100%',
                                                            padding: (
                                                                (
                                                                    messagesBot[index + 1] &&
                                                                    messagesBot[index + 1].user_id === message.user_id &&
                                                                    format(new Date(message.date), 'dd/MM/yyyy') === format(new Date(messagesBot[index + 1].date), 'dd/MM/yyyy')
                                                                ) || (
                                                                        messagesBot[index - 1] &&
                                                                        messagesBot[index - 1].user_id === message.user_id &&
                                                                        format(new Date(message.date), 'dd/MM/yyyy') === format(new Date(messagesBot[index - 1].date), 'dd/MM/yyyy')
                                                                    ) ? '2px 12px' : '8px 12px')
                                                        }}>
                                                        {
                                                            (!messagesBot[index + 1] || (messagesBot[index + 1] && format(new Date(message.date), 'dd/MM/yyyy') !== format(new Date(messagesBot[index + 1].date), 'dd/MM/yyyy'))) &&
                                                            <span className='text-text-md font-medium text-neutral-750'>
                                                                {new Date().getFullYear() === new Date(message.date).getFullYear() && (format(new Date(message.date), 'eee ', { locale: it }))}
                                                                {(format(new Date(message.date), 'dd MMM', { locale: it }))}
                                                                {new Date().getFullYear() !== new Date(message.date).getFullYear() && format(new Date(message.date), ' yyyy')}
                                                            </span>
                                                        }
                                                        <div className="text-text-lg font-medium text-neutral-800"
                                                            style={{
                                                                flexGrow: 1,
                                                                flexBasis: '50%',
                                                                maxWidth: '50%',
                                                                alignSelf: message.user_id === keycloak.subject ? 'flex-end' : 'flex-start'
                                                            }}
                                                        >
                                                            <MessageBotComponent
                                                                message={message.content}
                                                                date={message.date}
                                                                sent={true}
                                                                sameSanderAsPrevious={
                                                                    index > 0 &&
                                                                    messagesBot[index - 1].user_id === message.user_id &&
                                                                    format(new Date(message.date), 'dd/MM/yyyy') === format(new Date(messagesBot[index - 1].date), 'dd/MM/yyyy')
                                                                }
                                                            />
                                                        </div>
                                                        <div className="text-text-lg font-medium text-neutral-800"
                                                            style={{
                                                                flexGrow: 1,
                                                                flexBasis: '50%',
                                                                maxWidth: '50%',
                                                                alignSelf: 'flex-start'
                                                            }}
                                                        >
                                                            <MessageBotComponent
                                                                message={message.response}
                                                                date={message.date}
                                                                sent={false}
                                                                sender="Brighty"
                                                                sameSanderAsPrevious={
                                                                    index > 0 &&
                                                                    messagesBot[index - 1].user_id === message.user_id &&
                                                                    format(new Date(message.date), 'dd/MM/yyyy') === format(new Date(messagesBot[index - 1].date), 'dd/MM/yyyy')
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </div>
                            }
                        </div>
                        <div style={{ padding: '16px', gap: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', height: '72px' }}>
                            <InputComponent
                                value={textMessage}
                                onChangeText={e => setTextMessage(e)}
                                id={'chat'}
                                type={'text'}
                                placeholder={'Inserisci un messaggio'}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && keycloak.tokenParsed !== undefined && keycloak.tokenParsed.exp !== undefined && textMessage !== '') {
                                        setMessagesBot(prevMessages => [{
                                            user_id: keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.userId! : authState.findMeReferrersResponse?.userId!,
                                            date: new Date(),
                                            content: textMessage,
                                            response: ""
                                        }, ...prevMessages]);
                                        dispatch(questionBot({
                                            question: textMessage,
                                            userName: keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.companyName! : (authState.findMeReferrersResponse?.name + ' ' + authState.findMeReferrersResponse?.surname)
                                        }))
                                    }
                                }}
                                disabled={chatState.questionBotStatus === "loading" || errorStatus}
                            />
                            {chatState.questionBotStatus === "loading"
                                ? <SpinnerComponent size={30} /> :
                                <ButtonComponent
                                    key={chatState.selectedChat + '-chat-button-message'}
                                    label={''}
                                    onClick={() => {
                                        if (keycloak.tokenParsed !== undefined && keycloak.tokenParsed.exp !== undefined && textMessage !== '') {
                                            setMessagesBot(prevMessages => [{
                                                user_id: keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.userId! : authState.findMeReferrersResponse?.userId!,
                                                date: new Date(),
                                                content: textMessage,
                                                response: ""
                                            }, ...prevMessages]);
                                            dispatch(questionBot({
                                                question: textMessage,
                                                userName: keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.companyName! : (authState.findMeReferrersResponse?.name + ' ' + authState.findMeReferrersResponse?.surname)
                                            }))
                                        }
                                    }}
                                    color={"blue"}
                                    variant={"solid"}
                                    size={"md"}
                                    iconPosition={"only"}
                                    icon={<ArrowRightIcon size={"20"} color={colors.white} />}
                                    iconSize={24}
                                    disabled={errorStatus}
                                />
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}