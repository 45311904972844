import React, { useEffect } from "react"
import { InputComponent } from "../../../ui/molecules/input/index.tsx"
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import {
    createNewSpontaneous, setCreateNewSpontaneousStatus, setSignUpStep,
    setSpontaneousFinancialAdvisorsCommercialName, setSpontaneousFinancialAdvisorsName,
    setSpontaneousFinancialAdvisorsPhoneNumber,
    setSpontaneousFinancialAdvisorsSurname, setSpontaneousFinancialAdvisorsVat
} from "./slice.ts"
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx"
import { ErrorPopup } from "../../../ui/organisms/errorPopup/index.tsx"

export function FinancialAdvisorRegisterForm() {

    const dispatch = useAppDispatch()
    const spontaneousState = useAppSelector(state => state.spontaneous)
    const { name, surname, commercialName, phoneNumber, vat } = spontaneousState.spontaneousFinancialAdvisorRequest
    const formData = new FormData()

    useEffect(() => {
        if (spontaneousState.createNewSpontaneousRequestStatus === 'successfully') {
            dispatch(setSignUpStep(2))
        }
    }, [spontaneousState.createNewSpontaneousRequestStatus])

    return (
        <>
            <ErrorPopup
                active={spontaneousState.createNewSpontaneousRequestStatus === 'failed'}
                close={() => {
                    dispatch(setCreateNewSpontaneousStatus('idle'))
                }}
                message="Errore durante la registrazione"
            />
            <h1 className=" font-bold text-heading-sm text-neutral-850">Parlaci di te</h1>
            <div className=' bg-white flex flex-col gap-6 items-center
                rounded-xl p-8 '>
                <div className=" flex flex-row gap-x-4 w-full">
                    <InputComponent onChangeText={(t) => dispatch(setSpontaneousFinancialAdvisorsName(t))}
                        required label="Nome" placeholder="Nome" type={"text"} />
                    <InputComponent onChangeText={(t) => dispatch(setSpontaneousFinancialAdvisorsSurname(t))}
                        required label="Cognome" placeholder="Cognome" type={"text"} />
                </div>
                <InputComponent onChangeText={(t) => dispatch(setSpontaneousFinancialAdvisorsCommercialName(t))}
                    required label="Ragione sociale" placeholder="Ragione sociale" type={"text"} />
                <InputComponent onChangeText={(t) => { dispatch(setSpontaneousFinancialAdvisorsVat(t)) }}
                    required label="P.IVA" startIcon={<div className=" flex flex-row items-center">
                        <span className=" text-text-sm font-medium text-neutral-400">IT</span>
                    </div>} placeholder="P.IVA" type={"text"} />
                <InputComponent startIcon={
                    <div className=" flex flex-row items-center">
                        <span className=" text-text-sm font-medium text-neutral-400">+39</span>
                    </div>
                }
                    onChangeText={(t) => { dispatch(setSpontaneousFinancialAdvisorsPhoneNumber(t)) }}
                    required label="Telefono" placeholder="Telefono" type={"text"} />
                {spontaneousState.createNewSpontaneousRequestStatus === 'idle' && <ButtonComponent onClick={() => {
                    formData.append('user',
                        JSON.stringify(spontaneousState.spontaneousFinancialAdvisorRequest)
                    )
                    dispatch(createNewSpontaneous(formData))
                }}
                    disabled={!name.length || !surname.length || !phoneNumber.length || !commercialName.length
                        || (vat.length < 11 || vat.length > 15 || !/^\d+$/.test(vat) || !/^\d{9,10}$/.test(phoneNumber))}
                    label="Avanti" fullWidth size={"md"} iconPosition={"off"} variant={"solid"} color={"blue"} />
                }
                {spontaneousState.createNewSpontaneousRequestStatus === 'loading' && <SpinnerComponent size={30} />}
            </div>
        </>
    )
}

