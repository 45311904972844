import React, { ReactNode } from 'react';
import { colors } from '../../colors.ts';
import { CloseIcon } from '../../icons/closeIcon.tsx';
import './style.css';

interface Props {
    active: boolean;
    close: () => void;
    message?: string;
    cta? : ReactNode
}

export function ErrorPopup(props: Props) {
    const { active } = props;
    const { close } = props;
    const { message } = props;
    const {cta} = props

    if (!active) {
        return null;
    }

    setTimeout(() => close(), 5000);

    return (
        <div className={'popup-error flex items-center justify-between p-2'}>
            <div className=' flex gap-2 items-center'>
                <p className={'text-text-md flex-grow font-medium text-white'}>{message}</p>
                {cta}
            </div>
            <div style={{ cursor:'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => close()}><CloseIcon color={colors.white} size={'24'} /></div>
        </div>
    );
}
