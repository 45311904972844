import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { CompanyIcon } from "../../ui/icons/companyIcon.tsx";
import { DashboardIcon } from "../../ui/icons/dashboardIcon.tsx";
import { FinancialAdvisorIcon } from "../../ui/icons/financialAdvisorIcon.tsx";
import { NoticeIcon } from "../../ui/icons/noticeIcon.tsx";
import { OperatorIcon } from "../../ui/icons/operatorIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { StatsCard } from "../../ui/molecules/statsCard/index.tsx";
import { StatsCardAlt } from "../../ui/molecules/statsCardAlt/index.tsx";
import { EmptyList } from "../../ui/organisms/emptyList/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { findAllCompany } from "../company/slice.ts";
import { findAllCollaborator } from "../financialAdvisor/collaborator/slice.ts";
import { FinancialAdvisorDTO, SubscriptionTierLimit } from "../financialAdvisor/dto.ts";
import { findAllFinancialAdvisor, setSelectedTab } from "../financialAdvisor/slice.ts";
import { findAllNotice } from "../notice/slice.ts";
import { colors } from "../../ui/colors.ts";
import { ChevronRightIcon } from "../../ui/icons/chevronRightIcon.tsx";

export function DashboardAdmin() {
    const layoutState = useAppSelector(state => state.layout)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const companyState = useAppSelector(state => state.company)
    const noticeState = useAppSelector(state => state.notice)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [lastFinancialAdvisors, setLastFinancialAdvisors] = useState<FinancialAdvisorDTO[]>([])
    const [expiringSubscriptions, setExpiringSubscriptions] = useState<FinancialAdvisorDTO[]>([])

    useEffect(() => {
        dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
        dispatch(findAllCompany(companyState.companyFilters))
        dispatch(findAllNotice(noticeState.noticeFilters))
    }, [])

    useEffect(() => {
        let temp: FinancialAdvisorDTO[] = []
        if (financialAdvisorState.findAllFinancialAdvisorStatus === 'successfully') {
            for (let i = 0; i < 3; i++) {
                if (financialAdvisorState.findAllFinancialAdvisorResponse!.data[i] !== undefined)
                    temp.push(financialAdvisorState.findAllFinancialAdvisorResponse!.data[i])
            }
            setLastFinancialAdvisors(temp)
            temp = financialAdvisorState.findAllFinancialAdvisorResponse!.data.slice().
                filter(item => item.subscriptionTierLimit !== SubscriptionTierLimit.free &&
                    item !== undefined &&
                    item.paymentSuccess === "Confirmed")
            temp = temp.sort((a, b) => new Date(a.subscriptionExpirationDate).getTime() - new Date(b.subscriptionExpirationDate).getTime())
            setExpiringSubscriptions(temp.slice(0, 3))
        }
    }, [financialAdvisorState.findAllFinancialAdvisorStatus])

    return (
        <LayoutComponent
            menuItem={MenuItems.DASHBOARD}
            breadcrumbItems={['Dashboard']}
            headingLabel={"Dashboard"}
            headingButtons={[]}
            breadcrumbIcon={<DashboardIcon color={layoutState.theme[500]} size="24" />}
        >
            <div className={"flex flex-col gap-3 w-full"}>
                <div className={"flex flex-row gap-6 justify-between pb-6"}>
                    <div className=" flex flex-col w-full gap-4">
                        <div className=" flex flex-row gap-4 items-center flex-grow ">
                            <div className=" flex flex-row gap-4 basis-[50%]">
                                <StatsCard
                                    title={"Commercialisti"}
                                    value={financialAdvisorState.findAllFinancialAdvisorResponse ?
                                        financialAdvisorState.findAllFinancialAdvisorResponse.total.toString() :
                                        <SpinnerComponent />
                                    }
                                    icon={<FinancialAdvisorIcon size={"28"} color={layoutState.theme[500]} />}
                                    onClick={() => { navigate('/financial-advisor') }}
                                />
                                <StatsCard
                                    title={"Collaboratori"}
                                    value={collaboratorState.findAllCollaboratorResponse ?
                                        collaboratorState.findAllCollaboratorResponse.total.toString() :
                                        <SpinnerComponent />
                                    }
                                    icon={<OperatorIcon size={"24"} color={layoutState.theme[500]} />}
                                    onClick={() => { navigate('/financial-advisor') }}
                                />
                            </div>
                            <div className=" flex flex-row gap-4 basis-[50%]">
                                <StatsCard
                                    title={"Aziende"}
                                    value={companyState.findAllCompanyResponse ?
                                        companyState.findAllCompanyResponse.total.toString() :
                                        <SpinnerComponent />
                                    }
                                    icon={<CompanyIcon size={"28"} color={layoutState.theme[500]} />}
                                    onClick={() => { navigate('/company') }}
                                />
                                <StatsCard
                                    title={"Avvisi"}
                                    value={
                                        noticeState.findAllNoticeResponse ?
                                            noticeState.findAllNoticeResponse.total.toString() :
                                            <SpinnerComponent />
                                    }
                                    icon={<NoticeIcon size="28" color={layoutState.theme[500]} />}
                                    onClick={() => { navigate('/notice') }} />
                            </div>
                        </div>
                        <div className=" flex flex-row gap-4 w-full flex-grow">
                            <div className=" basis-[50%]">
                                <StatsCardAlt
                                    title="Ultimi commercialisti"
                                    content={
                                        financialAdvisorState.findAllFinancialAdvisorStatus === 'loading' ?
                                            <div className=" flex items-center justify-center">
                                                <SpinnerComponent />
                                            </div> :
                                            lastFinancialAdvisors.length ?
                                                lastFinancialAdvisors.map(item =>
                                                    <div  onClick={() => navigate('/operation/' + item.id)} key={item.id} className="hover:bg-neutral-100 cursor-pointer items-center flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] px-2 gap-4">
                                                        <span className=" text-brandSecondary-500 font-medium text-[13px] leading-[18px]">
                                                            {item.name + " " + item.surname}
                                                        </span>
                                                        <ChevronRightIcon onClick={() => {
                                                            navigate('/operation/' + item.id)
                                                        }
                                                        }
                                                            size="18" color={colors.brandSecondary[500]}>
                                                        </ChevronRightIcon>
                                                    </div>) :
                                                <EmptyList isSmall />
                                    }
                                    label="Vai alla sezione"
                                    onClick={() => navigate('/financial-advisor')} />
                            </div>
                            <div className=" basis-[50%]">
                                <StatsCardAlt title="Abbonamenti in scadenza" label="Vai alla sezione" onClick={() => navigate('/financial-advisor')}
                                    content={
                                        financialAdvisorState.findAllFinancialAdvisorStatus === 'loading' ?
                                            <div className=" flex items-center justify-center">
                                                <SpinnerComponent />
                                            </div> :
                                            expiringSubscriptions.length ?
                                                expiringSubscriptions.map(item =>
                                                    <div key={item.userId} onClick={() => {
                                                        navigate('/operation/' + item.id)
                                                        dispatch(setSelectedTab(2))
                                                    }
                                                    } className=" hover:bg-neutral-100 cursor-pointer items-center flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] px-2 gap-4">
                                                        <span className=" text-brandSecondary-500 font-medium text-[13px] leading-[18px]">{item.name + " " + item.surname}</span>
                                                        <div className=" flex flex-row gap-2 items-center">
                                                            <span className={clsx(' font-semibold leading-[18px] text-[13px] text-green-500', {
                                                                'text-red-500': new Date() > new Date(item.subscriptionExpirationDate) ,
                                                                'text-warning-500': new Date().getMonth() - new Date(item.subscriptionExpirationDate).getMonth() <= 0 &&
                                                                    new Date().getFullYear() - new Date(item.subscriptionExpirationDate).getFullYear() === 0 &&
                                                                    new Date() < new Date(item.subscriptionExpirationDate) 
                                                            })}>
                                                                {new Date(item.subscriptionExpirationDate).toLocaleDateString()}
                                                            </span>
                                                            <ChevronRightIcon onClick={() => {
                                                                navigate('/operation/' + item.id)
                                                                dispatch(setSelectedTab(2))
                                                            }
                                                            }
                                                                size="18" color={colors.brandSecondary[500]}>
                                                            </ChevronRightIcon>
                                                        </div>
                                                    </div>) :
                                                <EmptyList isSmall />
                                    } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    )
}
