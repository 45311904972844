import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../lib/utilis/index.ts"
import { CompanyDTO, CompanyFiltersDTO, CompanyStatus, CreateCompanyRequestDTO, EditCompanyRequestDTO, FindAllCompanyResponseDTO } from "./dto.ts"
import { NewCompanyService } from "./service.ts"

interface CompanyState {
    findAllCompanyStatus: PromiseStatuses
    findAllCompanyResponse?: FindAllCompanyResponseDTO
    companyFilters: CompanyFiltersDTO
    openNewCompanyModal: boolean
    openArchiveCompanyModal: boolean
    selectedOperationTab: number
    selectedSettingTab: number
    createCompanyRequest: CreateCompanyRequestDTO
    createCompanyStatus: PromiseStatuses
    findByIdCompanyStatus: PromiseStatuses
    findByIdCompanyResponse?: CompanyDTO
    editCompanyStatus: PromiseStatuses
    editCompanyRequest: EditCompanyRequestDTO
    deleteCompanyStatus: PromiseStatuses
    openConfirmModal: boolean
    addCollaboratorsToCompanyStatus: PromiseStatuses
    addCollaboratorsToList: string[]
    companyId: string
    selectedDocumentTab: number
    selectedCompanyDocumentTab: number
    changeCompanyStatusStatus: PromiseStatuses
    openAlert : boolean
}

const initialState: CompanyState = {
    findAllCompanyStatus: 'idle',
    companyFilters: {
        page: 0, itemsPerPage: 25, companyName: '', sort: '', order: false, financialAdvisorId: '', status: ""
    },
    openNewCompanyModal: false,
    openArchiveCompanyModal: false,
    selectedOperationTab: 0,
    selectedSettingTab: 0,
    createCompanyRequest: {
        name: '', surname: '', email: '', companyName: '', administrativeCompanyId: 0, financialAdvisorId: 0, collaboratorId: 0, note: '', avatar: null, vat: '', status: CompanyStatus.ACTIVE
    },
    createCompanyStatus: 'idle',
    findByIdCompanyStatus: 'idle',
    editCompanyStatus: 'idle',
    editCompanyRequest: { name: '', surname: '', companyName: '', note: '', avatar: null },
    deleteCompanyStatus: 'idle',
    openConfirmModal: false,
    addCollaboratorsToList: [],
    addCollaboratorsToCompanyStatus: 'idle',
    companyId: "",
    selectedDocumentTab: 0,
    selectedCompanyDocumentTab: 0,
    changeCompanyStatusStatus: "idle",
    openAlert : false
}

export const findAllCompany = createAsyncThunk(
    'company/findAllCompany',
    async (filters: CompanyFiltersDTO, thunkApi): Promise<FindAllCompanyResponseDTO> => {
        const companyService = NewCompanyService()

        return companyService.findAllCompany(filters)
    }
)

export const createCompany = createAsyncThunk(
    'company/createCompany',
    async (request: FormData, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.createCompany(request)
    }
)

export const findByIdCompany = createAsyncThunk(
    'company/findByIdCompany',
    async (id: string, thunkApi): Promise<CompanyDTO> => {
        const companyService = NewCompanyService()

        return companyService.findByIdCompany(id)
    }
)

export const editCompany = createAsyncThunk(
    'company/editCompany',
    async (request: { id: string, request: FormData }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.editCompany(request.id, request.request)
    }
)

export const deleteCompany = createAsyncThunk(
    'company/deleteCompany',
    async (id: string, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.deleteCompany(id)
    }
)

export const addCollaboratorsToCompany = createAsyncThunk(
    'company/addCollaboratorsToCompany',
    async (request: { companyId: string, collaboratorIds: string[] }): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.addCollaboratorsToCompany(request.companyId, request.collaboratorIds)
    }
)

export const changeCompanyStatus = createAsyncThunk(
    'company/deactiveCompany',
    async (request: { id: string, status: CompanyStatus }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.changeCompanyStatus(request.id, request.status)
    }
)

const CompanySlice = createSlice({
    name: 'company/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllCompany.pending, (state) => {
                state.findAllCompanyStatus = 'loading'
            })
            .addCase(findAllCompany.fulfilled, (state, action) => {
                state.findAllCompanyStatus = 'successfully'
                state.findAllCompanyResponse = action.payload
            })
            .addCase(findAllCompany.rejected, (state) => {
                state.findAllCompanyStatus = 'failed'
            })
            .addCase(createCompany.pending, (state) => {
                state.createCompanyStatus = 'loading'
            })
            .addCase(createCompany.fulfilled, (state, action) => {
                state.createCompanyStatus = 'successfully'
            })
            .addCase(createCompany.rejected, (state) => {
                state.createCompanyStatus = 'failed'
            })
            .addCase(findByIdCompany.pending, (state) => {
                state.findByIdCompanyStatus = 'loading'
            })
            .addCase(findByIdCompany.fulfilled, (state, action) => {
                state.findByIdCompanyStatus = 'successfully'
                state.findByIdCompanyResponse = action.payload
            })
            .addCase(findByIdCompany.rejected, (state) => {
                state.findByIdCompanyStatus = 'failed'
            })
            .addCase(editCompany.pending, (state) => {
                state.editCompanyStatus = 'loading'
            })
            .addCase(editCompany.fulfilled, (state, action) => {
                state.editCompanyStatus = 'successfully'
            })
            .addCase(editCompany.rejected, (state) => {
                state.editCompanyStatus = 'failed'
            })
            .addCase(deleteCompany.pending, (state) => {
                state.deleteCompanyStatus = 'loading'
            })
            .addCase(deleteCompany.fulfilled, (state, action) => {
                state.deleteCompanyStatus = 'successfully'
            })
            .addCase(deleteCompany.rejected, (state) => {
                state.deleteCompanyStatus = 'failed'
            })
            .addCase(addCollaboratorsToCompany.pending, (state) => {
                state.addCollaboratorsToCompanyStatus = 'loading'
            })
            .addCase(addCollaboratorsToCompany.fulfilled, (state, action) => {
                state.addCollaboratorsToCompanyStatus = 'successfully'
            })
            .addCase(addCollaboratorsToCompany.rejected, (state) => {
                state.addCollaboratorsToCompanyStatus = 'failed'
            })
            .addCase(changeCompanyStatus.pending, (state) => {
                state.changeCompanyStatusStatus = 'loading'
            })
            .addCase(changeCompanyStatus.fulfilled, (state, action) => {
                state.changeCompanyStatusStatus = 'successfully'
            })
            .addCase(changeCompanyStatus.rejected, (state) => {
                state.changeCompanyStatusStatus = 'failed'
            })
    },
    reducers: {
        setFindAllOperatorStatus: (state, action) => {
            state.findAllCompanyStatus = action.payload
        },
        setSelectedOperationTab: (state, action) => {
            state.selectedOperationTab = action.payload
        },
        setSelectedSettingTab: (state, action) => {
            state.selectedSettingTab = action.payload
        },
        setOpenNewCompanyModal: (state, action) => {
            state.openNewCompanyModal = action.payload
        },
        setOpenArchiveCompanyModal: (state, action) => {
            state.openArchiveCompanyModal = action.payload
        },
        resetCreateCompanyRequest: (state) => {
            state.createCompanyRequest = { name: '', surname: '', email: '', companyName: '', administrativeCompanyId: 0, financialAdvisorId: 0, collaboratorId: 0, note: '', avatar: null, vat: '', status: CompanyStatus.ACTIVE }
        },
        setCreateCompanyName: (state, action) => {
            state.createCompanyRequest.name = action.payload
        },
        setCreateCompanySurname: (state, action) => {
            state.createCompanyRequest.surname = action.payload
        },
        setCreateCompanyEmail: (state, action) => {
            state.createCompanyRequest.email = action.payload
        },
        setCreateCompanyCompanyName: (state, action) => {
            state.createCompanyRequest.companyName = action.payload
        },
        setCreateCompanyNote: (state, action) => {
            state.createCompanyRequest.note = action.payload
        },
        setCreateVat: (state, action) => {
            state.createCompanyRequest.vat = action.payload
        },
        setCreateCompanyFinancialAdvisorId: (state, action) => {
            state.createCompanyRequest.financialAdvisorId = action.payload
        },
        setFindByIdCompanyStatus: (state, action) => {
            state.findByIdCompanyStatus = action.payload
        },
        setEditCompanyStatus: (state, action) => {
            state.editCompanyStatus = action.payload
        },
        setEditCompanyRequestName: (state, action) => {
            state.editCompanyRequest.name = action.payload
        },
        setEditCompanyRequestSurname: (state, action) => {
            state.editCompanyRequest.surname = action.payload
        },
        setEditCompanyRequestCompanyName: (state, action) => {
            state.editCompanyRequest.companyName = action.payload
        },
        setEditCompanyRequestNote: (state, action) => {
            state.editCompanyRequest.note = action.payload
        },
        setDeleteCompanyStatus: (state, action) => {
            state.deleteCompanyStatus = action.payload
        },
        setOpenConfirmModal: (state, action) => {
            state.openConfirmModal = action.payload
        },
        setCreateCompanyStatus: (state, action) => {
            state.createCompanyStatus = action.payload
        },
        setCompanyFiltersPage: (state, action) => {
            state.companyFilters.page = action.payload
        },
        setCompanyFiltersCompanyName: (state, action) => {
            state.companyFilters.companyName = action.payload
        },
        setCompanyFilterStatus: (state, action) => {
            state.companyFilters.status = action.payload
        },
        setCompanyFiltersFinancialAdvisorId: (state, action) => {
            state.companyFilters.financialAdvisorId = action.payload
        },
        setAddCollaboratorsToCompanyStatus: (state, action) => {
            state.addCollaboratorsToCompanyStatus = action.payload
        },
        setAddCollaboratorsToList: (state, action) => {
            state.addCollaboratorsToList = action.payload
        },
        setCompanyId: (state, action) => {
            state.companyId = action.payload
        },
        setSelectedDocumentTab: (state, action) => {
            state.selectedDocumentTab = action.payload
        },
        setSelectedCompanyDocumentTab: (state, action) => {
            state.selectedCompanyDocumentTab = action.payload
        },
        setEditCompanyAvatar: (state, action) => {
            state.editCompanyRequest.avatar = action.payload
        },
        setChangeCompanyStatusStatus: (state, action) => {
            state.changeCompanyStatusStatus = action.payload
        },
        setOpenAlert : (state, action) => {
            state.openAlert = action.payload
        }
    },
})

export const {
    setSelectedOperationTab,
    setSelectedSettingTab,
    setFindAllOperatorStatus,
    setOpenNewCompanyModal,
    setOpenArchiveCompanyModal,
    setCreateCompanyCompanyName,
    setCreateCompanyEmail,
    setCreateCompanyName,
    setCreateCompanySurname,
    setFindByIdCompanyStatus,
    setEditCompanyRequestCompanyName,
    setEditCompanyRequestName,
    setEditCompanyRequestSurname,
    setEditCompanyStatus,
    setDeleteCompanyStatus,
    setOpenConfirmModal,
    setCreateCompanyStatus,
    setCompanyFiltersPage,
    setCreateCompanyFinancialAdvisorId,
    setCompanyFiltersCompanyName,
    setCompanyFiltersFinancialAdvisorId,
    setAddCollaboratorsToCompanyStatus,
    setAddCollaboratorsToList,
    setCompanyId,
    setCreateCompanyNote,
    resetCreateCompanyRequest,
    setSelectedDocumentTab,
    setEditCompanyRequestNote,
    setSelectedCompanyDocumentTab,
    setEditCompanyAvatar,
    setCreateVat,
    setChangeCompanyStatusStatus,
    setCompanyFilterStatus,
    setOpenAlert
} = CompanySlice.actions

export default CompanySlice.reducer
