import React, { ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../../ui/colors.ts";
import { CloseIcon } from "../../../ui/icons/closeIcon.tsx";
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx";
import { ModalComponent } from "../../../ui/organisms/modal/index.tsx";
import { setDetailsOBModal } from "../slice.ts";

export function ObDetailsModal() {
  const financialAdvisorState = useAppSelector(
    (state) => state.financialAdvisor
  );
  const dispatch = useAppDispatch();

  let activeCompanies: ReactNode[] = [];

  if (
    financialAdvisorState.openBankingActiveCompaniesStatus === "successfully" &&
    financialAdvisorState.openBankingActiveCompaniesResponse !== undefined
  ) {
    financialAdvisorState.openBankingActiveCompaniesResponse.forEach(
      (company) => {
        activeCompanies.push(
          <>
            <span className="text-text-xs font-semibold text-neutral-600 border-b-[1px] border-neutral-200 pb-2">
              {company.companyName}
            </span>
          </>
        );
      }
    );
  }

  return (
    <ModalComponent
      open={financialAdvisorState.detalisOBModal}
      handleClose={() => dispatch(setDetailsOBModal(false))}
    >
      <div
        style={{
          padding: "20px",
          backgroundColor: colors.white,
          boxShadow: "0px 12px 24px rgba(34, 36, 47, 0.16)",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div className="flex justify-end items-center p-0 cursor-pointer">
          <CloseIcon
            size={"20"}
            color={colors.neutral[500]}
            onClick={() => dispatch(setDetailsOBModal(false))}
          />
        </div>
        <div className="flex gap-5 flex-col">
          <div className="flex items-center justify-center">
            <span className="text-text-xl font-semibold text-neutral-800">
              Open Banking
            </span>
          </div>
          <div className="flex flex-col items-center justify-center gap-4 w-full">
            <span className="text-text-md font-normal text-neutral-600 text-center">
              Elenco delle aziende con slot Open Banking attualmente in uso
            </span>
          </div>
          {financialAdvisorState.openBankingActiveCompaniesStatus ===
          "loading" ? (
            <SpinnerComponent />
          ) : (
            <div className="h-max-[200px] overflow-auto flex flex-col gap-1">
              {activeCompanies}
            </div>
          )}
        </div>
      </div>
    </ModalComponent>
  );
}
