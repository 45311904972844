export interface ChatDTO {
    id: number
    users: string[]
    topic: string
    active: boolean
    lastMessageDate: Date
    notSeenMessages: number
}

export interface ChatFilters {
    firstUser: string
    secondUser: string
    topic: number
}

export interface MessageDTO {
    id: number,
    timestamp: Date,
    message: string,
    sender: string,
    type: MessageType
}

export enum MessageType {
    message = "message",
    media = "media"
}

export interface MessageResponse {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: MessageDTO[]
}

export interface MessageFilterDTO {
    page: number
    itemsPerPage: number
    order: boolean
    sort: string
}

export interface UserInfoDTO {
    name: string,
    surname: string,
    role: string,
    mail: string,
    avatarObjectId: string | null,
    userId: string,
    businessName?: string
}

export interface TopicUnseenInfoDTO {
    topic: string
    unseen: number
    lastMessage: string
}

export interface MessageBotDTO {
    user_id: string
    date: Date
    content: string
    response: string
}

export interface ChatBotResponseDTO {
    messages: MessageBotDTO[]
    page: number
    page_size: number
    total_messages: number
    total_pages: number
}

export interface ChatBotRequestDTO {
    question: string
    userName: string
}

export interface FindChatBotFilterDTO {
    page: number
    page_size: number
}
