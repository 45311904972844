import React, { useEffect } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { AccountantIcon } from "../../ui/icons/accountantIcon.tsx";
import { ArrowRightIcon } from "../../ui/icons/arrowRightIcon.tsx";
import { BalanceIcon } from "../../ui/icons/balanceIcon.tsx";
import { EmployeeIcon } from "../../ui/icons/employeeIcon.tsx";
import { F24Icon } from "../../ui/icons/f24Icon.tsx";
import { OtherIcon } from "../../ui/icons/otherIcon.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { Card } from "../../ui/organisms/card/index.tsx";
import { DocumentType } from "./documents/dto.ts";
import { documentReport, setDocumentFilterFileName, setDocumentFilterMonth, setDocumentFilterPage, setDocumentFilterReceivedGroup, setDocumentFilterSenderGroup, setDocumentFilterStatus, setDocumentFilterType, setDocumentFilterTypology, setDocumentFilterYear, setFindAllDocumentsStatus, setSelectedSentOrReceivedTab } from "./documents/slice.ts";
import { Events } from "./events/index.tsx";
import { setEventFilterCompanyId } from "./events/slice.ts";
import { setSelectedDocumentTab, setSelectedOperationTab } from "./slice.ts";
import "./style.css";

export function Overview() {
    const companyState = useAppSelector(state => state.company)
    const documentState = useAppSelector(state => state.document)
    const layoutState = useAppSelector(state => state.layout)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === "successfully" && companyState.findByIdCompanyResponse !== undefined) {
            dispatch(setEventFilterCompanyId(companyState.findByIdCompanyResponse?.id))
            dispatch(documentReport(companyState.findByIdCompanyResponse?.id!))
        }
    }, [companyState.findByIdCompanyStatus])

    const documentTypes = [
        { type: DocumentType.balance, label: "Bilanci e Dichiarativi", icon: <BalanceIcon color={layoutState.theme[500]} size="24" />, tab: 0 },
        { type: DocumentType.f24, label: "F24", icon: <F24Icon color={layoutState.theme[500]} size="24" />, tab: 1 },
        { type: DocumentType.employees, label: "Dipendenti", icon: <EmployeeIcon color={layoutState.theme[500]} size="24" />, tab: 2 },
        { type: DocumentType.accounting, label: "Contabili", icon: <AccountantIcon color={layoutState.theme[500]} size="24" />, tab: 3 },
        { type: DocumentType.other, label: "Altro", icon: <OtherIcon color={layoutState.theme[500]} size="24" />, tab: 4 }
    ];

    if (companyState.findByIdCompanyStatus === "loading" || documentState.documentReportStatus === "loading") {
        return (
            <div className={"flex flex-grow justify-center items-center"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className={"flex flex-col gap-6"}>
            <div className="w-full flex gap-6">
                {
                    documentTypes.map((doc) => {
                        let docReport = documentState.documentReportReponse?.[doc.type];
                        let subLabel = docReport
                            ? `${docReport.unreads} da leggere`
                            : "Nessun documento";

                        return (
                            <Card
                                key={doc.type}
                                icon={doc.icon}
                                label={doc.label}
                                subLabel={subLabel}
                                thirdLabel="Vai alla sezione"
                                secondIcon={<ArrowRightIcon size={"16"} color={layoutState.theme[500]} />}
                                onClick={() => {
                                    dispatch(setFindAllDocumentsStatus("idle"));
                                    dispatch(setDocumentFilterPage(0));
                                    dispatch(setDocumentFilterFileName(""));
                                    dispatch(setDocumentFilterMonth(0));
                                    dispatch(setDocumentFilterTypology(""));
                                    dispatch(setDocumentFilterYear(0));
                                    dispatch(setDocumentFilterType(doc.type));
                                    dispatch(setDocumentFilterReceivedGroup(""));
                                    dispatch(setDocumentFilterSenderGroup(""));
                                    dispatch(setDocumentFilterStatus(""));
                                    if (doc.type === DocumentType.accounting) {
                                        if (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) {
                                            dispatch(setDocumentFilterReceivedGroup("operators"));
                                        } else if (keycloak.hasRealmRole("company") || keycloak.hasRealmRole("administrative_company")) {
                                            dispatch(setDocumentFilterReceivedGroup("companies"));
                                        }
                                        dispatch(setSelectedSentOrReceivedTab(1));
                                    }
                                    dispatch(setSelectedDocumentTab(doc.tab));
                                    dispatch(setSelectedOperationTab(1));
                                }}
                            />
                        );
                    })
                }
            </div>
            <div className="flex gap-6 border-t pt-6" style={{ borderColor: layoutState.theme[100] }}>
                <Events />
            </div>
        </div>
    )
}