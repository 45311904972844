import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { keycloak } from "../../lib/keycloak/index.ts";
import { findFileById, setFindDocumentStatus } from "../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { PromiseStatuses } from "../../lib/utilis/index.ts";
import { SearchIcon } from "../../ui/icons/searchIcon.tsx";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { Switch } from "../../ui/molecules/switch/index.tsx";
import { EmptyList } from "../../ui/organisms/emptyList/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { HeadingSize } from "../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../ui/organisms/heading/index.tsx";
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx";
import { NewCollaboratorModal } from "../financialAdvisor/collaborator/newCollaboratorModal.tsx";
import { findAllCollaborator, setCollaboratorFiltersName, setCreateCollaboratorsEmail, setCreateCollaboratorsName, setCreateCollaboratorsPhoneNumber, setCreateCollaboratorsStatus, setCreateCollaboratorsSurname, setOpenAlert, setOpenNewCollaboratorModal, setSaveOrEdit } from "../financialAdvisor/collaborator/slice.ts";
import { findByIdFinancialAdvisor, setSelectedTab } from "../financialAdvisor/slice.ts";
import { CompanyStatus } from "./dto.ts";
import { findByIdCompany, setAddCollaboratorsToCompanyStatus, setAddCollaboratorsToList } from "./slice.ts";

export function Roles() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const companyState = useAppSelector(state => state.company)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const authState = useAppSelector(state => state.auth)
    const objectState = useAppSelector(state => state.object)
    const layoutState = useAppSelector(state => state.layout)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [avatars, setAvatars] = useState({});
    const [advisorAvatar, setAdvisorAvatar] = useState("");
    const [loading, setLoading] = useState<PromiseStatuses>("idle")

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === "successfully") {
            let financialAdvisorId = Number(companyState.findByIdCompanyResponse?.financialAdvisor)
            if (!keycloak.hasRealmRole("financial_advisor")) {
                dispatch(findByIdFinancialAdvisor(financialAdvisorId.toString()))
            }
            dispatch(findAllCollaborator({ financialAdvisorId: financialAdvisorId, itemsPerPage: 0, page: 0, sort: "", order: false, name: "" }))
            dispatch(setAddCollaboratorsToList(companyState.findByIdCompanyResponse?.collaborators))
        }
    }, [companyState.findByIdCompanyStatus])

    useEffect(() => {
        if ((financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully" || authState.findMeFinancialAdvisorStatus === "successfully") && loading === "idle") {
            dispatch(findFileById(financialAdvisorState.findByIdFinancialAdvisorResponse?.avatarObjectId! ?? authState.findMeFinancialAdvisorResponse?.avatarObjectId))
            setLoading("loading")
        }
    }, [financialAdvisorState.findByIdFinancialAdvisorStatus, loading])

    useEffect(() => {
        if (objectState.findDocumentStatus === "successfully" && loading === "loading") {
            setAdvisorAvatar(objectState.document!)
            setFindDocumentStatus("idle")
            setLoading("successfully")
        }
    }, [objectState.findDocumentStatus, loading])

    useEffect(() => {
        if (collaboratorState.createCollaboratorStatus === "successfully") {
            let financialAdvisorId = Number(companyState.findByIdCompanyResponse?.financialAdvisor)
            dispatch(findAllCollaborator({ financialAdvisorId: financialAdvisorId, itemsPerPage: 0, page: 0, sort: "", order: false, name: "" }))
            dispatch(setCreateCollaboratorsEmail(""))
            dispatch(setCreateCollaboratorsName(""))
            dispatch(setCreateCollaboratorsSurname(""))
            dispatch(setCreateCollaboratorsPhoneNumber(""))
        }
    }, [collaboratorState.createCollaboratorStatus])

    useEffect(() => {
        if (collaboratorState.findAllCollaboratorStatus === "successfully" && loading === "successfully") {
            collaboratorState.findAllCollaboratorResponse?.data.forEach((collaborator) => {
                dispatch(findFileById(collaborator.avatarObjectId)).then((response) => {
                    setAvatars(avatar => ({
                        ...avatar,
                        [collaborator.id]: response.payload
                    }));
                });

            })
            setFindDocumentStatus("idle")
        }
    }, [collaboratorState.findAllCollaboratorStatus, loading])

    useEffect(() => {
        if (companyState.addCollaboratorsToCompanyStatus === "successfully" || companyState.addCollaboratorsToCompanyStatus === "failed") {
            dispatch(findByIdCompany(companyState.companyId))
        }
    }, [companyState.addCollaboratorsToCompanyStatus])

    let collaboratorList: ReactElement[] = []

    if (companyState.findByIdCompanyStatus === "loading" ||
        financialAdvisorState.findByIdFinancialAdvisorStatus === "loading" ||
        collaboratorState.findAllCollaboratorStatus === "loading" ||
        collaboratorState.createCollaboratorStatus === "loading" ||
        companyState.addCollaboratorsToCompanyStatus === "loading" ||
        objectState.findDocumentStatus === "loading"
    ) {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    if (
        collaboratorState.findAllCollaboratorResponse !== undefined &&
        collaboratorState.findAllCollaboratorResponse.data !== undefined
    ) {
        collaboratorState.findAllCollaboratorResponse.data
            .forEach((collaborator, index) => {
                const selected = companyState.addCollaboratorsToList.find(id => collaborator.id.toString() === id.toString())
                collaboratorList.push(
                    <div
                        key={'edit-company-collaborator-' + collaborator.id}
                        className={"flex justify-between p-[6px] gap-2 self-stretch items-center border rounded-lg"}>
                        <Avatar
                            type="info"
                            size="sm"
                            firstRow={collaborator.name + ' ' + collaborator.surname}
                            secondRow={collaborator.email}
                            imageUrl={avatars[collaborator.id]}
                            altTextInitials={(collaborator.name[0] || '') + (collaborator.surname[0] || '')}
                        />
                        <Switch
                            id={'edit-company-collaborator-switch-' + collaborator.id}
                            checked={selected !== undefined}
                            disabled={keycloak.hasRealmRole('admin-accountant') ||
                                companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            }
                            onChange={(checked) => {
                                if (checked) {
                                    dispatch(setAddCollaboratorsToList(companyState.addCollaboratorsToList.concat(collaborator.id.valueOf())))
                                } else {
                                    dispatch(setAddCollaboratorsToList(companyState.addCollaboratorsToList.filter(selectedCollaborator => selectedCollaborator !== collaborator.id)))
                                }
                            }}
                        />
                    </div>
                )
            })
    }

    return (
        <div className={"mt-4 w-full"}>
            <SuccessPopup
                active={collaboratorState.createCollaboratorStatus === "successfully"}
                close={() => dispatch(setCreateCollaboratorsStatus("idle"))}
                message="Collaboratore aggiunto"
            />
            <ErrorPopup
                active={collaboratorState.createCollaboratorStatus === "failed"}
                close={() => dispatch(setCreateCollaboratorsStatus("idle"))}
                message="Errore durante la creazione del collaboratore."
            />
            <SuccessPopup
                active={companyState.addCollaboratorsToCompanyStatus === "successfully"}
                close={() => dispatch(setAddCollaboratorsToCompanyStatus("idle"))}
                message="Collaboratore associato"
            />
            <ErrorPopup
                active={companyState.addCollaboratorsToCompanyStatus === "failed"}
                close={() => dispatch(setAddCollaboratorsToCompanyStatus("idle"))}
                message="Errore durante l'associazione del collaboratore."
            />
            <ErrorPopup active={collaboratorState.openAlert}
                close={() => { dispatch(setOpenAlert(false)) }}
                message="Hai raggiunto il numero max. di collaboratori!"
                cta={
                    <span className=" cursor-pointer text-warning-400 font-semibold text-text-md" onClick={() => {
                        dispatch(setSelectedTab(2));
                        if (keycloak.hasRealmRole('financial_advisor'))
                            navigate('/setting')
                        else
                            navigate('/operation/' + companyState.findByIdCompanyResponse?.financialAdvisor)
                    }}>Aggiorna il piano</span>
                }
            />
            <div className='w-full m-0 p-0 gap-6'>
                <div className="w-full flex-col">
                    <div className="flex flex-col basis-[30%] items-start self-stretch rounded-xl border border-neutral-200 bg-white p-4 gap-4">
                        <HeadingComponent
                            size={HeadingSize.SM}
                            label={"Commercialista"}
                            buttons={[]}
                        />
                        <div
                            style={{ backgroundColor: layoutState.theme[100], borderColor: layoutState.theme[400] }}
                            className="flex justify-between p-[6px] gap-2 self-stretch items-center border rounded-lg"
                        >
                            <Avatar
                                type="info"
                                size="sm"
                                firstRow={!keycloak.hasRealmRole("financial_advisor") ? financialAdvisorState.findByIdFinancialAdvisorResponse?.name + ' ' + financialAdvisorState.findByIdFinancialAdvisorResponse?.surname : authState.findMeFinancialAdvisorResponse?.name + ' ' + authState.findMeFinancialAdvisorResponse?.surname}
                                secondRow={financialAdvisorState.findByIdFinancialAdvisorResponse?.email ?? authState.findMeFinancialAdvisorResponse?.email}
                                imageUrl={advisorAvatar}
                                altTextInitials={!keycloak.hasRealmRole("financial_advisor") ? (financialAdvisorState.findByIdFinancialAdvisorResponse?.name[0] || 'A') + (financialAdvisorState.findByIdFinancialAdvisorResponse?.surname[0] || 'A') : (authState.findMeFinancialAdvisorResponse?.name[0] || 'A') + (authState.findMeFinancialAdvisorResponse?.surname[0] || 'A')}
                            />
                        </div>
                    </div>
                    {
                        (
                            keycloak.hasRealmRole('admin') ||
                            keycloak.hasRealmRole('financial_advisor') ||
                            keycloak.hasRealmRole('operator')
                        ) &&
                        <div
                            className="flex flex-col basis-[70%] self-stretch rounded-xl border border-neutral-200 bg-white p-4 gap-4 mt-4"
                        >
                            <HeadingComponent
                                size={HeadingSize.SM}
                                label={"Collaboratori"}
                                buttons={
                                    keycloak.hasRealmRole('admin') ||
                                        keycloak.hasRealmRole('financial_advisor') ?
                                        [
                                            <ButtonComponent
                                                label='Aggiungi collaboratore'
                                                onClick={() => {
                                                    dispatch(setSaveOrEdit('save'))
                                                    if (!((keycloak.hasRealmRole('financial_advisor') && authState.findMeFinancialAdvisorResponse?.collaborators.length === authState.findMeFinancialAdvisorResponse?.maxNumberOfCollaborators)
                                                        || ((keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('operator')) && financialAdvisorState.findByIdFinancialAdvisorResponse!.collaborators.length === financialAdvisorState.findByIdFinancialAdvisorResponse?.maxNumberOfCollaborators)))
                                                        dispatch(setOpenNewCollaboratorModal(true))
                                                    else dispatch(setOpenAlert(true))
                                                }}
                                                size={"sm"}
                                                iconPosition={"off"}
                                                variant={"solid"}
                                                color={"blue"}
                                                disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                                    (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                                    (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                                                }
                                            />
                                        ] : []
                                }
                            />
                            <InputComponent
                                id="roles-collaborator-filter"
                                placeholder="Cerca un collaboratore"
                                startIcon={<SearchIcon />}
                                type={"text"}
                                onChangeText={(value) => dispatch(setCollaboratorFiltersName(value))}
                                value={collaboratorState.collaboratorFilters.name}
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
                                    }
                                }}
                            />
                            <div style={{ height: 'auto', overflow: 'auto', width: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                                {
                                    collaboratorState.findAllCollaboratorResponse !== undefined &&
                                        collaboratorState.findAllCollaboratorResponse.total === 0 ?
                                        <EmptyList />
                                        :
                                        collaboratorList
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <NewCollaboratorModal />
        </div>
    )
}