import clsx from 'clsx';
import { format } from 'date-fns';
import React, { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hook.ts';
import { PromiseStatuses } from '../../../lib/utilis/index.ts';
import { colors } from '../../../ui/colors.ts';
import { CompanyIcon } from '../../../ui/icons/companyIcon.tsx';
import { ShoppingBagIcon } from '../../../ui/icons/shoppingBagIcon.tsx';
import { TickIcon } from '../../../ui/icons/tickIcon.tsx';
import { ButtonComponent } from '../../../ui/molecules/button/index.tsx';
import { ProgressBarColor } from '../../../ui/molecules/progressBar/dto.ts';
import { ProgressBar } from '../../../ui/molecules/progressBar/index.tsx';
import { SpinnerComponent } from '../../../ui/molecules/spinner/index.tsx';
import { ConfirmModal } from '../../../ui/organisms/confirmModal/index.tsx';
import { ErrorPopup } from '../../../ui/organisms/errorPopup/index.tsx';
import { HeadingSize } from '../../../ui/organisms/heading/dto.ts';
import { HeadingComponent } from '../../../ui/organisms/heading/index.tsx';
import { SubscriptionChoiceCard } from '../../../ui/organisms/subscriptionChoiceCard/index.tsx';
import { SuccessPopup } from '../../../ui/organisms/successPopup/index.tsx';
import { setFindMeFinancialAdvisorStatus } from '../../auth/slice.ts';
import { findAllCompany } from '../../company/slice.ts';
import { findAllCollaborator } from '../collaborator/slice.ts';
import { FindStripeProductResponseDTO, ObSubscriptionType, SubscriptionTierLimit, subscriptionTierLimitMap, SubscriptionType } from '../dto.ts';
import { deletePlanOb, financialAdvisorActiveObSubscription, financialAdvisorActiveSubscription, findObProduct, openBankingActiveCompanies, selectPlanOb, setDeleteObPlanStatus, setDeletePlanStatus, setDetailsOBModal, setFinancialAdvisorActiveObSubscriptionStatus, setFindObProductStatus, setSelectedObPlan, setSelectPlanObStatus, setUnsubscribeModal } from '../slice.ts';
import { ObDetailsModal } from './obDetailsModal.tsx';

const cache = {}

export function ObSubscriptionManagement() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const authState = useAppSelector(state => state.auth)
    const layoutState = useAppSelector(state => state.layout)
    const companyState = useAppSelector(state => state.company)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const dispatch = useAppDispatch()

    const [status, setStatus] = useState<PromiseStatuses>("loading")
    const [hoverCustom, setHoverCustom] = useState(false)

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let listProduct: ReactNode[] = []
    let promo: number = 0
    let finalPrice: number = 0

    useEffect(() => {
        if (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully" && financialAdvisorState.findByIdFinancialAdvisorResponse !== undefined) {
            dispatch(financialAdvisorActiveObSubscription(financialAdvisorState.findByIdFinancialAdvisorResponse?.id))
            dispatch(findAllCompany({
                itemsPerPage: 0,
                order: false,
                page: 0,
                sort: '',
                companyName: '',
                financialAdvisorId: financialAdvisorState.findByIdFinancialAdvisorResponse?.id,
                status: ''
            }))
            dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
        }
    }, [financialAdvisorState.findByIdFinancialAdvisorStatus])

    useEffect(() => {
        let id = setTimeout(() => {
            dispatch(setDeleteObPlanStatus('idle'))
        }, 3000);
        return () => clearTimeout(id)
    }, [financialAdvisorState.deletePlanObStatus === 'successfully'])

    useEffect(() => {
        if (authState.findMeFinancialAdvisorStatus === "successfully" && authState.findMeFinancialAdvisorResponse !== undefined) {
            dispatch(financialAdvisorActiveSubscription(authState.findMeFinancialAdvisorResponse?.id))
            dispatch(findAllCompany({
                itemsPerPage: 0,
                order: false,
                page: 0,
                sort: '',
                companyName: '',
                financialAdvisorId: authState.findMeFinancialAdvisorResponse?.id,
                status: ''
            }))
        }
        dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
        dispatch(setFindMeFinancialAdvisorStatus('idle'))
    }, [authState.findMeFinancialAdvisorStatus])

    useEffect(() => {
        if (financialAdvisorState.deletePlanStripeStatus === "successfully") {
            dispatch(financialAdvisorActiveObSubscription(financialAdvisorState.findByIdFinancialAdvisorResponse?.id!))
        }
    }, [financialAdvisorState.deletePlanStripeStatus])

    useEffect(() => {
        if (
            financialAdvisorState.financialAdvisorActiveSubscriptionObStatus === "successfully"
        ) {
            dispatch(findObProduct({ sort: true }))
        }
    }, [financialAdvisorState.financialAdvisorActiveSubscriptionObStatus])

    useEffect(() => {
        if (
            financialAdvisorState.financialAdvisorActiveSubscriptionObStatus === "successfully" &&
            financialAdvisorState.findObProductStatus === "successfully"
        ) {
            const cacheKey = `findObProduct`;

            let plan;

            if (cache[cacheKey]) {
                plan = cache[cacheKey].find(name => name.name === subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionObResponse?.openBankingLimit!));
            } else {
                plan = financialAdvisorState.findObProductResponse?.filter(name => name.name === subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionObResponse?.openBankingLimit!)).find(plan => plan);

                cache[cacheKey] = financialAdvisorState.findObProductResponse;
            }
            let selected: FindStripeProductResponseDTO | undefined
            if (financialAdvisorState.findObProductResponse) {
                selected = financialAdvisorState.findObProductResponse?.find(item => item.name.substring(3).toLowerCase() === financialAdvisorState.financialAdvisorActiveSubscriptionObResponse?.openBankingLimit)
                if (selected) dispatch(setSelectedObPlan(
                    { id: selected.id, name: selected.name.substring(3).toLowerCase() }
                )
                )
            }

            setStatus('successfully')
            dispatch(setFinancialAdvisorActiveObSubscriptionStatus('idle'))
            dispatch(setFindObProductStatus('idle'))
        }
    }, [financialAdvisorState.financialAdvisorActiveSubscriptionObStatus, financialAdvisorState.findObProductStatus]);

    useEffect(() => {
        if (financialAdvisorState.selectPlanObStatus === "successfully" && financialAdvisorState.selectPlanObResponse !== undefined) {
            const link = document.createElement('a');
            link.href = financialAdvisorState.selectPlanObResponse.url
            link.rel = "noreferrer"
            link.target = "_blank"
            link.click();

            dispatch(setSelectPlanObStatus('idle'))
        }
    }, [financialAdvisorState.selectPlanObStatus])

    if (financialAdvisorState.findObProductResponse !== undefined) {
        financialAdvisorState.findObProductResponse.forEach((product) => {
            listProduct.push(
                <SubscriptionChoiceCard
                    onClick={() => {
                        dispatch(setSelectedObPlan(
                            { id: product.id, name: product.name.substring(3).toLowerCase() }
                        ))
                    }}
                    isLoading={financialAdvisorState.findObProductStatus === "loading"}
                    name={product.name}
                    limitInfo=''
                    price={(Number(product.unitAmount) / 100).toFixed(2).toString().replace('.', ',')}
                    onChange={() => {
                        dispatch(setSelectedObPlan(
                            { id: product.id, name: product.name.substring(3).toLowerCase() }
                        ))
                    }}
                    checked={financialAdvisorState.selectedObPlan?.id === product.id}
                    infoProduct={
                        <div key={product.id}>
                            {product.description.split(',').slice(0, product.description.split(',').length).map(item =>
                                <div key={item} className='flex flex-row items-center gap-2'>
                                    <TickIcon color={colors.green[500]} size='12' />
                                    <span className=' font-medium text-text-sm text-neutral-800'>
                                        {item}
                                    </span>
                                </div>)}
                        </div>
                    }
                />
            )
        })
        listProduct.push(
            <div
                style={{ backgroundColor: hoverCustom ? layoutState.theme[50] : 'white', borderColor: layoutState.theme[300] }}
                className={
                    clsx(' cursor-pointer shadow-xs flex flex-col rounded-xl border w-full')}
            >
                <div onMouseEnter={() => setHoverCustom(true)} onMouseLeave={() => setHoverCustom(false)} className=" flex flex-col items-start justify-start w-full p-4 gap-2 flex-grow">
                    <div className=" flex flex-row items-center justify-between w-full ">
                        <div className='font-bold text-text-md lg:text-text-lg text-brandSecondary-800'>Custom</div>
                    </div>
                    <a rel="noreferrer" target="_blank" href='mailto:hello@fiscalbright.it' className='font-bold text-heading-lg lg:text-heading-md text-brandSecondary-800'>
                        Richiedi preventivo
                    </a>
                    <div className='flex flex-row items-center gap-2'>
                        <TickIcon color={colors.green[500]} size='12' />
                        <span className=' font-medium text-text-sm text-neutral-800'>
                            Ideale per studi di grandi dimensioni
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    if (financialAdvisorState.financialAdvisorActiveSubscriptionObStatus === "loading" || companyState.findAllCompanyStatus === "loading" || status === "loading" || financialAdvisorState.deletePlanObStatus === "loading") {
        return (
            <div className='flex justify-center items-center flex-grow'>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <>
            <HeadingComponent
                label={"Open Banking"}
                buttons={[

                ]}
                size={HeadingSize.LG}
            />
            <span className=' text-neutral-500 text-text-sm max-w-[80%]'>
                Con l’Open Banking di Fiscalbright accedi in modo immediato ai movimenti bancari dei tuoi Clienti senza dover richiedere gli estratti conto. <br></br>
                Seleziona il pacchetto più in linea con le esigenze del tuo studio.
            </span>
            <div className='flex flex-col gap-6'>
                <SuccessPopup
                    active={financialAdvisorState.deletePlanObStatus === "successfully"}
                    close={() => dispatch(setDeleteObPlanStatus("idle"))}
                    message='Abbonamento disdetto con successo. Potrai continuare ad usare il tuo abbonamento fino alla scadenza.'
                />
                <ErrorPopup
                    active={financialAdvisorState.deletePlanObStatus === "failed"}
                    close={() => dispatch(setDeleteObPlanStatus("idle"))}
                    message='Si è verificato un errore durante la cancellazione del tuo abbonamento.'
                />
                <div className='w-full flex flex-row justify-around items-center flex-grow border rounded-xl p-6' style={{ borderColor: layoutState.theme[200] }}>
                    <div className='flex flex-col gap-3 w-[50%]'>
                        <ShoppingBagIcon size={'32'} color={layoutState.theme[400]} />
                        <div className='flex flex-col'>
                            <span className='text-text-sm font-medium text-neutral-500'>Il tuo piano</span>
                            <span className='text-heading-xs font-bold text-neutral-850'>
                                {financialAdvisorState.financialAdvisorActiveSubscriptionObResponse?.openBankingMaxCompanies === null ?
                                    "Da attivare" :
                                    "Piano " + subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionObResponse?.openBankingLimit as SubscriptionTierLimit)}
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3 w-full'>
                        <div className='flex flex-col gap-2'>
                            <div className='flex flex-row gap-[6px]'>
                                <CompanyIcon size={'18'} color={colors.brandSecondary[500]} />
                                <span className='text-text-sm font-semibold text-brandSecondary-800'>Slot Open Banking</span>
                            </div>
                            <ProgressBar
                                value={
                                    (financialAdvisorState.findByIdFinancialAdvisorResponse!.openBankingCompaniesCount / financialAdvisorState.findByIdFinancialAdvisorResponse!.openBankingMaxCompanies) * 100
                                }
                                color={ProgressBarColor.PRIMARY}
                                active={financialAdvisorState.findByIdFinancialAdvisorResponse?.openBankingCompaniesCount ? financialAdvisorState.findByIdFinancialAdvisorResponse?.openBankingCompaniesCount : 0}
                                total={financialAdvisorState.findByIdFinancialAdvisorResponse?.openBankingMaxCompanies ? financialAdvisorState.findByIdFinancialAdvisorResponse.openBankingMaxCompanies.toString() : '0'}
                            />
                            <span
                                className='text-text-xs font-medium cursor-pointer'
                                style={{
                                    color: layoutState.theme[500],
                                    transition: "color 0.3s",
                                }}
                                onMouseEnter={(e) => (e.currentTarget as HTMLElement).style.color = layoutState.theme[600]}
                                onMouseLeave={(e) => (e.currentTarget as HTMLElement).style.color = layoutState.theme[500]}
                                onClick={() => {
                                    dispatch(openBankingActiveCompanies(authState.findMeFinancialAdvisorResponse?.id !== undefined ? authState.findMeFinancialAdvisorResponse.id : financialAdvisorState.findByIdFinancialAdvisorResponse?.id!));
                                    dispatch(setDetailsOBModal(true))
                                }}
                            >
                                Mostra dettagli
                            </span>
                        </div>

                    </div>
                    <div className='pl-1 flex flex-col gap-3 justify-end items-end w-[50%]'>
                        <div className='flex flex-col gap-[6px]'>
                            <div className='flex flex-row gap-2 items-center justify-end'>
                                <span className='text-text-sm font-medium text-brandSecondary-400'>Attivazione</span>
                                <span className='text-text-sm font-medium text-brandSecondary-800'>
                                    {financialAdvisorState.financialAdvisorActiveSubscriptionObResponse?.openBankingRenewalDate !== null ? format(new Date(financialAdvisorState.financialAdvisorActiveSubscriptionObResponse!.openBankingRenewalDate.toString()), 'dd/MM/yyyy') : 'N/A'}
                                </span>
                            </div>
                            <div className='flex flex-row gap-2 items-center justify-end'>
                                <span className='text-text-sm font-medium text-brandSecondary-400'>
                                    Prossimo Rinnovo
                                </span>
                                <span className='text-text-sm font-medium text-brandSecondary-800'>
                                    {financialAdvisorState.findByIdFinancialAdvisorResponse?.openBankingExpirationDate !== null ? format(new Date(financialAdvisorState.findByIdFinancialAdvisorResponse!.openBankingExpirationDate.toString()), 'dd/MM/yyyy') : 'N/A'}
                                </span>
                            </div>
                            <ButtonComponent
                                size={'sm'}
                                iconPosition={'off'}
                                variant={'outline'}
                                color={'blue'}
                                label='Gestione Abbonamento'
                                onClick={() => { dispatch(setUnsubscribeModal(true)); }}
                                disabled={financialAdvisorState.financialAdvisorActiveSubscriptionObResponse?.openBankingSubscriptionType === ObSubscriptionType.canceled}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-row justify-between items-center'>
                        <span className='text-text-md font-bold text-brandSecondary-800'>Modifica il tuo piano</span>
                    </div>
                    <div className={clsx('flex flex-row gap-2 w-full')}>
                        {listProduct}
                    </div>
                </div>
                {
                    (financialAdvisorState.selectedObPlan) &&
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-col gap-4 px-6 pb-6'>
                            {((financialAdvisorState.financialAdvisorActiveSubscriptionObResponse?.openBankingLimit
                                !== financialAdvisorState.selectedObPlan.name) &&
                                (financialAdvisorState.findObProductResponse!.find(item => (item.id === financialAdvisorState.selectedObPlan?.id))))
                                &&
                                <>
                                    <span className='text-text-md font-bold text-brandSecondary-800'>Carrello</span>
                                    <div className=' flex flex-col'>
                                        <div className='flex flex-row justify-between font-medium text-text-md border-t border-dashed border-brandSecondary-200 py-2  '>
                                            <span className='text-text-sm font-medium text-neutral-500'>{financialAdvisorState.findObProductResponse!.find(item => item.id === financialAdvisorState.selectedObPlan?.id) && ("Piano " +
                                                financialAdvisorState.findObProductResponse!.find(item => item.id === financialAdvisorState.selectedObPlan?.id)!.name)}</span>
                                            <span className='font-semibold text-text-sm text-neutral-600'>
                                                {financialAdvisorState.findStripeProductStatus === "loading" ? <SpinnerComponent size={20} /> : "€ " +
                                                    (Number(financialAdvisorState.findObProductResponse!.find(
                                                        item => item.id === financialAdvisorState.selectedObPlan?.id)!.unitAmount) / 100).toFixed(2).toString().replace('.', ',')}
                                            </span>
                                        </div>
                                        <div className='flex flex-row justify-between font-medium text-text-md py-2  '>
                                            <span className='text-text-sm font-medium text-neutral-500'>IVA 22%</span>
                                            <span className='font-semibold text-text-sm text-neutral-600'>
                                                {financialAdvisorState.findObProductStatus === "loading" ? <SpinnerComponent size={20} /> :
                                                    "€ " + ((Number(financialAdvisorState.findObProductResponse!.find(
                                                        item => item.id === financialAdvisorState.selectedObPlan?.id)!.unitAmount) / 100) * 0.22).toFixed(2).replace('.', ',')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-between border-t border-dashed border-brandSecondary-200 py-2'>
                                        <div className='flex flex-col '>
                                            <span className='font-bold text-text-lg text-neutral-800'>Totale da pagare</span>
                                        </div>
                                        {
                                            financialAdvisorState.findObProductStatus === "loading" ?
                                                <SpinnerComponent size={20} /> :
                                                <span className='font-bold text-heading-sm text-neutral-800'>
                                                    <span className='text-sm font-medium'>
                                                        {"€ "}
                                                    </span>
                                                    {
                                                            (Number(financialAdvisorState.findObProductResponse!.find(item => item.id === financialAdvisorState.selectedObPlan?.id)?.unitAmount) * 1.22 / 100).toFixed(2).replace('.', ',')
                                                    }
                                                </span>
                                        }
                                    </div>
                                    <div className='pt-6 flex flex-row gap-2'>
                                        <div className='w-full flex flex-row justify-between items-center'>
                                            <div className='text-text-xs text-neutral-500 font-semibold flex flex-col gap-1.5'>
                                                <span>
                                                    {"· Prossima scadenza " + format(new Date(currentYear, currentMonth + 1, currentDay), "dd/MM/yyyy")}
                                                </span>
                                                <span>
                                                    · Puoi annullare in qualsiasi momento.
                                                </span>
                                            </div>
                                            {
                                                financialAdvisorState.selectPlanObStatus === "loading" ?
                                                    <SpinnerComponent size={30} />
                                                    :
                                                    <ButtonComponent
                                                        label='Procedi al pagamento'
                                                        onClick={() => {
                                                            financialAdvisorState.findObProductResponse &&
                                                                dispatch(selectPlanOb({
                                                                    id: financialAdvisorState.findByIdFinancialAdvisorResponse?.id!,
                                                                    request: {
                                                                        stripeProductId: financialAdvisorState.selectedObPlan!.id,
                                                                        promotionalCode: financialAdvisorState.promoCode,
                                                                        subscriptionType: ObSubscriptionType.active,
                                                                        subscriptionTierLimit: financialAdvisorState.findObProductResponse.find(
                                                                            item => item.id === financialAdvisorState.selectedObPlan?.id)!.name.toLowerCase().substring(3) as SubscriptionTierLimit,
                                                                    }
                                                                }))
                                                        }}
                                                        size={'md'}
                                                        iconPosition={'off'}
                                                        variant={'solid'}
                                                        color={'blue'}
                                                    />
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
                <ConfirmModal
                    open={financialAdvisorState.unsubscribeStripeModal}
                    handleClose={() => dispatch(setUnsubscribeModal(false))}
                    title={"Vuoi disdire il tuo abbonamento?"}
                    description={
                        <>
                            Se disdici il tuo abbonamento Open Banking, <br/> potrai continuare a utilizzarlo fino alla fine del periodo di abbonamento in corso.
                        </>
                    }
                    labelDeny={"Annulla"}
                    labelConfirm={"Disattiva"}
                    actionConfirm={() => {
                        dispatch(deletePlanOb(financialAdvisorState.findByIdFinancialAdvisorResponse?.id!))
                        dispatch(setUnsubscribeModal(false))
                    }}
                    isRedConfirmButton
                />
                <ObDetailsModal />
            </div>
        </>
    );

}