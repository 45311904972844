import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/utilis/index.ts";
import { CreateFinancialAdvisorDTO, EditFinancialAdvisorDTO, FilterFindProductDTO, FinancialAdvisorActiveOpenBankingCompanyDTO, FinancialAdvisorDTO, FinancialAdvisorObActiveSubscriptionDTO, FinancialAdvisorObSubscriptionDTO, FinancialAdvisorPromoResponseDTO, FinancialAdvisorStripeSubscriptionDTO, FinancialAdvisorSubscriptionDTO, FinancialAdvisorUrlResponseDTO, FindAllFinancialAdvisorFiltersDTO, findAllFinancialAdvisorResponseDTO, FindStripeProductResponseDTO, PurchaseDTO, SubscriptionPeriod, SubscriptionTierLimit, SubscriptionType } from "./dto.ts";
import { NewFinancialAdvisorService } from "./service.ts";

interface FinancialAdvisorState {
    findAllFinancialAdvisorStatus: PromiseStatuses
    findAllFinancialAdvisorResponse?: findAllFinancialAdvisorResponseDTO
    findAllFinancialAdvisorFilters: FindAllFinancialAdvisorFiltersDTO
    selectedTab: number,
    createFinancialAdvisorRequest: CreateFinancialAdvisorDTO
    createFinancialAdvisorStatus: PromiseStatuses
    editFinancialAdvisorRequest: EditFinancialAdvisorDTO
    editFinancialAdvisorStatus: PromiseStatuses
    deleteFinancialAdvisorStatus: PromiseStatuses
    findByIdFinancialAdvisorStatus: PromiseStatuses
    findByIdFinancialAdvisorResponse?: FinancialAdvisorDTO
    openNewFinancialAdvisorModal: boolean
    openConfirmModal: boolean
    financialAdvisorId: string
    findStripeProductStatus: PromiseStatuses
    findStripeProductResponse?: FindStripeProductResponseDTO[]
    findObProductStatus: PromiseStatuses
    findObProductResponse?: FindStripeProductResponseDTO[]
    valideStripePromoCodeStatus: PromiseStatuses
    valideStripePromoCodeResponse?: FinancialAdvisorPromoResponseDTO
    selectPlanStripeStatus: PromiseStatuses
    selectPlanObStatus: PromiseStatuses
    selectPlanStripeRequest: FinancialAdvisorStripeSubscriptionDTO
    selectPlanObResponse?: FinancialAdvisorUrlResponseDTO
    selectPlanStripeResponse?: FinancialAdvisorUrlResponseDTO
    deletePlanStripeStatus: PromiseStatuses
    deletePlanObStatus: PromiseStatuses
    financialAdvisorActiveSubscriptionStatus: PromiseStatuses
    financialAdvisorActiveSubscriptionResponse?: FinancialAdvisorSubscriptionDTO
    financialAdvisorActiveSubscriptionObStatus: PromiseStatuses
    financialAdvisorActiveSubscriptionObResponse?: FinancialAdvisorObActiveSubscriptionDTO
    filterFindProduct: FilterFindProductDTO
    hoveredPlan?: string
    selectedPlan?: { id: string, price: string, name: string, period: SubscriptionType }
    selectedObPlan?: { id: string, name: string }
    promoCode: string
    unsubscribeStripeModal: boolean
    findPurchaseHistoryStatus: PromiseStatuses
    findPurchaseHistoryResponse?: PurchaseDTO[]
    cache: { [key: string]: FindStripeProductResponseDTO[] },
    detalisOBModal: boolean
    openBankingActiveCompaniesStatus: PromiseStatuses
    openBankingActiveCompaniesResponse?: FinancialAdvisorActiveOpenBankingCompanyDTO[]
}

const initialState: FinancialAdvisorState = {
    findAllFinancialAdvisorStatus: 'idle',
    selectedTab: 0,
    findAllFinancialAdvisorFilters: { page: 0, itemsPerPage: 10, name: '', sort: '', order: false },
    createFinancialAdvisorRequest: {
        email: '',
        name: '',
        surname: '',
        phoneNumber: '',
        vat: '',
        commercialName: '',
        avatarObjectId: 0,
        userId: '',
        note: '',
        avatar: null,
    },
    createFinancialAdvisorStatus: 'idle',
    editFinancialAdvisorRequest: {
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
        commercialName: '',
        avatarObjectId: 0,
        note: "",
        avatar: null,
        primaryColor: "#3F48F3"
    },
    editFinancialAdvisorStatus: 'idle',
    deleteFinancialAdvisorStatus: 'idle',
    findByIdFinancialAdvisorStatus: 'idle',
    openNewFinancialAdvisorModal: false,
    openConfirmModal: false,
    financialAdvisorId: "",
    findStripeProductStatus: 'idle',
    valideStripePromoCodeStatus: "idle",
    selectPlanStripeStatus: "idle",
    selectPlanStripeRequest: {
        stripeProductId: "",
        promotionalCode: "",
        subscriptionType: SubscriptionType.free,
        subscriptionTierLimit: SubscriptionTierLimit.free,
        subscriptionPeriod: SubscriptionPeriod.year
    },
    deletePlanStripeStatus: "idle",
    deletePlanObStatus: "idle",
    financialAdvisorActiveSubscriptionStatus: "idle",
    filterFindProduct: {
        period: SubscriptionPeriod.year,
        sort: true
    },
    promoCode: "",
    unsubscribeStripeModal: false,
    cache: {},
    findObProductStatus: "idle",
    financialAdvisorActiveSubscriptionObStatus: "idle",
    findPurchaseHistoryStatus: "idle",
    selectPlanObStatus: "idle",
    detalisOBModal: false,
    openBankingActiveCompaniesStatus: "idle"
}

export const findAllFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/findAllFinancialAdvisor',
    async (filters: FindAllFinancialAdvisorFiltersDTO, thunkApi): Promise<findAllFinancialAdvisorResponseDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.findAllFinancialAdvisor(filters)
    }
)

export const createFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/createFinancialAdvisor',
    async (request: FormData, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.createFinancialAdvisor(request)
    }
)

export const deleteFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/deleteFinancialAdvisor',
    async (id: string, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.deleteFinancialAdvisor(id)
    }
)

export const findByIdFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/findByIdFinancialAdvisor',
    async (id: string, thunkApi): Promise<FinancialAdvisorDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.findByIdFinancialAdvisor(id)
    }
)

export const editFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/editFinancialAdvisor',
    async (request: { id: string, request: FormData }, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.editFinancialAdvisor(request.id, request.request)
    }
)

export const findStripeProduct = createAsyncThunk(
    'financialAdvisor/findStripeProduct',
    async (filter: FilterFindProductDTO): Promise<FindStripeProductResponseDTO[]> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return await financialAdvisorService.findStripeProduct(filter)
    }
)

export const findObProduct = createAsyncThunk(
    'financialAdvisor/findObProduct',
    async (filter: { sort: boolean }): Promise<FindStripeProductResponseDTO[]> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return await financialAdvisorService.findObProduct(filter)
    }
)

export const valideStripePromoCode = createAsyncThunk(
    'financialAdvisor/valideStripePromoCode',
    async (promoCode: string): Promise<FinancialAdvisorPromoResponseDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.valideStripePromoCode(promoCode)
    }
)

export const selectPlanStripe = createAsyncThunk(
    'financialAdvisor/selectPlanStripe',
    async (request: { id: string, request: FinancialAdvisorStripeSubscriptionDTO }, thunkApi): Promise<FinancialAdvisorUrlResponseDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.selectPlanStripe(request.id, request.request)
    }
)

export const selectPlanOb = createAsyncThunk(
    'financialAdvisor/selectPlanOb',
    async (request: { id: string, request: FinancialAdvisorObSubscriptionDTO }, thunkApi): Promise<FinancialAdvisorUrlResponseDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.selectPlanOb(request.id, request.request)
    }
)

export const deletePlanStripe = createAsyncThunk(
    'financialAdvisor/deletePlanStripe',
    async (id: string): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.deletePlanStripe(id)
    }
)

export const deletePlanOb = createAsyncThunk(
    'financialAdvisor/deletePlanOb',
    async (id: string): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.deletePlanOb(id)
    }
)

export const financialAdvisorActiveSubscription = createAsyncThunk(
    'financialAdvisor/financialAdvisorActiveSubscription',
    async (id: string): Promise<FinancialAdvisorSubscriptionDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.financialAdvisorActiveSubscription(id)
    }
)

export const financialAdvisorActiveObSubscription = createAsyncThunk(
    'financialAdvisor/financialAdvisorActiveObSubscription',
    async (id: string): Promise<FinancialAdvisorObActiveSubscriptionDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.financialAdvisorObActiveSubscription(id)
    }
)

export const findPurchaseHistory = createAsyncThunk(
    'financialAdvisor/findPurchaseHistory',
    async (id: string): Promise<PurchaseDTO[]> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.findPurchaseHistory(id)
    }
)

export const openBankingActiveCompanies = createAsyncThunk(
    'financialAdvisor/openBankingActiveCompanies',
    async (id: string): Promise<FinancialAdvisorActiveOpenBankingCompanyDTO[]> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.openBankingActiveCompanies(id)
    }
)

const financialAdvisorSlice = createSlice({
    name: 'financialAdvisor/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllFinancialAdvisor.pending, (state) => {
                state.findAllFinancialAdvisorStatus = 'loading'
            })
            .addCase(findAllFinancialAdvisor.fulfilled, (state, action) => {
                state.findAllFinancialAdvisorStatus = 'successfully'
                state.findAllFinancialAdvisorResponse = action.payload
            })
            .addCase(findAllFinancialAdvisor.rejected, (state) => {
                state.findAllFinancialAdvisorStatus = 'failed'
            })
            .addCase(createFinancialAdvisor.pending, (state) => {
                state.createFinancialAdvisorStatus = 'loading'
            })
            .addCase(createFinancialAdvisor.fulfilled, (state, action) => {
                state.createFinancialAdvisorStatus = 'successfully'
            })
            .addCase(createFinancialAdvisor.rejected, (state) => {
                state.createFinancialAdvisorStatus = 'failed'
            })
            .addCase(deleteFinancialAdvisor.pending, (state) => {
                state.deleteFinancialAdvisorStatus = 'loading'
            })
            .addCase(deleteFinancialAdvisor.fulfilled, (state, action) => {
                state.deleteFinancialAdvisorStatus = 'successfully'
            })
            .addCase(deleteFinancialAdvisor.rejected, (state) => {
                state.deleteFinancialAdvisorStatus = 'failed'
            })
            .addCase(findByIdFinancialAdvisor.pending, (state) => {
                state.findByIdFinancialAdvisorStatus = 'loading'
            })
            .addCase(findByIdFinancialAdvisor.fulfilled, (state, action) => {
                state.findByIdFinancialAdvisorStatus = 'successfully'
                state.findByIdFinancialAdvisorResponse = action.payload
            })
            .addCase(findByIdFinancialAdvisor.rejected, (state) => {
                state.findByIdFinancialAdvisorStatus = 'failed'
            })
            .addCase(editFinancialAdvisor.pending, (state) => {
                state.editFinancialAdvisorStatus = 'loading'
            })
            .addCase(editFinancialAdvisor.fulfilled, (state, action) => {
                state.editFinancialAdvisorStatus = 'successfully'
            })
            .addCase(editFinancialAdvisor.rejected, (state) => {
                state.editFinancialAdvisorStatus = 'failed'
            })
            .addCase(findStripeProduct.pending, (state) => {
                state.findStripeProductStatus = 'loading'
            })
            .addCase(findStripeProduct.fulfilled, (state, action) => {
                state.findStripeProductStatus = 'successfully'
                state.findStripeProductResponse = action.payload

                const cacheKey = `${action.payload}-${action.payload.sort}`
                state.cache[cacheKey] = action.payload
            })
            .addCase(findStripeProduct.rejected, (state) => {
                state.findStripeProductStatus = 'failed'
            })
            .addCase(valideStripePromoCode.pending, (state) => {
                state.valideStripePromoCodeStatus = 'loading'
            })
            .addCase(valideStripePromoCode.fulfilled, (state, action) => {
                state.valideStripePromoCodeStatus = 'successfully'
                state.valideStripePromoCodeResponse = action.payload
            })
            .addCase(valideStripePromoCode.rejected, (state) => {
                state.valideStripePromoCodeStatus = 'failed'
            })
            .addCase(selectPlanStripe.pending, (state) => {
                state.selectPlanStripeStatus = 'loading'
            })
            .addCase(selectPlanStripe.fulfilled, (state, action) => {
                state.selectPlanStripeStatus = 'successfully'
                state.selectPlanStripeResponse = action.payload
            })
            .addCase(selectPlanStripe.rejected, (state) => {
                state.selectPlanStripeStatus = 'failed'
            })
            .addCase(selectPlanOb.pending, (state) => {
                state.selectPlanObStatus = 'loading'
            })
            .addCase(selectPlanOb.fulfilled, (state, action) => {
                state.selectPlanObStatus = 'successfully'
                state.selectPlanObResponse = action.payload
            })
            .addCase(selectPlanOb.rejected, (state) => {
                state.selectPlanObStatus = 'failed'
            })
            .addCase(deletePlanStripe.pending, (state) => {
                state.deletePlanStripeStatus = 'loading'
            })
            .addCase(deletePlanStripe.fulfilled, (state, action) => {
                state.deletePlanStripeStatus = 'successfully'
            })
            .addCase(deletePlanOb.rejected, (state) => {
                state.deletePlanObStatus = 'failed'
            })
            .addCase(deletePlanOb.pending, (state) => {
                state.deletePlanObStatus = 'loading'
            })
            .addCase(deletePlanOb.fulfilled, (state, action) => {
                state.deletePlanObStatus = 'successfully'
            })
            .addCase(deletePlanStripe.rejected, (state) => {
                state.deletePlanStripeStatus = 'failed'
            })
            .addCase(financialAdvisorActiveSubscription.pending, (state) => {
                state.financialAdvisorActiveSubscriptionStatus = 'loading'
            })
            .addCase(financialAdvisorActiveSubscription.fulfilled, (state, action) => {
                state.financialAdvisorActiveSubscriptionStatus = 'successfully'
                state.financialAdvisorActiveSubscriptionResponse = action.payload
            })
            .addCase(financialAdvisorActiveSubscription.rejected, (state) => {
                state.financialAdvisorActiveSubscriptionStatus = 'failed'
            })
            .addCase(financialAdvisorActiveObSubscription.pending, (state) => {
                state.financialAdvisorActiveSubscriptionObStatus = 'loading'
            })
            .addCase(financialAdvisorActiveObSubscription.fulfilled, (state, action) => {
                state.financialAdvisorActiveSubscriptionObStatus = 'successfully'
                state.financialAdvisorActiveSubscriptionObResponse = action.payload
            })
            .addCase(financialAdvisorActiveObSubscription.rejected, (state) => {
                state.financialAdvisorActiveSubscriptionObStatus = 'failed'
            })
            .addCase(findObProduct.fulfilled, (state, action) => {
                state.findObProductStatus = 'successfully'
                state.findObProductResponse = action.payload

                const cacheKey = `${action.payload}-${action.payload.sort}`
                state.cache[cacheKey] = action.payload
            })
            .addCase(findObProduct.rejected, (state) => {
                state.findObProductStatus = 'failed'
            })
            .addCase(findObProduct.pending, (state) => {
                state.findObProductStatus = 'loading'
            })
            .addCase(findPurchaseHistory.fulfilled, (state, action) => {
                state.findPurchaseHistoryStatus = 'successfully'
                state.findPurchaseHistoryResponse = action.payload
            })
            .addCase(findPurchaseHistory.rejected, (state) => {
                state.findPurchaseHistoryStatus = 'failed'
            })
            .addCase(findPurchaseHistory.pending, (state) => {
                state.findPurchaseHistoryStatus = 'loading'
            })
            .addCase(openBankingActiveCompanies.pending, (state) => {
                state.openBankingActiveCompaniesStatus = 'loading'
            })
            .addCase(openBankingActiveCompanies.fulfilled, (state, action) => {
                state.openBankingActiveCompaniesStatus = 'successfully'
                state.openBankingActiveCompaniesResponse = action.payload
            })
            .addCase(openBankingActiveCompanies.rejected, (state) => {
                state.openBankingActiveCompaniesStatus = 'failed'
            })
    },
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        resetCreateFinancialAdvisorRequest: (state) => {
            state.createFinancialAdvisorRequest = {
                email: '',
                name: '',
                surname: '',
                phoneNumber: '',
                vat: '',
                commercialName: '',
                avatarObjectId: 0,
                userId: '',
                note: '',
                avatar: null,
            }
        },
        setCreateFinancialAdvisorsCommercialName: (state, action) => {
            state.createFinancialAdvisorRequest.commercialName = action.payload
        },
        setCreateFinancialAdvisorsName: (state, action) => {
            state.createFinancialAdvisorRequest.name = action.payload
        },
        setCreateFinancialAdvisorsSurname: (state, action) => {
            state.createFinancialAdvisorRequest.surname = action.payload
        },
        setCreateFinancialAdvisorsEmail: (state, action) => {
            state.createFinancialAdvisorRequest.email = action.payload
        },
        setCreateFinancialAdvisorsPhoneNumber: (state, action) => {
            state.createFinancialAdvisorRequest.phoneNumber = action.payload
        },
        setCreateFinancialAdvisorsVat: (state, action) => {
            state.createFinancialAdvisorRequest.vat = action.payload
        },
        setCreateFinancialAdvisorsNote: (state, action) => {
            state.createFinancialAdvisorRequest.note = action.payload
        },
        setCreateFinancialAdvisorsStatus: (state, action) => {
            state.createFinancialAdvisorStatus = action.payload
        },
        setFindbyIdFinancialAdvisorStatus: (state, action) => {
            state.findByIdFinancialAdvisorStatus = action.payload
        },
        setEditFinancialAdvisorsCommercialName: (state, action) => {
            state.editFinancialAdvisorRequest.commercialName = action.payload
        },
        setEditFinancialAdvisorsName: (state, action) => {
            state.editFinancialAdvisorRequest.name = action.payload
        },
        setEditFinancialAdvisorsSurname: (state, action) => {
            state.editFinancialAdvisorRequest.surname = action.payload
        },
        setEditFinancialAdvisorsPhoneNumber: (state, action) => {
            state.editFinancialAdvisorRequest.phoneNumber = action.payload
        },
        setEditFinancialAdvisorsEmail: (state, action) => {
            state.editFinancialAdvisorRequest.email = action.payload
        },
        setEditFinancialAdvisorsNote: (state, action) => {
            state.editFinancialAdvisorRequest.note = action.payload
        },
        setDeleteFinancialAdisorsStatus: (state, action) => {
            state.deleteFinancialAdvisorStatus = action.payload
        },
        setEditFinancialAdisorsStatus: (state, action) => {
            state.editFinancialAdvisorStatus = action.payload
        },
        setFindAllFinancialAdvisorsFilterName: (state, action) => {
            state.findAllFinancialAdvisorFilters.name = action.payload
        },
        setOpenNewFinancialAdvisorModal: (state, action) => {
            state.openNewFinancialAdvisorModal = action.payload
        },
        setOpenConfirmModal: (state, action) => {
            state.openConfirmModal = action.payload
        },
        setFindAllFinancialAdvisorsStatus: (state, action) => {
            state.findAllFinancialAdvisorStatus = action.payload
        },
        setFindAllFinancialAdvisorsFiltersPage: (state, action) => {
            state.findAllFinancialAdvisorFilters.page = action.payload
        },
        setFinancialAdvisorId: (state, action) => {
            state.financialAdvisorId = action.payload
        },
        setEditFinancialAdisorsFile: (state, action) => {
            state.editFinancialAdvisorRequest.avatar = action.payload
        },
        setEditPrimaryColor: (state, action) => {
            state.editFinancialAdvisorRequest.primaryColor = action.payload
        },
        setFilterFindProductPeriod: (state, action) => {
            state.filterFindProduct.period = action.payload
        },
        setFindStripeProductStatus: (state, action) => {
            state.findStripeProductStatus = action.payload
        },
        setFindObProductStatus: (state, action) => {
            state.findObProductStatus = action.payload
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload
        },
        setSelectedObPlan: (state, action) => {
            state.selectedObPlan = action.payload
        },
        setHoveredPlan: (state, action) => {
            state.hoveredPlan = action.payload
        },
        setPromoCode: (state, action) => {
            state.promoCode = action.payload
        },
        setValideStripePromoCodeStatus: (state, action) => {
            state.valideStripePromoCodeStatus = action.payload
        },
        setSelectPlanStripeStatus: (state, action) => {
            state.selectPlanStripeStatus = action.payload
        },
        setSelectPlanObStatus: (state, action) => {
            state.selectPlanObStatus = action.payload
        },
        setUnsubscribeModal: (state, action) => {
            state.unsubscribeStripeModal = action.payload
        },
        setFinancialAdvisorActiveSubscriptionStatusStatus: (state, action) => {
            state.financialAdvisorActiveSubscriptionStatus = action.payload
        },
        setFinancialAdvisorActiveObSubscriptionStatus: (state, action) => {
            state.financialAdvisorActiveSubscriptionObStatus = action.payload
        },
        setDeletePlanStatus: (state, action) => {
            state.deletePlanStripeStatus = action.payload
        },
        setDetailsOBModal: (state, action) => {
            state.detalisOBModal = action.payload
        },
        setDeleteObPlanStatus: (state, action) => {
            state.deletePlanObStatus = action.payload
        },
    },
})

export const {
    setSelectedTab,
    setCreateFinancialAdvisorsEmail,
    setCreateFinancialAdvisorsName,
    setCreateFinancialAdvisorsPhoneNumber,
    setCreateFinancialAdvisorsSurname,
    setCreateFinancialAdvisorsVat,
    setFindbyIdFinancialAdvisorStatus,
    setFindStripeProductStatus,
    setFilterFindProductPeriod,
    setEditFinancialAdvisorsName,
    setEditFinancialAdvisorsPhoneNumber,
    setEditFinancialAdvisorsSurname,
    setEditFinancialAdvisorsEmail,
    setCreateFinancialAdvisorsStatus,
    setDeleteFinancialAdisorsStatus,
    setEditFinancialAdisorsStatus,
    setFindAllFinancialAdvisorsFilterName,
    setOpenNewFinancialAdvisorModal,
    setOpenConfirmModal,
    setFindAllFinancialAdvisorsStatus,
    setFindAllFinancialAdvisorsFiltersPage,
    setFinancialAdvisorId,
    setCreateFinancialAdvisorsNote,
    setCreateFinancialAdvisorsCommercialName,
    resetCreateFinancialAdvisorRequest,
    setEditFinancialAdvisorsNote,
    setEditFinancialAdvisorsCommercialName,
    setEditFinancialAdisorsFile,
    setEditPrimaryColor,
    setSelectedPlan,
    setSelectedObPlan,
    setHoveredPlan,
    setPromoCode,
    setValideStripePromoCodeStatus,
    setSelectPlanStripeStatus,
    setSelectPlanObStatus,
    setUnsubscribeModal,
    setFinancialAdvisorActiveSubscriptionStatusStatus,
    setDeletePlanStatus,
    setDeleteObPlanStatus,
    setFinancialAdvisorActiveObSubscriptionStatus,
    setFindObProductStatus,
    setDetailsOBModal
} = financialAdvisorSlice.actions

export default financialAdvisorSlice.reducer

