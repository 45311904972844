import axios from "axios";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { ChatBotRequestDTO, ChatBotResponseDTO, ChatDTO, FindChatBotFilterDTO, MessageFilterDTO, MessageResponse, TopicUnseenInfoDTO } from "./dto.ts";
import { ChatService } from "./service.ts";

export class ChatServiceImpl implements ChatService {
    findChats(topic: string): Promise<ChatDTO[]> {
        let url: string = "/api/chats?topic=" + topic
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    findChatsByLoggedUser(): Promise<ChatDTO[]> {
        let url: string = "/api/chats"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    findMessages(chatId: number, filter: MessageFilterDTO): Promise<MessageResponse> {
        let url: string = "/api/chats/" + chatId + '/messages?itemsPerPage=' + filter.itemsPerPage + '&page=' + filter.page + "&order=" + filter.order + "&sort=" + filter.sort
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    setChatSeen(chatId: number): Promise<void> {
        let url: string = "/api/chats/" + chatId + '/seen'
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    unSeenMessages(topic: string): Promise<TopicUnseenInfoDTO[]> {
        let url: string = "/api/chats/unseenMessages?topic=" + topic
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    findChatBot(filter: FindChatBotFilterDTO): Promise<ChatBotResponseDTO> {
        let url: string = "/gpt/?page=" + filter.page + "&page_size=" + filter.page_size
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    questionBot(request: ChatBotRequestDTO): Promise<void> {
        let url: string = "/gpt/"
        return axios({
            url: url,
            data: request,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
} 