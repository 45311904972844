export interface FinancialAdvisorDTO {
    id: string
    name: string
    surname: string
    email: string
    userId: string
    avatarObjectId: string
    phoneNumber: string
    commercialName: string
    subscriptionType: SubscriptionType
    subscriptionExpirationDate: Date,
    collaborators: string[]
    companies: string[]
    note: string
    subscriptionTerm: number
    renewalDate: Date
    maxNumberOfCompanies: number
    freeSlotOfCompanies: number
    primaryColor: string
    vat: string
    subscriptionTierLimit: SubscriptionTierLimit
    maxNumberOfDocuments: number
    maxNumberOfCollaborators: number
    lastOpenBankingLimit: SubscriptionTierLimit
    openBankingRenewalDate: Date,
    openBankingExpirationDate: Date,
    openBankingMaxCompanies: number
    openBankingCompaniesCount: number,
    openBankingPaymentSuccess: StripePaymentStatus,
    openBankingStripeSubscriptionId: string
    splashScreenObjectId: string
    subscriptionPeriod: SubscriptionPeriod
    stripeSubscriptionId: string
    paymentSuccess: StripePaymentStatus
    lastSubscriptionTierLimit: SubscriptionTierLimit
    isActive: boolean
    firstTime: boolean
}

export interface findAllFinancialAdvisorResponseDTO {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: FinancialAdvisorDTO[]
}

export interface FindAllFinancialAdvisorFiltersDTO {
    itemsPerPage: number
    order: boolean
    page: number
    sort: string
    name: string
}

export interface CreateFinancialAdvisorDTO {
    name: string
    surname: string
    email: string
    phoneNumber: string
    commercialName: string
    avatarObjectId: number
    userId: string,
    note: string
    avatar: File | null
    vat: string
}

export interface EditFinancialAdvisorDTO {
    name: string
    surname: string
    email: string
    phoneNumber: string
    commercialName: string
    avatarObjectId: number
    note: string
    avatar: File | null
    primaryColor: string
}

export interface FinancialAdvisorPromoResponseDTO {
    codeFound: boolean
    discountPercentage: number
}

export interface FindStripeProductResponseDTO {
    name: string
    active: boolean
    id: string
    unitAmount: string
    description: string
}

export interface FinancialAdvisorStripeSubscriptionDTO {
    stripeProductId: string
    promotionalCode: string
    subscriptionType: SubscriptionType
    subscriptionTierLimit: SubscriptionTierLimit
    subscriptionPeriod: SubscriptionPeriod
}

export interface FinancialAdvisorUrlResponseDTO {
    url: string
}

export interface FinancialAdvisorSubscriptionDTO {
    subscriptionTerm: number
    renewalDate: Date
    subscriptionType: string
    subscriptionExpirationDate: Date
    maxNumberOfCompanies: number
    maxNumberOfDocuments: number
    maxNumberOfCollaborators: number
    subscriptionTierLimit: SubscriptionTierLimit
    subscriptionPeriod: SubscriptionPeriod
}

export interface FilterFindProductDTO {
    period: SubscriptionPeriod
    sort: boolean
}

export enum SubscriptionPeriod {
    year = 'year',
    month = 'month',
}

export enum StripePaymentStatus {
    Pending = 'Pending',
    Confirmed = 'Confirmed',
    Failed = 'Failed'
}

export interface FinancialAdvisorPromoCodeVerificationDTO {
    promoCode: string
}

export interface FinancialAdvisorPromoResponseDTO {
    codeFound: boolean
    discountPercentage: number
}

export interface FindStripeProductResponseDTO {
    name: string
    active: boolean
    id: string
    unitAmount: string
    description: string
}

export interface FinancialAdvisorObSubscriptionDTO {
    stripeProductId: string
    promotionalCode: string
    subscriptionType: ObSubscriptionType
    subscriptionTierLimit: SubscriptionTierLimit
}

export interface FinancialAdvisorUrlResponseDTO {
    url: string
}

export interface FinancialAdvisorSubscriptionDTO {
    subscriptionTerm: number
    renewalDate: Date
    subscriptionType: string
    subscriptionExpirationDate: Date
    maxNumberOfCompanies: number
    maxNumberOfDocuments: number
    maxNumberOfCollaborators: number
}

export interface FinancialAdvisorObActiveSubscriptionDTO {
    openBankingRenewalDate: Date,
    openBankingSubscriptionType: ObSubscriptionType,
    lastOpenBankingSubscriptionType: string,
    openBankingLimit: SubscriptionTierLimit
    lastOpenBankingLimit: SubscriptionTierLimit,
    openBankingMaxCompanies: number,
    openBankingPaymentSuccess: string
}

export interface FilterFindProductDTO {
    period: SubscriptionPeriod
    sort: boolean
}

export enum SubscriptionType {
    free = 'free',
    year = 'year',
    month = 'month',
    expired = 'expired',
    canceled = 'canceled'
}

export enum ObSubscriptionType {
    active = 'active',
    expired = 'expired',
    canceled = 'canceled'
}

export enum SubscriptionTierLimit {
    free = 'free',
    start = 'start',
    professional = 'professional',
    business = 'business',
    unlimited = 'unlimited'
}

export enum SubscriptionObTierLimit {
    start = 'start',
    professional = 'professional',
    business = 'business',
    unlimited = 'unlimited'
}

export const subscriptionPeriodMap = new Map<SubscriptionPeriod, SubscriptionType>([
    [SubscriptionPeriod.year, SubscriptionType.year],
    [SubscriptionPeriod.month, SubscriptionType.month],
])

export const subscriptionPeriodLabelMap = new Map<SubscriptionPeriod, string>([
    [SubscriptionPeriod.year, "Annuale"],
    [SubscriptionPeriod.month, "Trimestrale"],
])

export const subscriptionTypeMap = new Map<SubscriptionType, string>([
    [SubscriptionType.free, 'Free'],
    [SubscriptionType.year, 'In corso'],
    [SubscriptionType.month, 'In corso'],
    [SubscriptionType.expired, 'Scaduto'],
    [SubscriptionType.canceled, 'In scadenza'],
])

export const subscriptionTierLimitMap = new Map<SubscriptionTierLimit, string>([
    [SubscriptionTierLimit.free, 'Free'],
    [SubscriptionTierLimit.start, 'Start'],
    [SubscriptionTierLimit.professional, 'Professional'],
    [SubscriptionTierLimit.business, 'Business'],
    [SubscriptionTierLimit.unlimited, 'Unlimited'],
])


export enum SubscriptionPlanType {
    free = "Free",
    start = "Start",
    professional = "Professional",
    business = "Business",
    unlimited = "Unlimited"
}

export interface PurchaseDTO {
    unitAmount: number
    status: string
    paymentMethod: string
    date: string
    description: string
}

export interface FinancialAdvisorActiveOpenBankingCompanyDTO{
    companyName: string
    name: string
    surname: string
}