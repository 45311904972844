import React, { useEffect, useState } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { keycloak } from "../../lib/keycloak/index.ts";
import { findFileById, setFindDocumentStatus } from "../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { generateTheme, toBase64 } from "../../lib/utilis/index.ts";
import { colors } from "../../ui/colors.ts";
import { EmailIcon } from "../../ui/icons/emailIcon.tsx";
import { PhoneIcon } from "../../ui/icons/phoneIcon.tsx";
import { setTheme } from "../../ui/layout/slice.ts";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { FileInputComponent } from "../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { FormRow } from "../../ui/organisms/formRow/formRow.tsx";
import { HeadingSize } from "../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../ui/organisms/heading/index.tsx";
import { setCreateFinancialAdvisorsEmail, setEditFinancialAdisorsFile, setEditFinancialAdvisorsCommercialName, setEditFinancialAdvisorsEmail, setEditFinancialAdvisorsName, setEditFinancialAdvisorsNote, setEditFinancialAdvisorsPhoneNumber, setEditFinancialAdvisorsSurname, setEditPrimaryColor } from "./slice.ts";
import "./style.css";

export function RegistryFinancialAdvisor() {
    const dispatch = useAppDispatch()

    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const objectState = useAppSelector(state => state.object)
    const authState = useAppSelector(state => state.auth)

    const [avatarToBase64, setAvatarToBase64] = useState<string>('')
    const [color, setColor] = useColor(financialAdvisorState.findByIdFinancialAdvisorResponse?.primaryColor ?? authState.findMeFinancialAdvisorResponse?.primaryColor ?? '#3F48F3');

    useEffect(() => {
        if (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully") {
            dispatch(findFileById(financialAdvisorState.findByIdFinancialAdvisorResponse?.avatarObjectId!))
            dispatch(setEditFinancialAdvisorsCommercialName(financialAdvisorState.findByIdFinancialAdvisorResponse?.commercialName))
            dispatch(setEditFinancialAdvisorsEmail(financialAdvisorState.findByIdFinancialAdvisorResponse?.email))
            dispatch(setEditFinancialAdvisorsName(financialAdvisorState.findByIdFinancialAdvisorResponse?.name))
            dispatch(setEditFinancialAdvisorsPhoneNumber(financialAdvisorState.findByIdFinancialAdvisorResponse?.phoneNumber))
            dispatch(setEditFinancialAdvisorsSurname(financialAdvisorState.findByIdFinancialAdvisorResponse?.surname))
            dispatch(setEditFinancialAdvisorsNote(financialAdvisorState.findByIdFinancialAdvisorResponse?.note))
        } else if (authState.findMeFinancialAdvisorStatus === "successfully") {
            dispatch(findFileById(authState.findMeFinancialAdvisorResponse?.avatarObjectId!))
            dispatch(setEditFinancialAdvisorsCommercialName(authState.findMeFinancialAdvisorResponse?.commercialName))
            dispatch(setEditFinancialAdvisorsEmail(authState.findMeFinancialAdvisorResponse?.email))
            dispatch(setEditFinancialAdvisorsName(authState.findMeFinancialAdvisorResponse?.name))
            dispatch(setEditFinancialAdvisorsPhoneNumber(authState.findMeFinancialAdvisorResponse?.phoneNumber))
            dispatch(setEditFinancialAdvisorsSurname(authState.findMeFinancialAdvisorResponse?.surname))
            dispatch(setEditFinancialAdvisorsNote(authState.findMeFinancialAdvisorResponse?.note))
        }
    }, [financialAdvisorState.findByIdFinancialAdvisorStatus, keycloak.hasRealmRole, authState.findMeFinancialAdvisorStatus])

    useEffect(() => {
        if (objectState.findDocumentStatus === "successfully") {
            setAvatarToBase64(objectState.document!)
            dispatch(setFindDocumentStatus('idle'))
        }
    }, [objectState.findDocumentStatus])

    if (financialAdvisorState.findByIdFinancialAdvisorStatus === "loading" ||
        authState.findMeFinancialAdvisorStatus === "loading" ||
        financialAdvisorState.editFinancialAdvisorStatus === "loading" ||
        objectState.findDocumentStatus === "loading"
    ) {
        return (
            <div className={"flex flex-grow justify-center items-center"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className={"flex flex-col gap-5"}>
            <HeadingComponent
                label={"Anagrafica"}
                buttons={[]}
                size={HeadingSize.LG}
            />
            <FormRow
                label="Ragione Sociale e P.IVA"
                children={[
                    <>
                        <InputComponent
                            key={"edit-financial-advisor-commercial-name"}
                            type={"text"}
                            placeholder="Ragione Sociale"
                            value={financialAdvisorState.editFinancialAdvisorRequest?.commercialName}
                            onChangeText={(value) => {
                                dispatch(setEditFinancialAdvisorsCommercialName(value))
                            }}
                            disabled={!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")}
                        />

                        <InputComponent
                            key={"edit-financial-advisor-commercial-vat"}
                            type={"text"}
                            placeholder="IT"
                            value={financialAdvisorState.findByIdFinancialAdvisorResponse?.vat ?? authState.findMeFinancialAdvisorResponse?.vat}
                            disabled
                        />
                    </>
                ]}
            />
            <FormRow
                label="Nome e Cognome"
                children={[
                    <>
                        <InputComponent
                            key={"edit-financial-advisor-name"}
                            type={"text"}
                            placeholder="Nome"
                            value={financialAdvisorState.editFinancialAdvisorRequest?.name}
                            onChangeText={(value) => {
                                dispatch(setEditFinancialAdvisorsName(value))
                            }}
                            disabled={!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")}
                        />
                        <InputComponent
                            key={"edit-financial-advisor-surname"}
                            type={"text"}
                            placeholder="Cognome"
                            value={financialAdvisorState.editFinancialAdvisorRequest?.surname}
                            onChangeText={(value) => {
                                dispatch(setEditFinancialAdvisorsSurname(value))
                            }}
                            disabled={!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")}
                        />
                    </>
                ]}
            />
            <FormRow
                label="Email"
                children={[
                    <InputComponent
                        key={"edit-financial-advisor-email"}
                        type={"text"}
                        placeholder="Email"
                        startIcon={<EmailIcon size={"20"} color={colors.neutral[400]} />}
                        value={financialAdvisorState.editFinancialAdvisorRequest?.email}
                        onChangeText={(value) => dispatch(setCreateFinancialAdvisorsEmail(value))}
                        disabled
                    />
                ]}
            />
            <FormRow
                label="Telefono"
                children={[
                    <InputComponent
                        key={"edit-financial-advisor-phone"}
                        type={"text"}
                        placeholder="Telefono"
                        startIcon={<PhoneIcon size={"20"} color={colors.neutral[400]} />}
                        value={financialAdvisorState.editFinancialAdvisorRequest?.phoneNumber}
                        onChangeText={(value) => {
                            dispatch(setEditFinancialAdvisorsPhoneNumber(value))
                        }}
                        disabled={!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")}
                    />
                ]}
            />
            <FormRow
                label="Avatar"
                subLabel="Verrà visualizzata sul vostro profilo"
                children={[
                    <div key={"profile-edit-avatar-upload"} className={"flex items-center justify-center gap-4 w-full"}>
                        <Avatar
                            type="single"
                            size="2xl"
                            shape="circle"
                            imageUrl={avatarToBase64}
                            altTextInitials={(financialAdvisorState.editFinancialAdvisorRequest.name[0] || '') + (financialAdvisorState.editFinancialAdvisorRequest.surname[0] || '')}
                        />
                        <FileInputComponent
                            id={"profile-edit-upload"}
                            key={"profile-edit-upload"}
                            infoText
                            iconSize={"32"}
                            color={"gray"}
                            iconBackground={colors.neutral[100]}
                            onChange={(e) => {
                                if (e.length) {
                                    dispatch(setEditFinancialAdisorsFile(e[0]))
                                    toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                                }
                            }}
                            disabled={!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")}
                        />
                    </div>
                ]}
            />
            <FormRow
                label="Note"
                subLabel="Inserisci le note dell'utente"
                children={[
                    <InputComponent
                        type={"text"}
                        placeholder="Un po' di informazioni su di te"
                        supportingText={275 - financialAdvisorState.editFinancialAdvisorRequest.note.length + " caratteri rimasti"}
                        multiple
                        onChangeText={(value) => dispatch(setEditFinancialAdvisorsNote(value))}
                        defaultValue={financialAdvisorState.findByIdFinancialAdvisorResponse?.note ?? authState.findMeFinancialAdvisorResponse?.note}
                        disabled={!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")}
                    />
                ]}
            />
            <FormRow
                label="Tema"
                subLabel="Seleziona il colore del tema della tua app"
                children={[
                    <div className="w-2/4 gap-5 flex flex-col custom-picker">
                        <ColorPicker
                            color={color}
                            onChange={e => { { 
                                setColor(e); 
                                dispatch(setEditPrimaryColor(e.hex));
                                if(!keycloak.hasRealmRole("admin") && !keycloak.hasRealmRole("operator"))
                                    dispatch(setTheme(generateTheme(e.hex))) 
                                }
                             }}
                            hideInput={["rgb", "hsv"]}
                            disabled={!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")}
                        />
                        <ButtonComponent
                            label="Resetta"
                            size={"md"}
                            iconPosition={"off"}
                            variant={"link"}
                            color={"blue"}
                            onClick={() => {
                                setColor({ hex: "#3F48F3", rgb: { r: 63, g: 72, b: 243, a: 1 }, hsv: { h: 235, s: 74, v: 95, a: 1 }, })
                                dispatch(setEditPrimaryColor(colors.brandPrimary[500]))
                                dispatch(setTheme(colors.brandPrimary))
                            }}
                            disabled={!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")}
                        />
                    </div>
                ]}
            />
        </div>
    )
}