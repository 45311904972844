import clsx from 'clsx'
import { format } from 'date-fns'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { keycloak } from '../../lib/keycloak/index.ts'
import { NewObjectService } from '../../lib/object/service.ts'
import { findFileById, setFindDocumentStatus } from '../../lib/object/slice.ts'
import { useAppDispatch, useAppSelector } from '../../lib/redux/hook.ts'
import { colors } from '../../ui/colors.ts'
import { CheckCircleIcon } from '../../ui/icons/checkCircleIcon.tsx'
import { CompanyIcon } from '../../ui/icons/companyIcon.tsx'
import { DashboardIcon } from '../../ui/icons/dashboardIcon.tsx'
import { DownloadIcon } from '../../ui/icons/downloadIcon.tsx'
import { FinancialAdvisorIcon } from '../../ui/icons/financialAdvisorIcon.tsx'
import { NoticeIcon } from '../../ui/icons/noticeIcon.tsx'
import { OperatorIcon } from '../../ui/icons/operatorIcon.tsx'
import { OtherIcon } from '../../ui/icons/otherIcon.tsx'
import { PdfIcon } from '../../ui/icons/pdfIcon.tsx'
import { XlsxIcon } from '../../ui/icons/xlsxIcon.tsx'
import { ZipIcon } from '../../ui/icons/zipIcon.tsx'
import { ChevronRightIcon } from '../../ui/icons/chevronRightIcon.tsx'
import { LayoutComponent } from '../../ui/layout/index.tsx'
import { ButtonComponent } from '../../ui/molecules/button/index.tsx'
import { SpinnerComponent } from '../../ui/molecules/spinner/index.tsx'
import { StatsCard } from '../../ui/molecules/statsCard/index.tsx'
import { StatsCardAlt } from '../../ui/molecules/statsCardAlt/index.tsx'
import { EmptyList } from '../../ui/organisms/emptyList/index.tsx'
import { GroupedRow } from '../../ui/organisms/groupedRow/index.tsx'
import { SegmentControl } from '../../ui/organisms/segmentControl/index.tsx'
import { MenuItems } from '../../ui/organisms/sidebar/dto.ts'
import { MessageFilterDTO, MessageType } from '../company/chat/dto.ts'
import { findChatsByLoggedUser, findLastMessage, setFindChatsByLoggedUserStatus } from '../company/chat/slice.ts'
import { DocumentDTO, DocumentF24Status, DocumentType } from "../company/documents/dto.ts"
import { findAllDocuments, findAllSentDocuments, findByIdDocument, findExtensionsFiles, setDocumentFilterCompanyId, setDocumentFilterFileName, setDocumentFilterItemsPerPage, setDocumentFilterMonth, setDocumentFilterPage, setDocumentFilterReceivedGroup, setDocumentFilterSenderGroup, setDocumentFilterStatus, setDocumentFilterType, setDocumentFilterTypology, setDocumentFilterYear, setFindAllDocumentsStatus, setFindExtensionsFilesStatus } from '../company/documents/slice.ts'
import { findAllCompany, setSelectedDocumentTab, setSelectedOperationTab } from '../company/slice.ts'
import { findAllCollaborator } from '../financialAdvisor/collaborator/slice.ts'
import { SubscriptionTierLimit, SubscriptionType } from '../financialAdvisor/dto.ts'
import { financialAdvisorActiveSubscription, findAllFinancialAdvisor, setSelectedTab } from '../financialAdvisor/slice.ts'
import { findAllNotice } from '../notice/slice.ts'
import { setSelectedDocumentType } from './slice.ts'

export function DashboardFinancialAdvisor() {
    const layoutState = useAppSelector(state => state.layout)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const documentState = useAppSelector(state => state.document)
    const dashboardState = useAppSelector(state => state.dashboard)
    const chatState = useAppSelector(state => state.chat)
    const noticeState = useAppSelector(state => state.notice)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const me = keycloak.hasRealmRole('financial_advisor') ? authState.findMeFinancialAdvisorResponse : authState.findMeCollaboratorResponse

    const [noticeF24, setNoticeF24] = useState<DocumentDTO[]>([])
    const [allDocuments, setAllDocuments] = useState<DocumentDTO[]>([])
    const [start, setStart] = useState(false)
    const objectService = NewObjectService()
    let documentRow: ReactNode[] = []

    const iconMap = new Map<string, ReactElement>([
        [".png", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".jpg", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".jpeg", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".pdf", <PdfIcon size={"24"} color={colors.red[500]} />],
        [".xlsx", <XlsxIcon size={"24"} color={colors.green[500]} />],
        [".xls", <XlsxIcon size={"24"} color={colors.green[500]} />],
        [".zip", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".txt", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
    ]);

    useEffect(() => {
        if (me) {
            if (keycloak.hasRealmRole('financial_advisor')) {
                dispatch(financialAdvisorActiveSubscription(me.id))
                dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
            }
            else {
                dispatch(findAllNotice({ ...noticeState.noticeFilters, receiver: 'true' }))
                dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
            }
            dispatch(findAllCompany(companyState.companyFilters))
            dispatch(findChatsByLoggedUser())
            dispatch(setDocumentFilterItemsPerPage(5))
            setDocumentFilterType(DocumentType.balance)
            dispatch(findAllDocuments({
                companyId: null,
                itemsPerPage: 20,
                order: true,
                page: 0,
                receiverGroup: "",
                referenceYear: 0,
                referenceMonth: 0,
                senderGroup: "operators",
                sort: "",
                type: "",
                typology: "",
                fileName: "",
                status: "",
                f24Status: ""
            }))
        }
    }, [me])

    useEffect(() => {
        if (chatState.findChatsByLoggedUserStatus === "successfully") {
            let request: { chatId: number, filter: MessageFilterDTO }[] = []
            chatState.findChatByLoggedUserResponse.
                filter((element) => element.lastMessageDate !== null).
                sort((a, b) => new Date(b.lastMessageDate).getTime() - new Date(a.lastMessageDate).getTime()).
                forEach((chat) => (
                    request.push({
                        chatId: chat.id, filter: {
                            page: 0,
                            itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25),
                            order: chatState.messageFilter.order,
                            sort: chatState.messageFilter.sort
                        }
                    })
                ))
            dispatch(findLastMessage(request))
        }
    }, [chatState.findChatsByLoggedUserStatus])

    useEffect(() => {
        if (chatState.findChatsByLoggedUserStatus === 'successfully' || chatState.findChatsByLoggedUserStatus === 'failed')
            setFindChatsByLoggedUserStatus('idle')
    }, [chatState.findChatsByLoggedUserStatus])

    useEffect(() => {
        if ((documentState.findAllDocumentsStatus === "successfully" && documentState.findAllDocumentsResponse !== undefined)) {
            if (!start) {
                setNoticeF24(documentState.findAllDocumentsResponse.data.
                    filter(item => item.f24Status === DocumentF24Status.Delay))
            }
            if (allDocuments.length === 0)
                setAllDocuments(documentState.findAllDocumentsResponse.data)
            const updatedRequest = {
                ...documentState.findExtensionsFilesRequest,
                ids: [
                    // ...documentState.findExtensionsFilesRequest.ids,
                    ...documentState.findAllDocumentsResponse.data.slice().sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map(data => String(data.objectId))
                ]
            };
            dispatch(findExtensionsFiles(updatedRequest))
        }
    }, [documentState.findAllDocumentsStatus])

    useEffect(() => {
        if (start)
            dispatch(findAllDocuments({
                companyId: null,
                itemsPerPage: 20,
                order: true,
                page: 0,
                receiverGroup: "",
                referenceYear: 0,
                referenceMonth: 0,
                senderGroup: "operators",
                sort: "",
                type: documentState.documentFilters.type,
                typology: "",
                fileName: "",
                status: "",
                f24Status: ""
            }))
    }, [documentState.documentFilters.type])

    if (((documentState.findAllDocumentsStatus === "successfully" &&
        documentState.findAllDocumentsResponse !== undefined) ||
        (documentState.findAllSentDocumentsStatus === 'successfully' && documentState.findAllSentDocumentsResponse))
        &&
        documentState.findExtensionsFilesStatus === "successfully" &&
        companyState.findAllCompanyResponse !== undefined &&
        companyState.findAllCompanyStatus === 'successfully'
    ) {
        if (!start) setStart(true)
        if (documentState.findAllDocumentsStatus === "successfully" &&
            documentState.findAllDocumentsResponse !== undefined)
            documentState.findAllDocumentsResponse.data.slice().sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).slice(0, 5).forEach((item, index) => {
                documentRow.push(
                    <GroupedRow
                        key={item.id}
                        toRead={item.companiesDownloadDate !== null}
                        icon={iconMap.get(documentState.findExtensionsFilesResponse?.extensions[index]!)!}
                        onClick={() => {
                            dispatch(findFileById(item.objectId.toString())).then((e) => {
                                //@ts-ignore
                                objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                dispatch(findByIdDocument([item.id.toString()])).then(() => {
                                    dispatch(setFindDocumentStatus('idle'))
                                    dispatch(findAllDocuments(documentState.documentFilters))
                                })
                            })
                        }}
                        label={item.fileName}
                        subLabel={format(item.date, "dd-MM-yyyy") + " a "}
                        thirdLabel={companyState.findAllCompanyResponse?.data.
                            find(element => element.id.toString() === item.companyId.toString())?.companyName}
                        secondIcon={
                            <DownloadIcon
                                size={"24"}
                                color={layoutState.theme[500]}
                                onClick={() => {
                                    dispatch(findFileById(item.objectId.toString())).then((e) => {
                                        //@ts-ignore
                                        objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                        dispatch(findByIdDocument([item.id.toString()])).then(() => {
                                            dispatch(setFindDocumentStatus('idle'))
                                            dispatch(findAllDocuments(documentState.documentFilters))
                                        })
                                    })
                                }}
                            />}
                    />
                )
            })
        else if (documentState.findAllSentDocumentsStatus === 'successfully' && documentState.findAllSentDocumentsResponse)
            documentState.findAllSentDocumentsResponse.data.slice().sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).forEach((item, index) => {
                documentRow.push(
                    <GroupedRow
                        key={item.id}
                        toRead={item.companiesDownloadDate !== null}
                        icon={iconMap.get(documentState.findExtensionsFilesResponse?.extensions[index]!)!}
                        onClick={() => {
                            dispatch(findFileById(item.objectId.toString())).then((e) => {
                                //@ts-ignore
                                objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                dispatch(findByIdDocument([item.id.toString()])).then(() => {
                                    dispatch(setFindDocumentStatus('idle'))
                                    dispatch(findAllDocuments(documentState.documentFilters))
                                })
                            })
                        }}
                        label={item.fileName}
                        subLabel={format(item.date, "dd-MM-yyyy") + " a "}
                        thirdLabel={companyState.findAllCompanyResponse?.data.
                            find(element => element.id.toString() === item.companyId.toString())?.companyName}
                        secondIcon={
                            <DownloadIcon
                                size={"24"}
                                color={layoutState.theme[500]}
                                onClick={() => {
                                    dispatch(findFileById(item.objectId.toString())).then((e) => {
                                        //@ts-ignore
                                        objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                        dispatch(findByIdDocument([item.id.toString()])).then(() => {
                                            dispatch(setFindDocumentStatus('idle'))
                                            dispatch(findAllDocuments(documentState.documentFilters))
                                        })
                                    })
                                }}
                            />}
                    />
                )
            })
    }
    return (
        <LayoutComponent
            menuItem={MenuItems.DASHBOARD}
            breadcrumbItems={['Dashboard']}
            headingLabel={"Dashboard"}
            headingButtons={[]}
            breadcrumbIcon={<DashboardIcon color={layoutState.theme[500]} size="24" />}
        >
            {
                authState.findMeFinancialAdvisorResponse?.subscriptionType === SubscriptionType.expired ?
                    <div className='flex items-center justify-center flex-grow '>
                        <div className='flex items-center justify-center flex-col gap-4 shadow-lg p-6 border rounded-3xl w-[80%]' style={{ borderColor: layoutState.theme[200] }}>
                            <span className='text-text-2xl text-neutral-800 font-semibold'>
                                Ciao {authState.findMeFinancialAdvisorResponse.name}
                            </span>
                            <span className='text-text-md text-neutral-600 font-normal text-center'>
                                {"Per iniziare a usare Fiscalbright, scegli un piano in Impostazioni > Gestione Abbonamento o clicca sul pulsante qui sotto."} <br></br>
                                {"Se vuoi provarlo prima, attiva il Piano Free, è gratis!"}
                            </span>
                            <ButtonComponent
                                size={'md'}
                                iconPosition={'off'}
                                variant={'solid'}
                                label='Inizia con Fiscalbright'
                                color={'blue'}
                                onClick={() => {
                                    dispatch(setSelectedTab(2))
                                    navigate('/setting')
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div className={"flex flex-col gap-3 w-full"}>
                        <div className={"flex flex-row gap-4 justify-between pb-6"}>
                            <div className=" flex flex-col w-full gap-2">
                                <div className="flex flex-row gap-4 flex-grow ">
                                    <div className='  flex flex-row gap-4 basis-[50%]'>
                                        <StatsCard title={"Aziende"} value={companyState.findAllCompanyResponse ?
                                            companyState.findAllCompanyResponse.total.toString() : <SpinnerComponent />}
                                            icon={<FinancialAdvisorIcon size={"28"} color={layoutState.theme[500]} />}
                                            onClick={() => navigate('/company')}
                                        />
                                        {keycloak.hasRealmRole('financial_advisor') &&
                                            < StatsCard title={"Collaboratori"} value={collaboratorState.findAllCollaboratorResponse ?
                                                collaboratorState.findAllCollaboratorResponse.total.toString() : <SpinnerComponent />}
                                                icon={<OperatorIcon size={"28"} color={layoutState.theme[500]} />}
                                                onClick={() => navigate('/collaborator')}
                                            />
                                        }
                                        {!keycloak.hasRealmRole('financial_advisor') && <StatsCard title={"Avvisi ricevuti"} value={noticeState.findAllNoticeResponse ?
                                            noticeState.findAllNoticeResponse.total : <SpinnerComponent />}
                                            icon={<NoticeIcon size={"28"} color={layoutState.theme[500]} />}
                                            onClick={() => navigate('/notice')}
                                        />
                                        }
                                    </div>
                                    {keycloak.hasRealmRole('financial_advisor') &&
                                        <div className='basis-[50%]'>
                                            <StatsCardAlt rightLabel={financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit !== 'free' &&
                                                <div className=' flex flex-row items-center gap-2'>
                                                    <span className={clsx("", {
                                                        'text-green-500':
                                                            financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate!.toLocaleString().localeCompare(new Date().toLocaleDateString()),
                                                        'text-red-900':
                                                            !financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.toLocaleString().localeCompare(new Date().toLocaleDateString())
                                                    })}>
                                                        {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate!.
                                                            toLocaleString().localeCompare(new Date().toLocaleDateString()) ? 'Attivo' : 'Scaduto'}
                                                    </span>
                                                    {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate!.
                                                        toLocaleString().localeCompare(new Date().toLocaleDateString()) && <CheckCircleIcon />}
                                                </div>
                                            }
                                                title={'Piano ' + (financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies !== 0 ?
                                                    financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit.toString().toUpperCase() : 'Da attivare'.toUpperCase())}
                                                content={
                                                    <>
                                                        <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                            <span className=" text-brandPrimary-850 font-semibold text-[12px] leading-4 lg:text-[13px] lg:leading-[18px]">Max Documenti - Aziende - Collaboratori</span>
                                                            <span className=" text-brandSecondary-500 text-[12px] leading-4 lg:text-[13px] lg:leading-[18px]">{
                                                                financialAdvisorState.financialAdvisorActiveSubscriptionResponse &&
                                                                financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfDocuments + " - " + financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies + " - " + (financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit === SubscriptionTierLimit.unlimited ? '∞' : financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCollaborators)}
                                                            </span>
                                                        </div>
                                                        <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                            <span className=" text-brandPrimary-850 font-semibold text-[12px] leading-4 lg:text-[13px] lg:leading-[18px]">Data attivazione</span>
                                                            <span className=" text-brandSecondary-500 text-[12px] leading-4 lg:text-[13px] lg:leading-[18px]">
                                                                {
                                                                    financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies !== 0 ?
                                                                        financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit !== SubscriptionTierLimit.free ?
                                                                            new Date(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.renewalDate as Date).toLocaleDateString() : '-' :
                                                                        'N/A'
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                            <span className=" text-brandPrimary-850 font-semibold text-[12px] leading-4 lg:text-[13px] lg:leading-[18px]" >Prossimo rinnovo</span>
                                                            <span className=" text-brandSecondary-500 text-[12px] leading-4 lg:text-[13px] lg:leading-[18px]">{
                                                                financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies !== 0 ?
                                                                    financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit !== SubscriptionTierLimit.free ?
                                                                        new Date(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate as Date).toLocaleDateString() : '-' :
                                                                    'N/A'
                                                            }
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                                label="Gestisci abbonamento"
                                                onClick={() => {
                                                    dispatch(setSelectedTab(2))
                                                    navigate('/setting')
                                                }}
                                            />
                                        </div>
                                    }
                                    {!keycloak.hasRealmRole('financial_advisor') && <div className=' basis-[50%] flex-grow'>
                                        <StatsCardAlt title={'Il tuo commercialista'} rightLabel={
                                            <span className=' text-brandSecondary-850 font-bold'>
                                                {financialAdvisorState.findAllFinancialAdvisorResponse ?
                                                    financialAdvisorState.findAllFinancialAdvisorResponse?.data[0].name + " " +
                                                    financialAdvisorState.findAllFinancialAdvisorResponse?.data[0].surname : ''}
                                            </span>}
                                            content={
                                                <>
                                                    <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                        <span className=" text-brandPrimary-850 font-semibold text-[13px] leading-[18px]">Ragione sociale</span>
                                                        <span className=" text-brandSecondary-500 text-[13px] leading-[18px]">
                                                            {financialAdvisorState.findAllFinancialAdvisorResponse && financialAdvisorState.findAllFinancialAdvisorResponse?.data[0].commercialName}
                                                        </span>
                                                    </div>
                                                    <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                        <span className=" text-brandPrimary-850 font-semibold text-[13px] leading-[18px]">Email</span>
                                                        <span className=" text-brandSecondary-500 text-[13px] leading-[18px]" >
                                                            {financialAdvisorState.findAllFinancialAdvisorResponse && financialAdvisorState.findAllFinancialAdvisorResponse?.data[0].email}
                                                        </span>
                                                    </div>
                                                    <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                        <span className=" text-brandPrimary-850 font-semibold text-[13px] leading-[18px]">Recapito telefonico</span>
                                                        <span className=" text-brandSecondary-500 text-[13px] leading-[18px]">
                                                            {financialAdvisorState.findAllFinancialAdvisorResponse && financialAdvisorState.findAllFinancialAdvisorResponse?.data[0].phoneNumber}
                                                        </span>
                                                    </div>
                                                </>
                                            }
                                        />
                                    </div>}
                                </div>
                                <div className='flex flex-row gap-4 flex-grow'>
                                    <div className="p-6 rounded-3xl flex flex-col flex-grow basis-[50%] gap-6 border shadow-md w-full" style={{ borderColor: layoutState.theme[200], boxShadow: "0px 4px 24px -8px rgba(26, 37, 255, 0.08)" }}>
                                        <div className="flex flex-row gap-2 items-center">
                                            <OtherIcon size={"24"} color={layoutState.theme[400]} />
                                            <span className="text-label-md font-bold text-neutral-800">Ultimi documenti inviati</span>
                                        </div>
                                        <div className="flex flex-row justify-start gap-3 max-w-full overflow-scroll">
                                            <SegmentControl
                                                selected={dashboardState.selectedDocumentType === 0}
                                                label={"Bilanci"}
                                                onClick={() => {
                                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                                    dispatch(setDocumentFilterType(documentState.documentFilters.type === DocumentType.balance ? '' : DocumentType.balance))
                                                    dispatch(setDocumentFilterItemsPerPage(5))
                                                    dispatch(setFindAllDocumentsStatus("idle"))
                                                    dispatch(setSelectedDocumentType(dashboardState.selectedDocumentType === 0 ? undefined : 0))
                                                    dispatch(findAllDocuments({
                                                        companyId: null,
                                                        itemsPerPage: 20,
                                                        order: false,
                                                        page: 0,
                                                        receiverGroup: "",
                                                        referenceYear: 0,
                                                        referenceMonth: 0,
                                                        senderGroup: "operators",
                                                        sort: "",
                                                        type: documentState.documentFilters.type === DocumentType.balance ? '' : DocumentType.balance,
                                                        typology: "",
                                                        fileName: "",
                                                        status: "",
                                                        f24Status: ""
                                                    }))
                                                }}
                                            />
                                            <SegmentControl
                                                selected={dashboardState.selectedDocumentType === 1}
                                                label={"F24"}
                                                onClick={() => {
                                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                                    dispatch(setDocumentFilterType(documentState.documentFilters.type === DocumentType.f24 ? '' : DocumentType.f24))
                                                    dispatch(setDocumentFilterItemsPerPage(5))
                                                    dispatch(setFindAllDocumentsStatus("idle"))
                                                    dispatch(setSelectedDocumentType(dashboardState.selectedDocumentType === 1 ? undefined : 1))
                                                    dispatch(findAllDocuments({
                                                        companyId: null,
                                                        itemsPerPage: 20,
                                                        order: false,
                                                        page: 0,
                                                        receiverGroup: "",
                                                        referenceYear: 0,
                                                        referenceMonth: 0,
                                                        senderGroup: "operators",
                                                        sort: "",
                                                        type: documentState.documentFilters.type === DocumentType.f24 ? '' : DocumentType.f24,
                                                        typology: "",
                                                        fileName: "",
                                                        status: "",
                                                        f24Status: ""
                                                    }))
                                                }}
                                            />
                                            <SegmentControl
                                                selected={dashboardState.selectedDocumentType === 2}
                                                label={"Dipendenti"}
                                                onClick={() => {
                                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                                    dispatch(setDocumentFilterType(documentState.documentFilters.type === DocumentType.employees ? '' : DocumentType.employees))
                                                    dispatch(setDocumentFilterItemsPerPage(5))
                                                    dispatch(setFindAllDocumentsStatus("idle"))
                                                    dispatch(setSelectedDocumentType(dashboardState.selectedDocumentType === 2 ? undefined : 2))
                                                    dispatch(findAllDocuments({
                                                        companyId: null,
                                                        itemsPerPage: 20,
                                                        order: false,
                                                        page: 0,
                                                        receiverGroup: "",
                                                        referenceYear: 0,
                                                        referenceMonth: 0,
                                                        senderGroup: "operators",
                                                        sort: "",
                                                        type: documentState.documentFilters.type === DocumentType.employees ? '' : DocumentType.employees,
                                                        typology: "",
                                                        fileName: "",
                                                        status: "",
                                                        f24Status: ""
                                                    }))
                                                }}
                                            />
                                            <SegmentControl
                                                selected={dashboardState.selectedDocumentType === 3}
                                                label={"Altro"}
                                                onClick={() => {
                                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                                    dispatch(setDocumentFilterType(documentState.documentFilters.type === DocumentType.other ? '' : DocumentType.other))
                                                    dispatch(setDocumentFilterItemsPerPage(5))
                                                    dispatch(setFindAllDocumentsStatus("idle"))
                                                    dispatch(setSelectedDocumentType(dashboardState.selectedDocumentType === 3 ? undefined : 3))
                                                    dispatch(findAllDocuments({
                                                        companyId: null,
                                                        itemsPerPage: 20,
                                                        order: false,
                                                        page: 0,
                                                        receiverGroup: "",
                                                        referenceYear: 0,
                                                        referenceMonth: 0,
                                                        senderGroup: "operators",
                                                        sort: "",
                                                        type: documentState.documentFilters.type === DocumentType.other ? '' : DocumentType.other,
                                                        typology: "",
                                                        fileName: "",
                                                        status: "",
                                                        f24Status: ""
                                                    }))
                                                }}
                                            />
                                            <SegmentControl
                                                selected={dashboardState.selectedDocumentType === 4}
                                                label={"Contabili"}
                                                onClick={() => {
                                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                                    dispatch(setDocumentFilterType(documentState.documentFilters.type === DocumentType.accounting ? '' : DocumentType.accounting))
                                                    dispatch(setDocumentFilterItemsPerPage(5))
                                                    dispatch(setFindAllDocumentsStatus("idle"))
                                                    dispatch(setSelectedDocumentType(dashboardState.selectedDocumentType === 4 ? undefined : 4))
                                                    dispatch(findAllSentDocuments({
                                                        companyId: null,
                                                        itemsPerPage: 20,
                                                        order: false,
                                                        page: 0,
                                                        receiverGroup: "",
                                                        referenceYear: 0,
                                                        referenceMonth: 0,
                                                        senderGroup: "operators",
                                                        sort: "",
                                                        type: documentState.documentFilters.type === DocumentType.accounting ? '' : DocumentType.accounting,
                                                        typology: "",
                                                        fileName: "",
                                                        status: "",
                                                        f24Status: ""
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-col rounded-2xl gap-3 flex-grow">
                                            {
                                                (documentState.findAllDocumentsStatus === "loading" ||
                                                    documentState.findExtensionsFilesStatus === "loading") ?
                                                    <div className="flex justify-center items-center flex-grow">
                                                        <SpinnerComponent />
                                                    </div>
                                                    :
                                                    documentState.findAllDocumentsStatus === "successfully" && documentRow.length === 0 ?
                                                        <div className="flex justify-center items-center flex-grow">
                                                            <EmptyList isSmall />
                                                        </div>
                                                        :
                                                        <div className="w-full flex flex-col gap-2 max-h-[430px] overflow-y-auto">
                                                            {documentRow.slice(0, 5)}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                    <div className=' flex flex-col basis-[50%] gap-4'>
                                        <StatsCardAlt onClick={() => navigate('/company')} title={'Ultimi messaggi'}
                                            content={
                                                chatState.findChatsByLoggedUserStatus === 'loading' ?
                                                    <SpinnerComponent /> :
                                                    chatState.findChatByLoggedUserResponse.filter(item => item.lastMessageDate !== null).length > 0 ?
                                                        chatState.findChatByLoggedUserResponse.
                                                            filter((element) => element.lastMessageDate !== null).
                                                            sort((a, b) => new Date(b.lastMessageDate).getTime() - new Date(a.lastMessageDate).getTime()).
                                                            slice(0, 3).map(
                                                                (item, index) =>
                                                                    <div onClick={() => {
                                                                        navigate('/operation-company/' + item.topic)
                                                                        dispatch(setSelectedOperationTab(2))
                                                                    }}
                                                                        className=' flex flex-row hover:bg-neutral-100 cursor-pointer justify-between items-center w-full border-b border-brandSecondary-200 py-[6px] px-2 gap-4'>
                                                                        <div className=' basis-[30%] flex  gap-1 '>
                                                                            <span className='text-[13px] leading-[18px] text-brandSecondary-800 font-semibold'>{
                                                                                companyState.findAllCompanyResponse && companyState.findAllCompanyResponse!.data.
                                                                                    find(el => el.id.toString() === item.topic)!.companyName}
                                                                            </span>
                                                                            {companyState.findAllCompanyResponse &&
                                                                                item.users.includes(companyState.findAllCompanyResponse.data.
                                                                                    find(el => el.id.toString() === item.topic)!.userId) ?
                                                                                <CompanyIcon size={'20'} color={layoutState.theme[500]} /> :
                                                                                <OperatorIcon size={'20'} color={layoutState.theme[500]} />
                                                                            }
                                                                        </div>
                                                                        <div className=' basis-[70%] h-[18px] overflow-clip flex gap-2 justify-start'>
                                                                            <span className=' text-[13px] leading-[18px] text-brandSecondary-500'>
                                                                                {chatState.findLastMessageResponse && (
                                                                                    chatState.findLastMessageResponse[index].messages.data[0].type === MessageType.message ?
                                                                                        chatState.findLastMessageResponse[index].messages.data[0].message.substring(0, 70).concat('...') :
                                                                                        (chatState.findLastMessageResponse[index].messages.data[0].message.length <= 20 ?
                                                                                            chatState.findLastMessageResponse[index].messages.data[0].message.replace(/^.*?;/, ''):
                                                                                            chatState.findLastMessageResponse[index].messages.data[0].message.replace(/^.*?;/, '').substring(0, 20).concat('...')))
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                            ) : <EmptyList isSmall />
                                            }
                                            label={'Vai alla sezione'} />
                                        <StatsCardAlt title={'Avvisi di proroga F24'} content={documentState.findAllDocumentsStatus !== "loading" ? noticeF24.length ?
                                            (noticeF24).slice(0, 3).map(item =>
                                                <div onClick={() => {
                                                    dispatch(setSelectedOperationTab(1))
                                                    dispatch(setFindAllDocumentsStatus("idle"))
                                                    dispatch(setDocumentFilterCompanyId(item.companyId))
                                                    dispatch(setDocumentFilterPage(0))
                                                    dispatch(setDocumentFilterFileName(""))
                                                    dispatch(setDocumentFilterMonth(0))
                                                    dispatch(setDocumentFilterTypology(""))
                                                    dispatch(setDocumentFilterYear(0))
                                                    dispatch(setDocumentFilterType(DocumentType.f24))
                                                    dispatch(setDocumentFilterReceivedGroup(""))
                                                    dispatch(setDocumentFilterSenderGroup(""))
                                                    dispatch(setDocumentFilterStatus(""))
                                                    dispatch(setSelectedDocumentTab(1))
                                                    dispatch(findAllDocuments(documentState.documentFilters))
                                                    navigate('operation-company/' + item.companyId);
                                                }} className=" flex flex-row justify-between border-b border-brandPrimary-100 hover:bg-neutral-100 py-[6px] px-2 gap-4 cursor-pointer">
                                                    <span className=' text-brandSecondary-850 font-semibold text-[13px] leading-[18px]'>
                                                        {companyState.findAllCompanyResponse?.data.find(element =>
                                                            element.id.toString() === item.companyId.toString())?.companyName}
                                                    </span>
                                                    <div className=' flex flex-row gap-4 items-center'>
                                                        <span
                                                            className=' text-brandSecondary-500 text-[13px] leading-[18px]'>{item.fileName}
                                                        </span>
                                                        <ChevronRightIcon size={''} color={''} />
                                                    </div>
                                                </div>
                                            ) : <EmptyList isSmall /> :
                                            <div className=' flex justify-center items-center'>
                                                <SpinnerComponent />
                                            </div>
                                        }
                                            label={'Vai alla sezione'} onClick={() => navigate('/company')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </LayoutComponent>
    )
}