import clsx from 'clsx';
import { format } from 'date-fns';
import React, { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../lib/redux/hook.ts';
import { PromiseStatuses } from '../../lib/utilis/index.ts';
import { colors } from '../../ui/colors.ts';
import { CompanyIcon } from '../../ui/icons/companyIcon.tsx';
import { ShoppingBagIcon } from '../../ui/icons/shoppingBagIcon.tsx';
import { TickIcon } from '../../ui/icons/tickIcon.tsx';
import { ButtonComponent } from '../../ui/molecules/button/index.tsx';
import { InputComponent } from '../../ui/molecules/input/index.tsx';
import { PaymentSwitch } from '../../ui/molecules/paymentSwitch/index.tsx';
import { ProgressBarColor } from '../../ui/molecules/progressBar/dto.ts';
import { ProgressBar } from '../../ui/molecules/progressBar/index.tsx';
import { SpinnerComponent } from '../../ui/molecules/spinner/index.tsx';
import { ConfirmModal } from '../../ui/organisms/confirmModal/index.tsx';
import { ErrorPopup } from '../../ui/organisms/errorPopup/index.tsx';
import { SubscriptionChoiceCard } from '../../ui/organisms/subscriptionChoiceCard/index.tsx';
import { SuccessPopup } from '../../ui/organisms/successPopup/index.tsx';
import { findMeFinancialAdvisor, setFindMeFinancialAdvisorStatus } from '../auth/slice.ts';
import { findAllCompany } from '../company/slice.ts';
import { SubscriptionPeriod, subscriptionPeriodMap, SubscriptionTierLimit, subscriptionTierLimitMap, SubscriptionType } from './dto.ts';
import { deletePlanStripe, financialAdvisorActiveSubscription, findStripeProduct, selectPlanStripe, setDeletePlanStatus, setFilterFindProductPeriod, setFinancialAdvisorActiveSubscriptionStatusStatus, setFindStripeProductStatus, setPromoCode, setSelectedPlan, setSelectPlanStripeStatus, setUnsubscribeModal, valideStripePromoCode } from './slice.ts';
import { EmployeeIcon } from '../../ui/icons/employeeIcon.tsx';
import { findAllCollaborator } from './collaborator/slice.ts';
import { keycloak } from '../../lib/keycloak/index.ts';

const cache = {}

export function SubscriptionManagement() {
  const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
  const authState = useAppSelector(state => state.auth)
  const layoutState = useAppSelector(state => state.layout)
  const companyState = useAppSelector(state => state.company)
  const collaboratorState = useAppSelector(state => state.collaborator)
  const dispatch = useAppDispatch()

  const [status, setStatus] = useState<PromiseStatuses>("loading")

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  let monthCount = financialAdvisorState.filterFindProduct.period === 'year' ? 12 : 3

  let listProduct: ReactNode[] = []
  let promo: number = 0
  let finalPrice: number = 0

  const labels = [
    { label: "Annuale", value: SubscriptionType.year },
    { label: "Trimestrale", value: SubscriptionType.month },
  ];

  const formatPrice = (amount: number, type: string, name: string) => {
    let unitAmount: string;

    if (type === SubscriptionType.year) {
      let price = amount / 100
      let price3 = price / 12
      if (price3 !== 0)
        unitAmount = price3.toFixed(2)
      else unitAmount = '0'
    } else {
      let price = amount / 100
      let price2 = price / 3
      unitAmount = name === "Free" ? price2.toString() : price2.toFixed(2)
    }

    return unitAmount
  }

  useEffect(() => {
    if (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully" && financialAdvisorState.findByIdFinancialAdvisorResponse !== undefined) {
      dispatch(financialAdvisorActiveSubscription(financialAdvisorState.findByIdFinancialAdvisorResponse?.id))
      dispatch(findAllCompany({
        itemsPerPage: 0,
        order: false,
        page: 0,
        sort: '',
        companyName: '',
        financialAdvisorId: financialAdvisorState.findByIdFinancialAdvisorResponse?.id,
        status: ''
      }))
      dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
    }
  }, [financialAdvisorState.findByIdFinancialAdvisorStatus])

  useEffect(() => {
    if (authState.findMeFinancialAdvisorStatus === "successfully" && authState.findMeFinancialAdvisorResponse !== undefined) {
      dispatch(financialAdvisorActiveSubscription(authState.findMeFinancialAdvisorResponse?.id))
      dispatch(findAllCompany({
        itemsPerPage: 0,
        order: false,
        page: 0,
        sort: '',
        companyName: '',
        financialAdvisorId: authState.findMeFinancialAdvisorResponse?.id,
        status: ''
      }))
    }
    dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
    dispatch(setFindMeFinancialAdvisorStatus('idle'))
  }, [authState.findMeFinancialAdvisorStatus])

  useEffect(() => {
    if (financialAdvisorState.deletePlanStripeStatus === "successfully") {
      dispatch(financialAdvisorActiveSubscription(financialAdvisorState.findByIdFinancialAdvisorResponse?.id!))
    }
  }, [financialAdvisorState.deletePlanStripeStatus])

  useEffect(() => {
    if (financialAdvisorState.financialAdvisorActiveSubscriptionStatus === "successfully") {
      dispatch(setFilterFindProductPeriod(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionPeriod))
      dispatch(findStripeProduct({
        period: financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionPeriod!,
        sort: true
      }))
    }
  }, [financialAdvisorState.financialAdvisorActiveSubscriptionStatus])

  useEffect(() => {
    if (
      financialAdvisorState.financialAdvisorActiveSubscriptionStatus === "successfully" &&
      financialAdvisorState.findStripeProductStatus === "successfully"
    ) {
      const cacheKey = `findStripeProduct-${financialAdvisorState.filterFindProduct.period}-${financialAdvisorState.filterFindProduct.sort}`;

      let plan;

      if (cache[cacheKey]) {
        plan = cache[cacheKey].find(name => name.name === subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit!));
      } else {
        plan = financialAdvisorState.findStripeProductResponse?.filter(name => name.name === subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit!)).find(plan => plan);

        cache[cacheKey] = financialAdvisorState.findStripeProductResponse;
      }

      dispatch(setSelectedPlan({
        id: plan?.id,
        price: formatPrice(Number(plan?.unitAmount), financialAdvisorState.filterFindProduct.period, plan?.name!),
        name: plan?.name,
        period: financialAdvisorState.filterFindProduct.period
      }));
      setStatus('successfully')
      dispatch(setFinancialAdvisorActiveSubscriptionStatusStatus('idle'))
      dispatch(setFindStripeProductStatus('idle'))
    }
  }, [financialAdvisorState.financialAdvisorActiveSubscriptionStatus, financialAdvisorState.findStripeProductStatus]);

  useEffect(() => {
    if (financialAdvisorState.selectPlanStripeStatus === "successfully" && financialAdvisorState.selectPlanStripeResponse !== undefined) {
      if (financialAdvisorState.selectedPlan?.name === "Free") {
        dispatch(financialAdvisorActiveSubscription(financialAdvisorState.findByIdFinancialAdvisorResponse?.id!))
        if (keycloak.hasRealmRole("financial_advisor")) {
          dispatch(findMeFinancialAdvisor())
        }
      } else {
        const link = document.createElement('a');
        link.href = financialAdvisorState.selectPlanStripeResponse.url
        link.rel = "noreferrer"
        link.target = "_blank"
        link.click();
      }
      dispatch(setSelectPlanStripeStatus('idle'))
    }
  }, [financialAdvisorState.selectPlanStripeStatus])

  if (financialAdvisorState.valideStripePromoCodeStatus === "successfully" &&
    financialAdvisorState.valideStripePromoCodeResponse?.discountPercentage !== undefined
  ) {
    promo = Number(financialAdvisorState.selectedPlan?.price) / 100 * financialAdvisorState.valideStripePromoCodeResponse?.discountPercentage * (financialAdvisorState.filterFindProduct.period === 'year' ? 12 : 3)
    finalPrice = Number(financialAdvisorState.selectedPlan?.price)- promo
  }

  if (financialAdvisorState.findStripeProductResponse !== undefined) {
    financialAdvisorState.findStripeProductResponse.forEach((product) => {
      listProduct.push(
        <SubscriptionChoiceCard
          onClick={() => {
            dispatch(setSelectedPlan({ id: product.id, price: formatPrice(Number(product.unitAmount), financialAdvisorState.filterFindProduct.period, product.name), name: product.name, period: financialAdvisorState.filterFindProduct.period }))
          }}
          isLoading={financialAdvisorState.findStripeProductStatus === "loading" || !financialAdvisorState.selectedPlan}
          name={product.name}
          limitInfo={product.name !== 'Free' ? product.description.split(';')[0] : ''}
          price={formatPrice(Number(product.unitAmount), financialAdvisorState.filterFindProduct.period, product.name).replace('.', ',')}
          onChange={() => {
            dispatch(setSelectedPlan({ id: product.id, price: formatPrice(Number(product.unitAmount), financialAdvisorState.filterFindProduct.period, product.name), name: product.name, period: financialAdvisorState.filterFindProduct.period }))
          }}
          checked={financialAdvisorState.selectedPlan?.id === product.id}
          infoProduct={
            <div key={product.id}>
              {(product.description.split(';').slice(product.name === 'Free' ? 0 : 1, product.description.split(';').length))
                .map(item =>
                  <div key={item} className='flex flex-row items-center gap-2'>
                    <TickIcon color={colors.green[500]} size='12' />
                    <span className=' font-medium text-text-sm text-neutral-800'>
                      {item}
                    </span>
                  </div>)}
            </div>
          }
        />
      )
    })
  }

  if (financialAdvisorState.financialAdvisorActiveSubscriptionStatus === "loading" || companyState.findAllCompanyStatus === "loading" || status === "loading" || financialAdvisorState.deletePlanStripeStatus === "loading") {
    return (
      <div className='flex justify-center items-center flex-grow'>
        <SpinnerComponent />
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-6'>
      <SuccessPopup
        active={financialAdvisorState.deletePlanStripeStatus === "successfully"}
        close={() => dispatch(setDeletePlanStatus("idle"))}
        message='Abbonamento disdetto con successo. Potrai continuare ad usare il tuo abbonamento fino alla scadenza.'
      />
      <ErrorPopup
        active={financialAdvisorState.deletePlanStripeStatus === "failed"}
        close={() => dispatch(setDeletePlanStatus("idle"))}
        message='Si è verificato un errore durante la cancellazione del tuo abbonamento.'
      />
      <div className='w-full flex flex-row justify-around flex-grow items-center border rounded-xl p-6' style={{ borderColor: layoutState.theme[200] }}>
        <div className='flex flex-col gap-3 w-[50%]'>
          <ShoppingBagIcon size={'32'} color={layoutState.theme[400]} />
          <div className='flex flex-col'>
            <span className='text-text-sm font-medium text-neutral-500'>Il tuo piano</span>
            <span className='text-heading-xs font-bold text-neutral-850'>
              {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies === 0 ?
                "Da attivare" :
                "Piano " + subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit!)}
            </span>
          </div>
        </div>
        <div className='flex flex-col gap-3 w-full'>
          <div className='flex flex-col gap-2'>
            <div className='flex flex-row gap-[6px]'>
              <CompanyIcon size={'18'} color={colors.brandSecondary[500]} />
              <span className='text-text-sm font-semibold text-brandSecondary-800'>Aziende</span>
            </div>
            <ProgressBar
              value={
                financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit === SubscriptionTierLimit.unlimited ? 0 :
                  financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies && companyState.findAllCompanyResponse?.total
                    ? (companyState.findAllCompanyResponse?.total / financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies) * 100
                    : 0
              }
              color={ProgressBarColor.PRIMARY}
              active={companyState.findAllCompanyResponse?.total}
              total={financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit === SubscriptionTierLimit.unlimited ? "150" : financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies !== undefined ? financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies.toString() : ""}
            />
            <div className='flex flex-row gap-[6px]'>
              <EmployeeIcon size={'18'} color={colors.brandSecondary[500]} />
              <span className='text-text-sm font-semibold text-brandSecondary-800'>Collaboratori</span>
            </div>
            <ProgressBar
              value={
                financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit === SubscriptionTierLimit.unlimited ? 0 :
                  financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCollaborators && collaboratorState.findAllCollaboratorResponse?.total
                    ? (collaboratorState.findAllCollaboratorResponse?.total / financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCollaborators) * 100
                    : 0
              }
              color={ProgressBarColor.PRIMARY}
              active={collaboratorState.findAllCollaboratorResponse?.total}
              total={financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit === SubscriptionTierLimit.unlimited ? "∞" : financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCollaborators !== undefined ? financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCollaborators.toString() : ""}
            />
          </div>
        </div>
        <div className='pl-1 flex flex-col gap-3 justify-end items-end w-[50%]'>
          <div className='flex flex-col gap-[6px]'>
            <div className='flex flex-row gap-2 items-center justify-end'>
              <span className='text-text-sm font-medium text-brandSecondary-400'>Attivazione</span>
              <span className='text-text-sm font-medium text-brandSecondary-800'>
                {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.renewalDate && format(new Date(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.renewalDate), 'dd/MM/yyyy')}
              </span>
            </div>
            <div className='flex flex-row gap-2 items-center justify-end'>
              <span className='text-text-sm font-medium text-brandSecondary-400'>
                Prossimo Rinnovo
              </span>
              <span className='text-text-sm font-medium text-brandSecondary-800'>
                {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate && format(new Date(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate), 'dd/MM/yyyy')}
              </span>
            </div>
            <ButtonComponent
              size={'sm'}
              iconPosition={'off'}
              variant={'outline'}
              color={'blue'}
              label='Gestione Abbonamento'
              onClick={() => { dispatch(setUnsubscribeModal(true)); }}
              disabled={financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionType === SubscriptionType.canceled}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-3'>
        <div className='flex flex-row justify-between items-center'>
          <span className='text-text-md font-bold text-brandSecondary-800'>Modifica il tuo piano</span>
          <PaymentSwitch
            options={labels}
            supportingText={financialAdvisorState.filterFindProduct.period === SubscriptionPeriod.year ? "10% di risparmio" : ""}
            onChange={(value) => {
              dispatch(setFilterFindProductPeriod(value))
              dispatch(findStripeProduct({
                period: value as SubscriptionPeriod,
                sort: true
              }));
            }}
            selectedValue={financialAdvisorState.filterFindProduct.period}
            disabled={financialAdvisorState.findStripeProductStatus === "loading"}
          />
        </div>
        <div className={clsx('flex flex-row gap-2 w-full')}>
          {listProduct}
        </div>
      </div>
      {
        (subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit!)
          !== financialAdvisorState.selectedPlan?.name ||
          financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionPeriod !==
          financialAdvisorState.selectedPlan?.period || financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies === 0) &&
        <div className='flex flex-col gap-4'>
          {financialAdvisorState.selectedPlan?.name !== 'Free' &&
            <><div className='flex flex-col gap-2 border rounded-xl p-6 shadow-xs' style={{ borderColor: layoutState.theme[200] }}>
              <span className='font-bold text-text-md text-brandSecondary-800'>Codice sconto</span>
              <div className='flex flex-row gap-2 w-full'>
                <InputComponent
                  onChangeText={(text) => dispatch(setPromoCode(text))}
                  placeholder='es. NuovoCliente2024'
                  type='text'
                  value={financialAdvisorState.promoCode}
                  typeOfText={financialAdvisorState.valideStripePromoCodeResponse?.codeFound === undefined ? "" : financialAdvisorState.valideStripePromoCodeResponse?.codeFound ? "success" : "error"}
                  supportingText={financialAdvisorState.valideStripePromoCodeResponse?.codeFound === undefined ? "" : financialAdvisorState.valideStripePromoCodeResponse?.codeFound ? "Il codice sconto è stato applicato con successo!" : "Codice sconto non valido!"}
                />
                <div>
                  <ButtonComponent
                    onClick={() => { dispatch(valideStripePromoCode(financialAdvisorState.promoCode)) }}
                    label={'Applica'}
                    size={'md'}
                    iconPosition={'off'}
                    variant={'outline'}
                    color={'blue'}
                    fullWidth
                    disabled={financialAdvisorState.promoCode.length === 0}
                  />
                </div>
              </div>
            </div>
            </>}
          <div className='flex flex-col gap-4 px-6 pb-6'>
            {financialAdvisorState.selectedPlan?.name !== 'Free' &&
              <>
                <span className='text-text-md font-bold text-brandSecondary-800'>Carrello</span>
                <div className=' flex flex-col'>
                  <div className='flex flex-row justify-between font-medium text-text-md border-t border-dashed border-brandSecondary-200 py-2  '>
                    <span className='text-text-sm font-medium text-neutral-500'>{"Piano " + financialAdvisorState.selectedPlan?.name}</span>
                    <span className='font-semibold text-text-sm text-neutral-600'>
                      {financialAdvisorState.findStripeProductStatus === "loading" ? <SpinnerComponent size={20} /> : "€ " + Number(financialAdvisorState.selectedPlan?.price).toFixed(2).replace('.', ',')}
                    </span>
                  </div>
                  <div className='flex flex-row justify-between font-medium text-text-md py-2  '>
                    <span className='text-text-sm font-medium text-neutral-500'>IVA 22%</span>
                    <span className='font-semibold text-text-sm text-neutral-600'>
                      {financialAdvisorState.findStripeProductStatus === "loading" ? <SpinnerComponent size={20} /> : "€ " + (Number(financialAdvisorState.selectedPlan?.price!) * 0.22).toFixed(2).replace('.', ',')}
                    </span>
                  </div>
                  <div className='flex flex-row border-t border-dashed border-brandSecondary-200 justify-between font-medium text-text-md py-2  '>
                    <span className='text-text-sm font-medium text-neutral-500'>Importo mensile</span>
                    <span className='font-semibold text-text-sm text-neutral-600'>
                      {financialAdvisorState.findStripeProductStatus === "loading" ? <SpinnerComponent size={20} /> : "€ " + (Number(financialAdvisorState.selectedPlan?.price!) * 1.22).toFixed(2).replace('.', ',')}
                    </span>
                  </div>
                </div>
                {
                  financialAdvisorState.valideStripePromoCodeResponse?.codeFound &&
                  <div className='flex flex-row justify-between border-t border-dashed border-brandSecondary-200 py-2 '>
                    <span className='text-text-sm font-medium text-neutral-500'>Sconto(-{financialAdvisorState.valideStripePromoCodeResponse?.discountPercentage}%)</span>
                    <span className='font-semibold text-text-sm text-neutral-600'>
                      {financialAdvisorState.findStripeProductStatus === "loading" ?
                        <SpinnerComponent size={20} /> : "- € " + promo.toFixed(2).replace('.', ',')}
                    </span>
                  </div>
                }
                <div className='flex flex-row justify-between border-t border-dashed border-brandSecondary-200 pt-2'>
                  <div className='flex flex-col '>
                    <span className='font-bold text-text-lg text-neutral-800'>{'Totale da pagare ' + (financialAdvisorState.filterFindProduct.period === 'year' ? '(12 mesi)' : '(3 mesi)')}</span>
                  </div>
                  {
                    financialAdvisorState.findStripeProductStatus === "loading" ?
                      <SpinnerComponent size={20} /> :
                      <span className='font-bold text-heading-sm text-neutral-800'>
                        <span className='text-sm font-medium'>
                          {"€ "}
                        </span>
                        {
                          financialAdvisorState.valideStripePromoCodeResponse?.codeFound ?
                            ((Number(financialAdvisorState.selectedPlan?.price! ) * (1-financialAdvisorState.valideStripePromoCodeResponse?.discountPercentage/100) * 1.22 * monthCount).toFixed(2)).replace('.', ',') :
                            (Number(financialAdvisorState.selectedPlan?.price!) * 1.22 * monthCount).toFixed(2).replace('.', ',')
                        }
                      </span>
                  }
                </div>
                <div className='flex flex-row justify-end w-full'>
                  {
                    financialAdvisorState.findStripeProductStatus === "loading" ?
                      <SpinnerComponent size={20} /> :
                      <span className='font-semibold text-text-sm text-neutral-600'>
                        <span className='text-sm font-medium'>
                          {"€ "}
                        </span>
                        {
                          financialAdvisorState.valideStripePromoCodeResponse?.codeFound ?
                            ((Number(financialAdvisorState.selectedPlan?.price! ) * (1-financialAdvisorState.valideStripePromoCodeResponse?.discountPercentage/100) * monthCount).toFixed(2)).replace('.', ',') + ' i.e.' :
                            (Number(financialAdvisorState.selectedPlan?.price!) * monthCount).toFixed(2).replace('.', ',') + ' i.e.'
                        }
                      </span>
                  }
                </div>
              </>
            }
            <div className='pt-6 flex flex-row gap-2'>
              <div className='w-full flex flex-row justify-between items-center'>
                {financialAdvisorState.selectedPlan?.name !== 'Free' &&
                  <div className='text-text-xs text-neutral-500 font-semibold flex flex-col gap-1.5'>
                    <span>
                      {"· Prossima scadenza " + format(new Date(financialAdvisorState.filterFindProduct.period === 'year' ?
                        currentYear + 1 : currentYear,
                        financialAdvisorState.filterFindProduct.period === 'year' ? currentMonth : currentMonth + 3, currentDay), "dd/MM/yyyy")}
                    </span>
                    <span>
                      · Puoi annullare in qualsiasi momento.
                    </span>
                  </div>
                }
                {
                  financialAdvisorState.selectPlanStripeStatus === "loading" ?
                    <SpinnerComponent size={30} />
                    :
                    <div className={clsx('', {
                      'flex flex-row justify-end w-full flex-grow items-end': financialAdvisorState.selectedPlan?.name === 'Free'
                    })}>
                      <ButtonComponent
                        label={financialAdvisorState.selectedPlan?.name !== 'Free' ? 'Procedi al pagamento' : 'Procedi con il Piano Free'}
                        onClick={() => {
                          dispatch(selectPlanStripe({
                            id: financialAdvisorState.findByIdFinancialAdvisorResponse?.id!,
                            request: {
                              stripeProductId: financialAdvisorState.selectedPlan?.id!,
                              promotionalCode: financialAdvisorState.promoCode,
                              subscriptionType: financialAdvisorState.selectedPlan?.name.toLowerCase() === SubscriptionTierLimit.free ? SubscriptionType.free : subscriptionPeriodMap.get(financialAdvisorState.filterFindProduct.period)!,
                              subscriptionTierLimit: financialAdvisorState.selectedPlan?.name.toLowerCase() as SubscriptionTierLimit,
                              subscriptionPeriod: financialAdvisorState.filterFindProduct.period
                            }
                          }))
                        }}
                        size={'md'}
                        iconPosition={'off'}
                        variant={'solid'}
                        color={'blue'}
                      />
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
      <ConfirmModal
        open={financialAdvisorState.unsubscribeStripeModal}
        handleClose={() => dispatch(setUnsubscribeModal(false))}
        title={"Vuoi disdire il tuo abbonamento?"}
        description={
          <>
            Se decidi di disdire il tuo abbonamento, potrai comunque continuare a<br />
            utilizzare FiscalBright fino alla scadenza dell’attuale periodo di abbonamento.
          </>
        }
        labelDeny={"Annulla"}
        labelConfirm={"Disattiva"}
        actionConfirm={() => {
          dispatch(deletePlanStripe(financialAdvisorState.findByIdFinancialAdvisorResponse?.id!))
          dispatch(setUnsubscribeModal(false))
        }}
        isRedConfirmButton
      />
    </div>
  );
}