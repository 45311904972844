import { differenceInDays, format, isAfter } from "date-fns";
import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { DownloadIcon } from "../../../ui/icons/downloadIcon.tsx";
import { RefreshIcon } from "../../../ui/icons/refreshIcon.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { Dropdown } from "../../../ui/organisms/dropDown/index.tsx";
import { setSelectedTab } from "../../financialAdvisor/slice.ts";
import { BankStatements } from "./bankStatements.tsx";
import { DownloadFileAcubeAccountsModal } from "./downloadFileAcubeAccountsModal.tsx";
import {
  acubeReconnectRequest,
  setOpenDownloadPdfAcubeAccountsModal,
} from "./slice.ts";

export function BankList() {
  const dispatch = useAppDispatch();
  const bankState = useAppSelector((state) => state.bank);
  const companyState = useAppSelector((state) => state.company);
  const authState = useAppSelector((state) => state.auth);
  const financialAdvisorState = useAppSelector(
    (state) => state.financialAdvisor
  );

  let isObActive =
    financialAdvisorState.financialAdvisorActiveSubscriptionObResponse &&
    financialAdvisorState.financialAdvisorActiveSubscriptionObResponse
      ?.openBankingSubscriptionType === "active";

  const [uuid, setUuid] = useState("");
  const [name, setName] = useState("");
  const [fiscalId, setFiscalId] = useState("");
  const navigate = useNavigate();

  let acubeAccounts: ReactNode[] = [];

  if (
    bankState.findByIdAcubeGetAccountStatus === "successfully" &&
    bankState.findByIdAcubeGetAccountResponse !== undefined &&
    bankState.findByIdAcubeGetAccountResponse !== null &&
    bankState.findByIdAcubeGetAccountResponse.length > 0
  ) {
    bankState.findByIdAcubeGetAccountResponse.forEach((bank, index) => {
      const expirationDate = new Date(bank.consentExpiresAt);
      const difference = differenceInDays(expirationDate, new Date());
      acubeAccounts.push(
        <tr
          className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
          key={bank.uuid + "-" + bank.name}
        >
          <td className="px-4 py-4 text-text-sm font-semibold text-neutral-850">
            {bank.name}
          </td>
          <td className="px-2 py-4 text-text-sm text-neutral-600">
            {bank.iban === null ? "-" : bank.iban}
          </td>
          <td className="px-2 py-4 text-text-sm text-neutral-600">
            {format(new Date(bank.consentExpiresAt), "dd/MM/yyyy")}
          </td>
          <td className="w-[40px] cursor-pointer">
            <Dropdown
              items={[
                {
                  label: "Scarica",
                  icon: <DownloadIcon color="" size={""} />,
                  onClick: () => {
                    setName(bank.name);
                    setUuid(bank.uuid);
                    setFiscalId(bank.fiscalId);
                    dispatch(setOpenDownloadPdfAcubeAccountsModal(true));
                  },
                },
                {
                  label: "Rinnova consenso",
                  icon: <RefreshIcon color="" size={""} />,
                  onClick: () => {
                    dispatch(
                      acubeReconnectRequest({
                        id:
                          companyState.findByIdCompanyResponse !== undefined
                            ? companyState.findByIdCompanyResponse?.id
                            : "",
                        uuid: bank.uuid,
                      })
                    );
                  },
                  visible:
                    (difference <= 20 &&
                      (keycloak.hasRealmRole("financial-advisor") ||
                        keycloak.hasRealmRole(
                          "financial_advisor_collaborator"
                        )) &&
                      !authState.findMeFinancialAdvisorResponse?.isActive &&
                      keycloak.hasRealmRole("financial_advisor")) ||
                    (!authState.findMeCollaboratorResponse?.isActive &&
                      keycloak.hasRealmRole("financial_advisor_collaborator")),
                },
              ]}
            />
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto">
      <div>
        <table className="w-full mb-3">
          <thead className="h-[56px] bg-neutral-100 font-normal text-neutral-500 text-text-xs">
            <th className="text-left">
              <div className="flex py-4 px-4 gap-1">NOME</div>
            </th>
            {bankState.findByIdAcubeGetAccountResponse.length > 0 &&
              !isAfter(
                new Date(),
                authState.findMeFinancialAdvisorResponse
                  ?.openBankingExpirationDate!
              ) && (
                <>
                  <th className="text-left">
                    <div className="flex py-4 px-2 gap-1">IBAN</div>
                  </th>
                  <th className="text-left">
                    <div className="flex py-4 px-2 gap-1">SCADENZA</div>
                  </th>
                  <th className="text-left">
                    <div className="flex py-4 px-2 gap-1" />
                  </th>
                </>
              )}
          </thead>
          <tbody>
            {!isObActive ? (
              !(
                keycloak.hasRealmRole("company") ||
                keycloak.hasRealmRole("administrative_company")
              ) && (
                <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                  <td className="py-4 px-4 text-text-sm font-semibold text-neutral-800 flex justify-center items-center">
                    <ButtonComponent
                      onClick={() => {
                        keycloak.hasRealmRole("financial_advisor")
                          ? navigate("/setting")
                          : navigate(
                            "/operation/" +
                            companyState.findByIdCompanyResponse
                              ?.financialAdvisor
                          );
                        dispatch(setSelectedTab(3));
                      }}
                      size={"sm"}
                      label="Acquista Open Banking"
                      iconPosition={"off"}
                      variant={"solid"}
                      color={"blue"}
                    />
                  </td>
                </tr>
              )
            ) : bankState.findByIdAcubeGetAccountResponse.length === 0 ? (
              <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                <td className="py-4 px-4 text-text-sm font-semibold text-neutral-800">
                  <span className=" text-text-sm text-neutral-500">
                    Nessun risultato trovato!
                  </span>
                </td>
              </tr>
            ) : (
              acubeAccounts
            )}
          </tbody>
        </table>
      </div>
      {bankState.findByIdAcubeGetAccountResponse.length > 0 && <BankStatements />}
      <DownloadFileAcubeAccountsModal
        uuid={uuid}
        name={name}
        fiscalId={fiscalId}
        open={bankState.openDownloadPdfAcubeAccountsModal}
        handleClose={() => {
          dispatch(setOpenDownloadPdfAcubeAccountsModal(false));
        }}
      />
    </div>
  );
}
