import clsx from 'clsx'
import { format } from 'date-fns'
import React from 'react'
import { useAppSelector } from '../../../lib/redux/hook.ts'
import { colors } from '../../../ui/colors.ts'
import { UserInfoDTO } from './dto.ts'

interface Props {
    message: string
    date: Date
    sent: boolean
    sender?: UserInfoDTO | string
    sameSanderAsPrevious: boolean
}

export function MessageBotComponent(props: Props) {
    const { message, date, sent, sender, sameSanderAsPrevious } = props
    const layoutState = useAppSelector(state => state.layout)

    const formatMessage = (text) => {
        return text
            .replace(/\n\n/g, '<br/><br/>')
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            .replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    };

    return (
        <div
            style={{
                padding: '8px 12px 8px 12px',
                backgroundColor: (sent ? layoutState.theme[500] : colors.white),
                borderRadius: '12px',
                borderTopRightRadius: (sent ? '0' : '12px'),
                borderTopLeftRadius: (sent ? '12px' : '0'),
                gap: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                boxShadow: '0px 1px 2px 0px rgba(22, 42, 100, 0.12)'
            }}
        >
            {
                !sent &&
                <div className="text-text-xs font-semibold text-brandSecondary-500">
                    Brighty
                </div>
            }
            <div
                style={{
                    gap: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    width: '100%',
                }}
            >
                {
                    message === "" ?
                        <div className="flex gap-[2px] mt-2">
                            <div className=" w-[10px] h-[10px] rounded-xl bg-brandSecondary-200 animate-bounce [animation-delay:0ms]"></div>
                            <div className=" w-[10px] h-[10px] rounded-xl bg-brandSecondary-300 animate-bounce [animation-delay:200ms]"></div>
                            <div className=" w-[10px] h-[10px] rounded-xl bg-brandSecondary-400 animate-bounce [animation-delay:400ms]"></div>
                        </div>
                        :
                        <div
                            className={clsx("text-text-md font-medium text-brandPrimary-800", {
                                "text-white": sent
                            })}
                            dangerouslySetInnerHTML={{ __html: formatMessage(message) }}
                        />
                }
            </div>
            {
                message !== "loading" &&
                <div className="flex justify-end w-full">
                    <span
                        className={clsx(
                            'text-text-xs opacity-80 font-normal',
                            { "text-neutral-500": !sent, "text-white": sent }
                        )}
                    >
                        {format(new Date(date), 'HH:mm')}
                    </span>
                </div>
            }
        </div>
    )
}
