import React from "react"
import { IconsProps } from "./dto"

export function SortIcon(props : IconsProps) {
    return (
        <svg className=" cursor-pointer" onClick={()=>props.onClick && props.onClick()} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.10175 2.796C7.92942 2.62366 7.65001 2.62366 7.47767 2.796L4.13014 6.14354C3.85214 6.42154 4.04903 6.89687 4.44218 6.89687H11.1373C11.5304 6.89687 11.7273 6.42154 11.4493 6.14354L8.10175 2.796ZM8.10175 13.2042C7.92942 13.3765 7.65001 13.3765 7.47767 13.2042L4.13014 9.85663C3.85214 9.57863 4.04903 9.1033 4.44218 9.1033H11.1373C11.5304 9.1033 11.7273 9.57863 11.4493 9.85663L8.10175 13.2042Z" fill="#8383AD" />
        </svg>
    )
}
