import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { FinancialAdvisorIcon } from "../../ui/icons/financialAdvisorIcon.tsx";
import { SettingIcon } from "../../ui/icons/settingIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { HorizontalTab } from "../../ui/organisms/horizontalTab/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx";
import { Tab } from "../../ui/organisms/tab/index.tsx";
import { findMeFinancialAdvisor, setFindMeFinancialAdvisorStatus } from "../auth/slice.ts";
import { Collaborator } from "./collaborator/index.tsx";
import { setCollaboratorFiltersFinancialAdvisorId } from "./collaborator/slice.ts";
import { FinancialAdvisorDTO, SubscriptionType } from "./dto.ts";
import { RegistryFinancialAdvisor } from "./registryFinancialAdvisor.tsx";
import { editFinancialAdvisor, findByIdFinancialAdvisor, setEditFinancialAdisorsStatus, setEditPrimaryColor, setFindbyIdFinancialAdvisorStatus, setSelectedTab } from "./slice.ts";
import { SubscriptionManagement } from "./subscriptionManagement.tsx";
import { PurchaseHistory } from "./purchaseHistory.tsx";
import { ObSubscriptionManagement } from "./ob/obSubscriptionManagement.tsx";

export function OperationView() {
    const navigation = useNavigate()
    const dispatch = useAppDispatch()

    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const authState = useAppSelector(state => state.auth)
    let breadCrumbString: string[]

    let formData = new FormData();

    useEffect(() => {
        if (!keycloak.hasRealmRole("financial_advisor"))
            dispatch(findByIdFinancialAdvisor(financialAdvisorState.financialAdvisorId))
        else {
            financialAdvisor = keycloak.hasRealmRole("financial_advisor") ? authState.findMeFinancialAdvisorResponse
                : financialAdvisorState.findByIdFinancialAdvisorResponse
            dispatch(findByIdFinancialAdvisor(financialAdvisor?.id!))
        }
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully") {
            dispatch(setEditPrimaryColor(financialAdvisorState.findByIdFinancialAdvisorResponse?.primaryColor))
            dispatch(setCollaboratorFiltersFinancialAdvisorId(financialAdvisorState.findByIdFinancialAdvisorResponse?.id))
        }
    }, [financialAdvisorState.findByIdFinancialAdvisorStatus])

    useEffect(() => {
        if (financialAdvisorState.editFinancialAdvisorStatus === "successfully" &&
            keycloak.hasRealmRole("financial_advisor")) {
            dispatch(findMeFinancialAdvisor())
            dispatch(setFindMeFinancialAdvisorStatus("idle"))
        }
    }, [financialAdvisorState.editFinancialAdvisorStatus, keycloak.hasRealmRole])

    if (financialAdvisorState.selectedTab === 0) {
        breadCrumbString = ["Commercialisti", "Anagrafica"]
    } else if (financialAdvisorState.selectedTab === 1) {
        breadCrumbString = ["Commercialisti", "Collaboratori"]
    } else {
        breadCrumbString = ["Commercialisti", "Gestione abbonamento"]
    }

    if (financialAdvisorState.findByIdFinancialAdvisorStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    let financialAdvisor: FinancialAdvisorDTO | undefined
    financialAdvisor = keycloak.hasRealmRole('financial_advisor') ?
        authState.findMeFinancialAdvisorResponse :
        financialAdvisorState.findByIdFinancialAdvisorResponse

    const viewComponentMap = new Map<number, ReactElement>([
        [0, <RegistryFinancialAdvisor />],
        [1, <Collaborator />],
        [2, <SubscriptionManagement />],
        [3, <ObSubscriptionManagement />],
        [4, <PurchaseHistory />],
    ])

    return (
        <LayoutComponent
            menuItem={!keycloak.hasRealmRole("financial_advisor") ? MenuItems.FINANCIAL_ADVISOR : MenuItems.SETTING}
            headingLabel={(financialAdvisor?.name ?? '') + ' ' + (financialAdvisor?.surname ?? '')}
            headingButtons={[
                <div className={"flex gap-2"}>
                    {
                        !keycloak.hasRealmRole("financial_advisor") &&
                        <ButtonComponent
                            label={"Annulla"}
                            onClick={() => {
                                navigation('/financial-advisor')
                            }}
                            color={"gray"}
                            variant={"outline"}
                            size={"md"}
                            iconPosition="off"
                        />
                    }
                    <ButtonComponent
                        label={"Aggiorna"}
                        onClick={() => {
                            if (financialAdvisorState.editFinancialAdvisorRequest.avatar !== null)
                                formData.append('avatar', financialAdvisorState.editFinancialAdvisorRequest.avatar)
                            formData.append('user', JSON.stringify(financialAdvisorState.editFinancialAdvisorRequest))
                            dispatch(editFinancialAdvisor({ id: financialAdvisor?.id ? financialAdvisor.id : '', request: formData }))
                            dispatch(setFindbyIdFinancialAdvisorStatus('idle'))
                            if (!keycloak.hasRealmRole("financial_advisor")) {
                                dispatch(setFindbyIdFinancialAdvisorStatus('idle'))
                                navigation('/financial-advisor')
                            }
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"md"}
                        iconPosition="off"
                        disabled={
                            financialAdvisorState.editFinancialAdvisorRequest.name === "" ||
                            financialAdvisorState.editFinancialAdvisorRequest.surname === "" ||
                            financialAdvisorState.editFinancialAdvisorRequest.commercialName === "" ||
                            !/^\d{9,10}$/.test(financialAdvisorState.editFinancialAdvisorRequest.phoneNumber) ||
                            ((!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) && financialAdvisorState.selectedTab === 0)
                        }
                    />
                </div>
            ]}
            breadcrumbItems={!keycloak.hasRealmRole("financial_advisor") ? breadCrumbString : ["Impostazioni", "Anagrafica"]}
            breadcrumbIcon={!keycloak.hasRealmRole("financial_advisor") ? <FinancialAdvisorIcon size="24" color={colors.brandSecondary[500]} /> : <SettingIcon color={colors.brandSecondary[500]} size={"20"} />}
        >
            <ErrorPopup
                active={financialAdvisorState.editFinancialAdvisorStatus === 'failed'}
                close={() => dispatch(setEditFinancialAdisorsStatus('idle'))}
                message="Si è verificato un errore durante la modifica del commercialista"
            />
            <SuccessPopup
                active={financialAdvisorState.editFinancialAdvisorStatus === 'successfully'}
                close={() => dispatch(setEditFinancialAdisorsStatus('idle'))}
                message="Commercialista modificato"
            />
            <HorizontalTab>
                <Tab
                    label={"Anagrafica"}
                    selectedPrimary={financialAdvisorState.selectedTab === 0}
                    onClick={() => dispatch(setSelectedTab(0))}
                    type="primary"
                />
                {
                    (keycloak.hasRealmRole("operator") || keycloak.hasRealmRole('admin')) &&
                    <>
                        <Tab
                            label={"Collaboratori"}
                            selectedPrimary={financialAdvisorState.selectedTab === 1}
                            onClick={() => dispatch(setSelectedTab(1))}
                            type="primary"
                        />
                    </>
                }
                <Tab
                    label={"Gestione Abbonamento"}
                    selectedPrimary={financialAdvisorState.selectedTab === 2}
                    onClick={() => dispatch(setSelectedTab(2))}
                    type="primary"
                    disabled={financialAdvisorState.findByIdFinancialAdvisorResponse?.paymentSuccess === null}
                />
                <Tab
                    label={"Moduli Extra"}
                    selectedPrimary={financialAdvisorState.selectedTab === 3}
                    onClick={() => dispatch(setSelectedTab(3))}
                    type="primary"
                    disabled={financialAdvisorState.findByIdFinancialAdvisorResponse?.paymentSuccess === null || authState.findMeFinancialAdvisorResponse?.subscriptionType === SubscriptionType.expired}
                />
                <Tab
                    label={"Cronologia Acquisti"}
                    selectedPrimary={financialAdvisorState.selectedTab === 4}
                    onClick={() => dispatch(setSelectedTab(4))}
                    type="primary"
                    disabled={financialAdvisorState.findByIdFinancialAdvisorResponse?.paymentSuccess === null || authState.findMeFinancialAdvisorResponse?.subscriptionType === SubscriptionType.expired}
                />
            </HorizontalTab>
            {viewComponentMap.get(financialAdvisorState.selectedTab)}
        </LayoutComponent >
    )
}