
import { Router } from "@remix-run/router";
import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import { Chat } from "../../pages/company/chat/index.tsx";
import { Events } from "../../pages/company/events/index.tsx";
import { Company } from "../../pages/company/index.tsx";
import { OperationCompanyView } from "../../pages/company/operation.tsx";
import { SettingsCompany } from "../../pages/company/settings.tsx";
import { DashboardAdmin } from "../../pages/dashboard/dashboardAdmin.tsx";
import { DashboardCompany } from "../../pages/dashboard/dashboardCompany.tsx";
import { DashboardFinancialAdvisor } from "../../pages/dashboard/dashboardFA.tsx";
import { Collaborator } from "../../pages/financialAdvisor/collaborator/index.tsx";
import { OperationCollaborator } from "../../pages/financialAdvisor/collaborator/operation.tsx";
import { SubscriptionType } from "../../pages/financialAdvisor/dto.ts";
import { FinancialAdvisor } from "../../pages/financialAdvisor/index.tsx";
import { OperationView } from "../../pages/financialAdvisor/operation.tsx";
import { SuccesfulSignup } from "../../pages/financialAdvisor/signup/succesfulSignup.tsx";
import { Notice } from "../../pages/notice/index.tsx";
import { Operator } from "../../pages/operator/index.tsx";
import { OperationOperatorView } from "../../pages/operator/operation.tsx";
import { setMenuItems } from "../../ui/layout/slice.ts";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { keycloak } from '../keycloak/index.ts';
import { useAppDispatch, useAppSelector } from "../redux/hook.ts";
import { unregisteredRouter } from "./registerRouter.tsx";

function RedirectToDashboard() {

    const navigate = useNavigate()

    useEffect(() => { navigate('/') }, [])

    return (<></>)
}

const adminRouter = createBrowserRouter([
    {
        path: '/',
        element: (
            <DashboardAdmin />
        ),
    },
    {
        path: '/financial-advisor',
        element: (
            <FinancialAdvisor />
        ),
    },
    {
        path: '/company',
        element: (
            <Company />
        ),
    },
    {
        path: '/operator',
        element: (
            <Operator />
        ),
    },
    {
        path: '/notice',
        element: (
            <Notice />
        ),
    },
    {
        path: '/operation/:id',
        element: (
            <OperationView />
        ),
    },
    {
        path: '/operation-operator/:id',
        element: (
            <OperationOperatorView />
        ),
    },
    {
        path: '/operation-company/:id',
        element: (
            <OperationCompanyView />
        ),
    },
    {
        path: '/settings-company/:id',
        element: (
            <SettingsCompany />
        ),
    },
    {
        path: '/success',
        element:
            <SuccesfulSignup />

    },
    {
        path: '/ob-success',
        element:
            <SuccesfulSignup />

    },
    {
        path: '*',
        element: (
            <RedirectToDashboard />
        ),
    },
])

const operatorRouter = createBrowserRouter([
    {
        path: '/',
        element: (
            <DashboardAdmin />
        ),
    },
    {
        path: '/financial-advisor',
        element: (
            <FinancialAdvisor />
        ),
    },
    {
        path: '/company',
        element: (
            <Company />
        ),
    },
    {
        path: '/notice',
        element: (
            <Notice />
        ),
    },
    {
        path: '/operation/:id',
        element: (
            <OperationView />
        ),
    },
    {
        path: '/operation-company/:id',
        element: (
            <OperationCompanyView />
        ),
    },
    {
        path: '/settings-company/:id',
        element: (
            <SettingsCompany />
        ),
    },
    {
        path: '/setting',
        element: (
            <OperationOperatorView />
        ),
    },
    {
        path: '/success',
        element:
            <SuccesfulSignup />

    },
    {
        path: '/ob-success',
        element:
            <SuccesfulSignup />

    },
    {
        path: '*',
        element: (
            <RedirectToDashboard />
        ),
    },
])

const financialAdvisorRouter = createBrowserRouter([
    {
        path: '/',
        element: (
            <DashboardFinancialAdvisor />
        ),
    },
    {
        path: '/company',
        element: (
            <Company />
        ),
    },
    {
        path: '/collaborator',
        element: (
            <Collaborator />
        ),
    },
    {
        path: '/notice',
        element: (
            <Notice />
        ),
    },
    {
        path: '/setting',
        element: (
            <OperationView />
        ),
    },
    {
        path: '/operation-company/:id',
        element: (
            <OperationCompanyView />
        ),
    },
    {
        path: '/settings-company/:id',
        element: (
            <SettingsCompany />
        ),
    },
    {
        path: '/success',
        element:
            <SuccesfulSignup />

    },
    {
        path: '/ob-success',
        element:
            <SuccesfulSignup />

    },
    {
        path: '*',
        element: (
            <RedirectToDashboard />
        ),
    },
])

const collaboratorRouter = createBrowserRouter([
    {
        path: '/',
        element: (
            <DashboardFinancialAdvisor />
        ),
    },
    {
        path: '/company',
        element: (
            <Company />
        ),
    },
    {
        path: '/notice',
        element: (
            <Notice />
        ),
    },
    {
        path: '/operation-company/:id',
        element: (
            <OperationCompanyView />
        ),
    },
    {
        path: '/settings-company/:id',
        element: (
            <SettingsCompany />
        ),
    },
    {
        path: '/setting',
        element: (
            <OperationCollaborator />
        ),
    },
    {
        path: '*',
        element: (
            <RedirectToDashboard />
        ),
    },
])

const companyRouter = createBrowserRouter([
    {
        path: '/',
        element: (
            <DashboardCompany />
        ),
    },
    {
        path: '/document-company',
        element: (
            <OperationCompanyView />
        ),
    },
    {
        path: '/message',
        element: (
            <Chat />
        ),
    },
    {
        path: '/calendar',
        element: (
            <Events />
        ),
    },
    {
        path: '/setting',
        element: (
            <SettingsCompany />
        ),
    },
    {
        path: '/notice',
        element: (
            <Notice />
        ),
    },
    {
        path: '*',
        element: (
            <RedirectToDashboard />
        ),
    },
])
export function KeycloakRouter() {
    const roles = useAppSelector(state => state.secured.userRoles)
    const authState = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const [router, setRouter] = useState<Router | undefined>(undefined)
    useEffect(() => {
        if (roles !== undefined && roles.length > 0 && keycloak.token !== undefined) {
            if (keycloak.hasRealmRole('admin')) {
                setRouter(adminRouter)
                dispatch(setMenuItems([
                    MenuItems.DASHBOARD,
                    MenuItems.FINANCIAL_ADVISOR,
                    MenuItems.COMPANY,
                    MenuItems.OPERATOR,
                    MenuItems.NOTICE,
                ]))
            } else if (keycloak.hasRealmRole('operator')) {
                setRouter(operatorRouter)
                dispatch(setMenuItems([
                    MenuItems.DASHBOARD,
                    MenuItems.FINANCIAL_ADVISOR,
                    MenuItems.COMPANY,
                    MenuItems.NOTICE,
                    MenuItems.SETTING
                ]))
            } else if (keycloak.hasRealmRole('financial_advisor')) {
                setRouter(financialAdvisorRouter)
                if (authState.findMeFinancialAdvisorResponse?.subscriptionType === SubscriptionType.expired)
                    dispatch(setMenuItems([
                        MenuItems.DASHBOARD,
                        MenuItems.SETTING
                    ]))
                else
                    dispatch(setMenuItems([
                        MenuItems.DASHBOARD,
                        MenuItems.COMPANY,
                        MenuItems.COLLABORATOR,
                        MenuItems.NOTICE,
                        MenuItems.SETTING
                    ]))
            } else if (keycloak.hasRealmRole('financial_advisor_collaborator')) {
                setRouter(collaboratorRouter)
                dispatch(setMenuItems([
                    MenuItems.DASHBOARD,
                    MenuItems.COMPANY,
                    MenuItems.NOTICE,
                    MenuItems.SETTING
                ]))
            } else {
                setRouter(companyRouter)
                dispatch(setMenuItems([
                    MenuItems.DASHBOARD,
                    MenuItems.DOCUMENT,
                    MenuItems.CHAT,
                    MenuItems.CALENDAR,
                    MenuItems.NOTICE,
                    MenuItems.SETTING
                ]))
            }
        }
        else { setRouter(unregisteredRouter) }
    }, [roles, keycloak.token])

    if (router === undefined) {
        return (
            <div className={"flex justify-center items-center w-full h-[100vh]"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <RouterProvider router={router} />
    );
}
