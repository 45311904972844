import React, { forwardRef } from "react";
import { colors } from "../colors.ts";

const PaperClipIcon = forwardRef<SVGSVGElement, { size?: number; color?: string }>(
    ({ size = 20, color = colors.neutral[500], ...props }, ref) => (
        <svg
            ref={ref}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props} 
        >
            <path
                d="M21.44 11.05L12.25 20.24C11.1242 21.3659 9.59718 21.9984 8.005 21.9984C6.41282 21.9984 4.88584 21.3659 3.76 20.24C2.63416 19.1142 2.00166 17.5872 2.00166 15.995C2.00166 14.4029 2.63416 12.8759 3.76 11.75L12.95 2.56004C13.7006 1.80948 14.7185 1.38782 15.78 1.38782C16.8415 1.38782 17.8594 1.80948 18.61 2.56004C19.3606 3.3106 19.7822 4.32859 19.7822 5.39004C19.7822 6.4515 19.3606 7.46948 18.61 8.22004L9.41 17.41C9.03472 17.7853 8.52573 17.9962 7.995 17.9962C7.46427 17.9962 6.95528 17.7853 6.58 17.41C6.20472 17.0348 5.99389 16.5258 5.99389 15.995C5.99389 15.4643 6.20472 14.9553 6.58 14.58L15.07 6.10004"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
);

export default PaperClipIcon;
