import React, { useEffect } from 'react'
import { Dropdown } from '../../ui/organisms/dropDown/index.tsx'
import { HeadingComponent } from '../../ui/organisms/heading/index.tsx'
import { HeadingSize } from '../../ui/organisms/heading/dto.ts'
import { keycloak } from '../../lib/keycloak/index.ts'
import { useAppDispatch, useAppSelector } from '../../lib/redux/hook.ts'
import { findByIdFinancialAdvisor, findPurchaseHistory } from './slice.ts'
import { SpinnerComponent } from '../../ui/molecules/spinner/index.tsx'
import { Pills } from '../../ui/molecules/pills/index.tsx'
import { format } from 'date-fns'

export function PurchaseHistory() {

    const dispatch = useAppDispatch()

    const authState = useAppSelector(state => state.auth)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    useEffect(() => {
        if (keycloak.hasRealmRole('financial_advisor')) {
            if (authState.findMeFinancialAdvisorResponse)
                dispatch(findPurchaseHistory(authState.findMeFinancialAdvisorResponse.id))
        }
        else {
            if (financialAdvisorState.findByIdFinancialAdvisorResponse) {
                dispatch(findPurchaseHistory(financialAdvisorState.findByIdFinancialAdvisorResponse.id))
            }
        }
    }, [])

    if (financialAdvisorState.findPurchaseHistoryStatus === 'loading') {
        return (
            <div className=' flex justify-center items-center h-full flex-grow'>
                <SpinnerComponent size={30} />
            </div>)
    }

    return (
        <>
            <HeadingComponent
                label={"Cronologia Acquisti"}
                buttons={[

                ]}
                size={HeadingSize.LG}
            />
            <div className='w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto'>
                <div>
                    <table className="w-full mb-3">
                        <thead className="h-[56px] bg-neutral-100 font-semibold text-neutral-500 text-text-xs">
                            <tr>
                                <th className="text-left">
                                    <div className="flex py-4 px-4 gap-1">
                                        IMPORTO
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-4 gap-1">
                                        STATO
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-4 gap-1">
                                        METODO DI PAGAMENTO
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-4 gap-1">
                                        DESCRIZIONE ABBONAMENTO
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-4 gap-1">
                                        DATA
                                    </div>
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                financialAdvisorState.findPurchaseHistoryResponse && (
                                    financialAdvisorState.findPurchaseHistoryResponse.length === 0 ?
                                        <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                            <td className="py-4 px-4 text-text-sm font-medium text-neutral-500">
                                                Nessun risultato trovato!
                                            </td>
                                        </tr>
                                        :
                                        financialAdvisorState.findPurchaseHistoryResponse.map(item =>
                                            <tr
                                                className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 text-text-sm text-center font-semibold text-neutral-600"
                                                key={item.date}
                                            >
                                                <td className="py-4 px-4 text-neutral-800 text-start ">{(item.unitAmount / 100).toFixed(2).toString()} €
                                                </td>
                                                <td className="py-4 px-4 text-start ">
                                                    {item.status === 'succeeded' ?
                                                        <Pills size={'sm'} label={'Successo'} outline={false} emphasis={false} color={'green'} /> :
                                                        <Pills size={'sm'} label={'Fallito'} outline={false} emphasis={false} color={'red'} />
                                                    }
                                                </td>
                                                <td className="py-4 px-4 text-start">
                                                    {item.paymentMethod}
                                                </td>
                                                <td className="py-4 px-4 text-start ">{item.description}</td>
                                                <td className="py-4 px-4 text-start ">{format(new Date(item.date), 'dd-MM-yyyy')}</td>
                                                <td>
                                                    <Dropdown items={[

                                                    ]} />

                                                </td>
                                            </tr>
                                        )
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
